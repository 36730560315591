import Styles from "../user-individual-page/UserIndividualPage.module.scss";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ButtonWithLinks from "../buttons/ButtonWithLink";
import VideoView from "../video-view/video-view";
import { getIndividualAuthor } from "../../service/imapctService";
import { ThreeCircles } from "react-loader-spinner";
import { individualProgram } from "../../service/formService";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { decryptObject } from "../../helper-fuction/helper";
import { ToastContainer, toast } from "react-toastify";
import GetInTouch from "..//common_components/forms/GetInTouch";
import {
  FaLinkedinIn
} from "react-icons/fa";
import { urls } from "../../utils/urls";

export default function UserIndividualPage() {
  const { id } = useParams();
  const apiId = id;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [popupVideoUrl, setPopupVideoUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [formLink, setFormLink] = useState("");
  const [getInTouchOpen, setGetInTouchOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    getIndividualAuthorData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getIndividualAuthorData = async () => {
    try {
      const response = await getIndividualAuthor(apiId);
      setUserData(response);
      setLoading(false);
    } catch (error) {
      setUserData({});
      setLoading(false);
    }
  };

  const getLepRegistrationForm = async () => {
    try {
      const response = await individualProgram();
      const encryptedLepProgramRegistrationUrl = response.data.data;
      const decryptLepProgramRegistrationUrl = decryptObject(
        encryptedLepProgramRegistrationUrl,
        appLevelConstants.REACT_APP_SECRET_KEY
      );
      setFormLink(decryptLepProgramRegistrationUrl.lepProgramMasterclassPageUrl);
    } catch (error) {
    }
  };

  useEffect(()=>{
    getLepRegistrationForm()
  },[])

  const extractYouTubeVideoId = (url) => {
    const regex =
      /^https?:\/\/(?:www\.)?youtu(?:be\.com\/(?:watch\?(?:.*&)?v=|v\/|embed\/)|\.be\/)([\w\-_]+)(?:.*)$/i;
    const match = url?.match(regex);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  };

  const iframePopup = () => {
    if (userData.successStoryPostYouTubeLink) {
      setDialogOpen(true);
      setPopupVideoUrl(
        "https://www.youtube.com/embed/" +
          extractYouTubeVideoId(userData.successStoryPostYouTubeLink)
      );
    }
  };

  const handleCallback = () => {
    if (userData.program.length > 0 && userData.program.includes("Leadership Essentials Program")) {
      window.open(
        formLink,
        "_blank"
      );
    } else {
      setGetInTouchOpen(true)
    }
  };

  const handleSucessToasterGIT = (messageGIT) => {
    // handleSucess(messageGIT);
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleErrorToasterGIT = (messageGIT) => {
    // handleError(messageGIT);
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };

  const handleBack = () => {
    window.history.back();// Navigate back to the previous page
  };
  return (
    <>
     {getInTouchOpen && (
        <GetInTouch
          isDemo={false}
          isRegister ={true}
          isWorkProfile={true}
          isSeeYourSelf={true}
          isExp={true}
          setDialogOpen={setGetInTouchOpen}
          open={getInTouchOpen}
          programName={userData?.program}
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          // callback={handleCallback}
        />
      )}
      <VideoView
        setDialogOpen={setDialogOpen}
        open={dialogOpen}
        url={popupVideoUrl}
      ></VideoView>
      <div className={Styles["main-parent-div"]}>
        <div className={Styles["back-button-container"]}>
          <ButtonWithLinks id='impactBack' name="Back" linkTo="action" callback={handleBack} className={"button"} />
        </div>

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3vw",
            }}
          >
            <ThreeCircles
              height="100"
              width="100"
              color="#F52929"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        )}

        {!loading && JSON.stringify(userData) !== "{}" ? (
          <div className={Styles["user-details-container-main"]}>
            <span className={Styles["main-title"]}>{userData.pageTitle}</span>
            <div className={Styles["user-details-container"]}>
              <div className={Styles["video-section"]}>
                <div className={Styles["inner-card"]} onClick={iframePopup}>
                  {userData?.successStoryPostYouTubeLink ? (
                    <>
                    <iframe
                    title="Embedded YouTube Video"
                      className={Styles["iframe"]}
                      src={
                        "https://www.youtube.com/embed/" +
                        extractYouTubeVideoId(
                          userData.successStoryPostYouTubeLink
                        )
                      }
                      id="fitvid725928"
                    ></iframe>

                    <a
                        
                        href={userData?.linkedinURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                           <div  className={Styles["icon-linkedin-container"]}>
                        <FaLinkedinIn className={Styles["icon-linkedin"]} />
                        </div>
                      </a>

                      </>

                  ) : (
                    <>
                    <img
                    alt=""
                      src={userData?.successStoryPostImageLink}
                      className={Styles["user-image"]}
                    ></img>

                    <a
                       
                        href={userData?.linkedinURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                           <div  className={Styles["icon-linkedin-container"]}>
                        <FaLinkedinIn className={Styles["icon-linkedin"]} />
                        </div>
                      </a>

                      </>

                  )}
                </div>
              </div>

              <div className={Styles["details-section"]}>
                <span className={Styles["title"]}>
                  {userData.fullNameOfStoryTeller}
                </span>
                <span className={Styles["designation"]}>
                  {userData.fullNameOfStoryTellerDesignation}
                </span>

                {userData &&
                userData?.introductionOfTheStoryTeller?.startsWith("<p") ? (
                  <>
                    <div
                      className={Styles["decription-intro"]}
                      dangerouslySetInnerHTML={{
                        __html: userData?.introductionOfTheStoryTeller,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span className={Styles["decription-intro"]}>
                      {userData.introductionOfTheStoryTeller}
                    </span>
                  </>
                )}

                {userData &&
                userData?.situationOfTheStoryTeller?.startsWith("<p") ? (
                  <>
                    <span className={Styles["description-title"]}>
                      Before the Program
                    </span>
                    <div
                      className={Styles["decription-intro"]}
                      dangerouslySetInnerHTML={{
                        __html: userData?.situationOfTheStoryTeller,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span className={Styles["description-title"]}>
                      Before the Program
                    </span>
                    <span className={Styles["decription-intro"]}>
                      {userData.situationOfTheStoryTeller}
                    </span>
                  </>
                )}

                {userData &&
                userData?.principalAppliedByTheStoryTeller?.startsWith("<p") ? (
                  <>
                    <span className={Styles["description-title"]}>
                      Principles
                    </span>
                    <div
                      className={Styles["decription-intro"]}
                      dangerouslySetInnerHTML={{
                        __html: userData?.principalAppliedByTheStoryTeller,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span className={Styles["description-title"]}>
                      Principles
                    </span>
                    <span className={Styles["decription-intro"]}>
                      {userData.principalAppliedByTheStoryTeller}
                    </span>
                  </>
                )}

                {userData &&
                userData?.actionTakenByTheStoryTeller?.startsWith("<p") ? (
                  <>
                    <span className={Styles["description-title"]}>
                      Actions Taken
                    </span>
                    <div
                      className={Styles["decription-intro"]}
                      dangerouslySetInnerHTML={{
                        __html: userData?.actionTakenByTheStoryTeller,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span className={Styles["description-title"]}>
                      Actions Taken
                    </span>
                    <span className={Styles["decription-intro"]}>
                      {userData.actionTakenByTheStoryTeller}
                    </span>
                  </>
                )}

                {userData &&
                userData?.resultsAchievedByTheStoryTeller?.startsWith("<p") ? (
                  <>
                    <span className={Styles["description-title"]}>
                      Key Results
                    </span>
                    <div
                      className={Styles["decription-intro"]}
                      dangerouslySetInnerHTML={{
                        __html: userData?.resultsAchievedByTheStoryTeller,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span className={Styles["description-title"]}>
                      Key Results
                    </span>
                    <span className={Styles["decription-intro"]}>
                      {userData?.resultsAchievedByTheStoryTeller}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className={Styles["buttons-section"]}>
              <div className={Styles["register-button"]}>
                <ButtonWithLinks
                id='impactRegisterForProgram'
                  name="Register for Program"
                  linkTo={"action"}
                  className={"button-width"}
                  callback={handleCallback}
                />
              </div>
              <div className={Styles["program-button"]}>
                <ButtonWithLinks
                id='impactExplorePrograms'
                  name="Explore Programs"
                  linkTo={"/programs"}
                  className={"button-width"}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            {!loading && JSON.stringify(userData) === "{}" && (
              <div className={Styles["no-data"]}>
                <img alt="" src={urls.noData} className={Styles["no-data-image"]}></img>
              </div>
            )}
          </>
        )}
      </div>
      <ToastContainer
        limit={1}
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId={"getInTouch"}
        enableMultiContainer
      />
    </>
  );
}
