import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import Styles from "./FeedsDescription.module.scss";
import { getFeedDetailsById } from '../../service/communityService.js';

const FeedsDescription = () => {
    const { id } = useParams();
    const [feedContent, setFeedContent] = useState('');
    const [elements, setElements] = useState([]);

    useEffect(() => {
        getFeedDetailsById(id).then(data => {
            setFeedContent(data.content.rendered);
        });
    }, [id]);

    useEffect(() => {
        if (feedContent) {
            const elementsArray = [];
            const parseOptions = {
                replace: domNode => {
                    if (domNode.name === 'h3') {
                        elementsArray.push(
                            <h3>{domToReact(domNode.children)}</h3>
                        );
                    } else if (domNode.name === 'h5') {
                        elementsArray.push(
                            <h5>{domToReact(domNode.children)}</h5>
                        );
                    } else if (domNode.name === 'p') {
                        elementsArray.push(
                            <p>{domToReact(domNode.children)}</p>
                        );
                    } else if (domNode.name === 'img') {
                        elementsArray.push(
                            <img src={domNode.attribs.src} alt={domNode.attribs.alt} />
                        );
                    }
                }
            };

            parse(feedContent, parseOptions);
            setElements(elementsArray);
        }
    }, [feedContent]);

    return (
        <div className={Styles["main-div"]}>
            {elements.map((element, index) => (
                <div key={index}>{element}</div>
            ))}
        </div>
    );
}

export default FeedsDescription;

