import React, { useEffect, useState } from "react";
import Styles from "./WhatDriveUs.module.scss";
import AOS from "aos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { appLevelConstants } from "../../constants/AppLevelConstants";

export default function WhatDriveUs() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      className={Styles["video"]}
    >
      <div className={Styles["video-image"]}>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["card-div"]}
        >
          {appLevelConstants.program.whatsDrive.map((item, index) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["card"]}
                key={index}
              >
                <span className={Styles["card-title"]}>{item.title}</span>
                <div
                  className={
                    index === appLevelConstants.program.whatsDrive.length - 1
                      ? Styles["img-div-hidden"]
                      : Styles["img-div"]
                  }
                >
                  <img className={Styles["img"]} alt="" src={item.img} />
                </div>
                <div>
                  {item.description.map((des, desIndex) => {
                    return (
                      <>
                        <div key={des.id}>
                        <p className={Styles["description"]}>
                        {item.id === 1 && desIndex === 0 ? (
                            des.value.split(" ").map((word, wordIndex) => {
                              if (
                                wordIndex === 3 ||
                                wordIndex === 4 ||
                                wordIndex === 5 ||
                                wordIndex === 6
                              ) {
                                return (
                                  <span className={Styles["description"]} style={{ fontWeight: "bold", color : '#F52929' }} key={wordIndex}>{word}&nbsp; </span>
                                );
                              } else {
                                return (
                                  <span className={Styles["description"]} key={wordIndex}>{word}&nbsp; </span>
                                );
                              }
                            })
                          ) : item.id === 2 && desIndex === 1 ? (
                            des.value.split(" ").map((word, wordIndex) => {
                              if (wordIndex === 14 || wordIndex === 15) {
                                return (
                                  <span
                                    className={Styles["description"]}
                                    style={{ fontWeight: "bold", color : '#F52929' }}
                                    key={wordIndex}
                                  >
                                    {word}&nbsp;{" "}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    className={Styles["description"]}
                                    key={wordIndex}
                                  >
                                    {word}&nbsp;{" "}
                                  </span>
                                );
                              }
                            })
                          ) : (
                            <>{des.value}</>
                          )}
                          </p>
                          
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* <span className={Styles["pargraph"]}>Women are constantly underrepresented at every level of the organization - from entry-level jobs to C-suite roles. And as they go higher up, the representation becomes bleaker.</span>
      <span className={Styles["pargraph"]}>Most B-school programs and organizational courses are great experiences, but they do not equip women to win over the real workplace challenges. We aim to change this with our results-focussed programs. We enable women to pitch themselves powerfully and navigate through challenging landscapes of mind games, office politics and biases at ease.</span> */}
    </div>
  );
}
