import Styles from './ThankYouPopUp.module.scss';
import ButtonWithLink from '../../components/buttons/ButtonWithLink';
import AOS from "aos";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";


export default function ThankYouPopUp() {
  const { programName, formType } = useParams();
  const newProgramName = programName.split("_").join(" ");
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (formType === "Program_Registration") {
      setMessage(
        `Thank you for registering in the ${newProgramName}. You will receive the further details on your registered email id.`
      );
    } if (formType === "Program_Enrollment") {
      setMessage(
        `Thank you for your program enrollment in the ${newProgramName}. You will receive the further details on your registered email id.`
      );
    } if (formType === "EMI_-_Complete") {
      setMessage(`Thank you for your program enrollment in the ${newProgramName}. You will receive the further details on your registered email id.`)
    }
    if (formType === "EMI_-_Partial") {
      setMessage(`Thank you for your program enrollment in the ${newProgramName}. You will receive the further details on your registered email id.`)
    }
     if (formType === "Master_Class") {
      setMessage(`Thank you for applying for the ${newProgramName}. You will receive the further details on your registered email id.`)
    }
  }, [formType, newProgramName, programName]);
 
  const goToLms = () => {
    // window.open("https://lms.iamironlady.com/login/index.php");
    window.location.href = "https://lms.iamironlady.com/login/index.php";
  }

  useEffect(() => {
    AOS.init();
  }, []);
    return(
        <div data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out" className={Styles["popup"]}>
        <div className={Styles["popup-content"]}>
          {message}
          <ButtonWithLink id="go-to-home" name="Go To Home" linkTo="action" callback={() => goToLms()} className = {"button"}>
           
          </ButtonWithLink>
        </div>
      </div>

    );
}