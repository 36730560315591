import Styles from "./JobPosts.module.scss";
import { useEffect, useState } from "react";
import { getJobPostsSummary, getJobPostsData } from "../../service/jobPosts";
import { ThreeCircles } from "react-loader-spinner";
import ButtonWithLink from "../buttons/ButtonWithLink";
import { useNavigate } from "react-router-dom";
import { urls } from "../../utils/urls";

export default function JobPosts() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    try {
      if (page === 1) {
        setLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      const summary = await getJobPostsSummary();
      setTotalPages(summary.totalPage);
      const response = await getJobPostsData(page);
      setData((prevData) => [...prevData, ...response]);
    } catch (error) {
      console.log("data fetching error: ", error);
    } finally {
      if (page === 1) {
        setLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredResults = data.filter(
      (item) =>
        item.companyDetails.companyName.toLowerCase().includes(query) ||
        item.jobPostTitle.toLowerCase().includes(query)
    );

    setSearchResults(filteredResults);
  };

  return (
    <>
      <div className={Styles["main-parent-div"]}>
        <span className={Styles["main-title"]}>Careers</span>
        <img alt="" className={Styles["banner"]} src={urls.careerBanner}></img>

        <span className={Styles["main-title"]}>Apportunities For You</span>
        <span className={Styles["message"]}>
          "Empowerment knows no gender, Embrace the limitless opportunities
          before you, for your journey to success is paved with your strengths
          and determination"
        </span>

        <div className={Styles["search-bar-div"]}>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            className={Styles["search-bar"]}
            placeholder="Search by company name or job post title"
          />
        </div>
      </div>

      <>
        <div className={Styles["main-card-div"]}>
          {loading ? (
            <ThreeCircles
              height="70"
              width="70"
              color="#F52929"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "20vh",
              }}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
            />
          ) : searchResults.length > 0 ? (
            <>
              {searchResults.map((item) => {
                return (
                  <div
                    className={Styles["card"]}
                    id={item.jobPostId}
                    onClick={() => {
                      navigate("/jobPostsDetailPage/" + item.jobPostId);
                    }}
                  >
                    <div className={Styles["card-top"]}>
                      <img
                        alt=""
                        className={Styles["company-logo"]}
                        src={item.companyDetails.companyLogo}
                      ></img>
                      <div className={Styles["right-div"]}>
                        <span className={Styles["job-post-title"]}>
                          {item.jobPostTitle}
                        </span>
                        <span className={Styles["company-name"]}>
                          {item.companyDetails.companyName}
                        </span>
                      </div>
                    </div>
                    <div className={Styles["card-bottom"]}>
                      <div className={Styles["icon-div"]}>
                        <img
                          alt=""
                          className={Styles["icon"]}
                          src={urls.locationIcon}
                        ></img>
                        <span className={Styles["location"]}>
                          {item.jobLocation}
                        </span>
                      </div>
                      <div className={Styles["icon-div"]}>
                        <img
                          alt=""
                          className={Styles["icon"]}
                          src={urls.experienceIcon}
                        ></img>
                        <span className={Styles["location"]}>
                          {item.experienceRange}
                        </span>
                      </div>
                      <div className={Styles["icon-div"]}>
                        <img
                          alt=""
                          className={Styles["icon"]}
                          src={urls.rupeeIcon}
                        ></img>
                        <span className={Styles["location"]}>Confidential</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : searchQuery ? (
            <>
              <div className={Styles["no-data"]}>
                <img
                  alt=""
                  src={urls.noData}
                  className={Styles["no-data-image"]}
                ></img>
              </div>
            </>
          ) : totalPages === 0 && loading === false ? (
            <>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["no-data"]}
              >
                <img
                  alt=""
                  src={urls.noData}
                  className={Styles["no-data-image"]}
                ></img>
              </div>
            </>
          ) : (
            <>
              {data.map((item) => {
                return (
                  <div
                    className={Styles["card"]}
                    id={item.jobPostId}
                    onClick={() => {
                      navigate("/jobPostsDetailPage/" + item.jobPostId);
                    }}
                  >
                    <div className={Styles["card-top"]}>
                      <img
                        alt=""
                        className={Styles["company-logo"]}
                        src={item.companyDetails.companyLogo}
                      ></img>
                      <div className={Styles["right-div"]}>
                        <span className={Styles["job-post-title"]}>
                          {item.jobPostTitle}
                        </span>
                        <span className={Styles["company-name"]}>
                          {item.companyDetails.companyName}
                        </span>
                      </div>
                    </div>
                    <div className={Styles["card-bottom"]}>
                      <div className={Styles["icon-div"]}>
                        <img
                          alt=""
                          className={Styles["icon"]}
                          src={urls.locationIcon}
                        ></img>
                        <span className={Styles["location"]}>
                          {item.jobLocation}
                        </span>
                      </div>
                      <div className={Styles["icon-div"]}>
                        <img
                          alt=""
                          className={Styles["icon"]}
                          src={urls.experienceIcon}
                        ></img>
                        <span className={Styles["location"]}>
                          {item.experienceRange}
                        </span>
                      </div>
                      <div className={Styles["icon-div"]}>
                        <img
                          alt=""
                          className={Styles["icon"]}
                          src={urls.rupeeIcon}
                        ></img>
                        <span className={Styles["location"]}>Confidential</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>

        {currentPage < totalPages && (
          <div className={Styles["load-more-div"]}>
            <ButtonWithLink
              linkTo="action"
              callback={handleLoadMore}
              name={isLoadingMore ? "Loading..." : "Load More"}
              className={"button"}
              disabled={isLoadingMore}
            ></ButtonWithLink>
          </div>
        )}
      </>
    </>
  );
}
