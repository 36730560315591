import Feeds from '../components/feeds/Feeds';
import EventChallengeMC from '../components/event-challenge-mc/EventChallengeMC';
import Wisdom from '../components/wisdom/Wisdom';
import InsiprationZone from '../components/inspiration-zone/InsiprationZone';
import NewsletterLH from '../components/latest-happenings-newsletter/NewsletterLH.js';
import { Helmet } from 'react-helmet';

export default function LatestHappening() {
    return (
        <>
            <Helmet>
                <title>Latest Updates from Iron Lady Leadership Programs</title>
                <meta name="description" content="Get the latest updates on Iron Lady's top leadership program for women. Discover new batches, upcoming events, success stories, and program highlights designed to empower women and accelerate their leadership journey." />
            </Helmet>

            <EventChallengeMC />
            <NewsletterLH />
            <InsiprationZone />
            <Wisdom />
            <Feeds />

        </>
    )
}