import Styles from "./OurStoryNew.module.scss";
import React from "react";
import { Link } from "react-router-dom";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { urls } from "../../utils/urls";

export default function OurStoryNew() {
  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["main-parent-inner-container"]}>
        <section>
          <div>
            <span className={Styles["titile"]}>
              {appLevelConstants.aboutUs.ourStoryTitle}
            </span>
          </div>
        </section>
        <div className={Styles["our-journy-container"]}>
          {appLevelConstants.aboutUs.ourStories &&
            appLevelConstants.aboutUs.ourStories.map((val, index) => (
              <>
                <div
                  className={
                    Styles[
                      index === 0
                        ? "stepper-container-left-first"
                        : "stepper-container-left"
                    ]
                  }
                >
                  {index !== 0 && (
                    <div className={Styles["vertical-line-top"]}></div>
                  )}
                  <img
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    src={urls.stepperIcon}
                    alt=""
                    className={Styles["icon"]}
                  ></img>
                  <div className={Styles["vertical-line"]}></div>
                </div>
                <div
                  className={
                    Styles[
                      index === 0
                        ? "stepper-container-right-first"
                        : "stepper-container-right"
                    ]
                  }
                >
                  <span
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    className={Styles["year-title"]}
                  >
                    {val.title}
                  </span>
                  {index === appLevelConstants.aboutUs.ourStories.length - 1 ? (
                    <>
                      {val.description.map((des, desIndex) => {
                        return (
                          <ul
                            key={des.id}
                            className={Styles["year-description-div"]}
                          >
                            <li
                              data-aos="fade-up"
                              data-aos-duration="1000"
                              data-aos-easing="ease-in-out"
                              className={Styles["year-description"]}
                            >
                              {des.value}
                              {desIndex === des.length - 1 ? (
                                ""
                              ) : (
                                <Link
                                  ata-aos="fade-up"
                                  data-aos-duration="1000"
                                  data-aos-easing="ease-in-out"
                                  className={Styles["year-home"]}
                                  to="/"
                                >
                                  {des.home}
                                </Link>
                              )}
                            </li>
                          </ul>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {val.description.map((des) => {
                        return (
                          <ul
                            key={des.id}
                            className={Styles["year-description-div"]}
                          >
                            <li
                              data-aos="fade-up"
                              data-aos-duration="1000"
                              data-aos-easing="ease-in-out"
                              className={Styles["year-description"]}
                            >
                              {des.value}
                            </li>
                          </ul>
                        );
                      })}
                    </>
                  )}
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
}
