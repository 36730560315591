import CryptoJS from 'crypto-js';
import { appLevelConstants } from '../constants/AppLevelConstants';

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

 export const encryptURL = (url) => {
  const key = appLevelConstants.REACT_APP_SECRET_KEY; // Replace with your own encryption key
  const encryptedURL = CryptoJS.AES.encrypt(url, key).toString();
  return encryptedURL;
};

export const decryptResponse = (encryptedResponse, key) => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedResponse, key);
  const decryptedResponse = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return decryptedResponse;
};

export const decryptObject = (encryptedResponse, key) => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedResponse, key);
  const decryptedResponse = decryptedBytes.toString(CryptoJS.enc.Utf8);
  const data = JSON.parse(decryptedResponse);
  return data;
};

export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const validatePhoneNumber = (phone) => {
  // const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const phoneNumberRegex = /^[\d()+\s-]{10,}$/;
  return phoneNumberRegex.test(phone);
};

export const WordCount = (str) => {
  return str.split(" ").length;
}

export const extractYouTubeVideoId = (url) => {
  const regex =
    /^https?:\/\/(?:www\.)?youtu(?:be\.com\/(?:watch\?(?:.*&)?v=|v\/|embed\/)|\.be\/)([\w\-_]+)(?:.*)$/i;
  const match = url?.match(regex);
  if (match) {
    return match[1];
  } else {
    return null;
  }
};

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }  
  return text.substring(0, maxLength) + '...';
}

export const formatDate = (dateString) => {

const options = { year: 'numeric', month: 'short', day: '2-digit' };
const [year, month, day] = dateString.split('-');
const formattedDate = new Date(year, month - 1, day).toLocaleDateString('en-US', options);
const [formattedMonth, formattedDay, formattedYear] = formattedDate.split(' ');
const formattedOutput = `${formattedDay} ${formattedMonth} ${formattedYear}`;
const formattedOutputWithoutComma = formattedOutput.replace(',', '');
return formattedOutputWithoutComma;
}

export const formatTimestampToTime = (timestamp) => {
  const date = new Date(timestamp);
    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();
    const period = utcHours >= 12 ? "PM" : "AM";

    const formattedHours = (utcHours % 12 || 12).toString().padStart(2, "0");
    const formattedMinutes = utcMinutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${period}`;
}

export const formatDatestampToDate = (dateString) => {
  const date = new Date(dateString);
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const removeCommaFromDate = (dateWithComma) => {
  return dateWithComma?.replace(/,/g, '');
}

