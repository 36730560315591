import React from "react";
import Styles from "./ImpactMain.module.scss";
import AOS from "aos";
import { useEffect, useState } from "react";
import "react-html5video/dist/styles.css";
import { urls } from "../../utils/urls";
import { appLevelConstants } from "../../constants/AppLevelConstants";

export default function ImpactMain() {
  
  const [profession, setProfession] = useState("CEOs");
  const getNextProfession = (currentProfession) => {
    switch (currentProfession) {
      case "CEOs":
        return "CHRO";
      case "CHRO":
        return "COOs";
      case "COOs":
        return "CFOs";
      case "CFOs":
        return "CTOs";
      case "CTOs":
        return "CxOs";
      case "CxOs":
        return "CEOs";
      default:
        return "CEOs";
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setProfession(prevProfession => getNextProfession(prevProfession));
    }, 2000);

    return () => {
      clearInterval(toggleInterval);
    };
  }, []);

  return (
    <div className={Styles["main-parent-div"]}>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        className={Styles["main-div"]}
      >
        
        <video
          className={Styles["aboutus-banner"]}
          controls
          poster={urls.impactThumbneil}
        >
          <source
            src={urls.impactVideo}
            type="video/mp4"
          />
        </video>
        <div className={Styles["toggle-heading"]}>
          <p className={Styles["toggle"]}>
          {appLevelConstants.impact.impactTitle1}</p>
          <p className={Styles["toggle"]}>{appLevelConstants.impact.impactTitle2} <span className={Styles["profession"]}>{profession}</span> {appLevelConstants.impact.impactTitle3} </p>
        </div>
      </div>
    </div>
  );
}
