import { appLevelConstants } from "../../constants/AppLevelConstants";
import Styles from "./Landing.module.scss";
import { useState, useEffect } from "react";
import Button from "../../components/buttons/ButtonWithLink";
import GetInTouch from "../common_components/forms/GetInTouch";
import { urls } from "../../utils/urls";

export default function Landing({ handleSucess, handleError }) {
  const screenWidth = window.innerWidth;
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    if (screenWidth > 768) {
      const timeout = setTimeout(() => {
        setShowDiv(true);
      }, 3000);
      return () => clearTimeout(timeout);
    } else {
      setShowDiv(true);
    }
  }, [screenWidth]);

  const [showDivColor, setShowDivColor] = useState(false);

  useEffect(() => {
    if (screenWidth > 768) {
      const timeout = setTimeout(() => {
        setShowDivColor(true);
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      setShowDivColor(true);
    }
  }, [screenWidth]);

  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  return (
    <>
      {dialogOpen && (
        <GetInTouch
          setDialogOpen={setDialogOpen}
          open={dialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}

      <div className={`${showDiv} ? '-' : ${Styles["parent-div"]}`}>
        {showDivColor && (
          <div className={Styles["main-container"]}>
            {showDiv && (
              <>
                <h1 className={Styles["power-tagline-container"]}>
                  {appLevelConstants.Home.MainTitle.mainTitle.map((item) => (
                    <span className={Styles["power-tagline"]} key={item.id}>
                      {item.title}
                    </span>
                  ))}
                </h1>

                <div className={Styles["small-para-container"]}>
                  <div className={Styles["small-para-new-container"]}>
                    <span className={Styles["small-para-new"]}>
                      {/* Iron lady is a{" "} */}
                      Iron Lady is the{" "}
                    </span>
                    <span className={Styles["bold"]}>
                      Leading Leadership platform{" "}
                    </span>
                    <span className={Styles["small-para-new"]}>
                      enabling women to fast-track their career growth and reach
                      the{" "}
                    </span>
                    <span className={Styles["bold"]}>TOP </span>
                    <span className={Styles["small-para-new"]}>using </span>
                    <span className={Styles["bold"]}>
                      Business War Tactics!
                    </span>
                  </div>
                  {/* ))} */}
                </div>

                {screen > 768 ? (
                  <div
                    id="exploreProgramButton"
                    className={Styles["button-div"]}
                  >
                    <button
                      onClick={() => window.open('https://zfrmz.in/boZr9Cru7BVFkZsqt5dV', '_self')}
                      className={Styles["explore-more"]}
                    >
                      Know More{" "}
                    </button>
                  </div>
                ) : (
                  <div className={Styles["mobile-button"]}>
                    <Button
                      linkTo="https://zfrmz.in/boZr9Cru7BVFkZsqt5dV"
                      name="Know More"
                      className={"button"}
                    ></Button>
                  </div>
                )}
              </>
            )}

            {/* <Link to="/"> */}

            <img className={Styles["black-logo"]} alt="" src={urls.logoGif}></img>
            {/* </Link> */}

            {showDiv && (
              <>
                {screen > 768 ? (
                  <div>
                    <span className={Styles["red-arrow"]}></span>
                    <img
                      className={Styles["lady-image"]}
                      fetchpriority="high"
                      alt=""
                      src={urls.ironLadyLanding}
                    ></img>
                  </div>
                ) : (
                  <>
                    <div>
                      <div className={Styles["mobile-red-arrow"]}></div>
                      <img
                        fetchpriority="high"
                        className={Styles["mobile-lady-image"]}
                        alt=""
                        src={urls.ironLadyLanding}
                      ></img>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
