import ButtonWithLinks from "../buttons/ButtonWithLink";
import Styles from "./LatestHappeningDetailPage.module.scss";
import { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import {
  getLatestHappeningData,
  getLatestHappeningSummary,
  updateUser,
} from "../../service/communityService";
import UserValidation from "../common_components/forms/user-validation/UserValidation";
import OtpValidation from "../common_components/forms/otpValidatoin/otpValidation";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import GetInTouch from "../common_components/forms/GetInTouch";
import { ToastContainer, toast } from "react-toastify";
import { formatDate } from "../../helper-fuction/helper";

export default function LatestHappeningDetailPage() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredItem, setFilteredItem] = useState({});
  const [summary, setSummary] = useState({});
  const [userValidationDialogOpen, setUserValidationDialogOpen] =
    useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState("initial");
  const [otpId, setOtpId] = useState("");
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [speakToUsDialogOpen, setSpeakToUsDialogOpen] = useState(false);

  const handleChallengeClickOpen = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));

    if (userData) {
      try {
        const updatedUserDetails = await updateUser(userData.zohoUserId);
        if (updatedUserDetails?.data.programEnrollmentDetails !== null &&
          updatedUserDetails?.data.programEnrollmentDetails.includes(
            filteredItem?.latest_happening_program_details
          )
        ) {
          window.open(filteredItem?.latest_happening_website_url, "_blank");
        } else {
          setMessageDialogOpen(true);
        }
      } catch (error) {
        throw error;
      }
    } else {
      setUserValidationDialogOpen(true);
    }
  };

  const handleEventClickOpen = () => {
    window.open(filteredItem?.latest_happening_website_url, "_blank");
  };

  const messageDialogClose = () => {
    setMessageDialogOpen(false);
  };

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const result = await getLatestHappeningData(page);
      const summary = await getLatestHappeningSummary();
      setSummary(summary);
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    // updateUserDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && data.length > 0) {
      const filtered = data.find((item) => item.id === parseInt(id));
      setFilteredItem(filtered || null);
    }
  }, [id, data]);

  useEffect(() => {
    if (id && !filteredItem && currentPage < summary.totalPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [id, filteredItem, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUserValidationResponse = (response) => {
    setOtpId(response.data.data.otpID);
    setMessage(response.data.message);
    setStatusCode(response.data.statusCode);
    if (response.data.statusCode === 200) {
      setOtpDialogOpen(true);
    }
  };

  const handleOtpVerificationUserDataResponse = (response) => {
    if (response.data.statusCode !== 200) {
      setOtpDialogOpen(true);
    } else {
      setOtpDialogOpen(false);
      if (response.data.data.programEnrollmentDetails !== null &&
        response.data.data.programEnrollmentDetails.includes(
          filteredItem?.latest_happening_program_details
        )
      )
        window.open(filteredItem?.latest_happening_website_url, "_blank");
      else {
        setMessageDialogOpen(true);
      }
    }
  };

  useEffect(() => {
    if (statusCode === 200) {
      setOtpDialogOpen(true);
    }
  }, [statusCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSpeakToUsDialog = () => {
    setSpeakToUsDialogOpen(true);
    setMessageDialogOpen(false);
  };

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  return (
    <>
      {userValidationDialogOpen && (
        <UserValidation
          open={userValidationDialogOpen}
          setDialogOpen={setUserValidationDialogOpen}
          onResponse={handleUserValidationResponse}
        ></UserValidation>
      )}

      <div className={Styles["main-parent-div"]}>
        <div className={Styles["back-button-container"]}>
          <ButtonWithLinks
            id="latestHappeningDetailPageBack"
            name="Back"
            linkTo="/latest"
            className={"button"}
          />
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3vw",
            }}
          >
            <ThreeCircles
              height="100"
              width="100"
              color="#F52929"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <>
            <div id={filteredItem?.id} className={Styles["main-section"]}>
              <div className={Styles["img-container"]}>
                <img
                  alt=""
                  className={Styles["img"]}
                  src={filteredItem?.latest_happening_banner}
                ></img>
              </div>

              <div className={Styles["des-venue-container"]}>
                <div className={Styles["description-container"]}>
                  <div className={Styles["title"]}>
                    {filteredItem?.latest_happening_title}
                  </div>

                  {filteredItem && (
                    <>
                      <div
                        className={Styles["description"]}
                        dangerouslySetInnerHTML={{
                          __html: filteredItem?.latest_happening_description,
                        }}
                      />
                    </>
                  )}
                </div>
                <div className={Styles["venue-card"]}>
                  <div className={Styles["date-label"]}> Start Date: </div>
                  <div className={Styles["date"]}>
                    {filteredItem?.latest_happening_start_date &&
                      formatDate(filteredItem?.latest_happening_start_date)}
                  </div>
                  <div className={Styles["date-label"]}> End Date: </div>
                  <div className={Styles["date"]}>
                    {filteredItem?.latest_happening_end_date &&
                      formatDate(filteredItem?.latest_happening_end_date)}
                  </div>
                  {filteredItem?.categoryName === "Event" && (
                    <>
                      <div className={Styles["date-label"]}> Venue: </div>
                      <div className={Styles["date"]}>
                        {filteredItem?.latest_happening_venue}
                      </div>
                    </>
                  )}
                  <div className={Styles["date-label"]}> Website Link: </div>
                  <a
                    href={filteredItem?.latest_happening_website_url}
                    className={Styles["date"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {filteredItem?.latest_happening_website_url}
                  </a>
                </div>
              </div>

              <div className={Styles["buttons-section"]}>
                <ButtonWithLinks
                  id="latestHappeningRegisteredButton"
                  name={
                    filteredItem?.categoryName === "Event"
                      ? "Register Now"
                      : "Enroll Now"
                  }
                  linkTo={"action"}
                  callback={
                    filteredItem?.categoryName === "Event"
                      ? handleEventClickOpen
                      : handleChallengeClickOpen
                  }
                  className={"button-width"}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {otpDialogOpen && (
        <OtpValidation
          open={otpDialogOpen}
          setOtpDialogOpen={setOtpDialogOpen}
          message={message}
          setMessage={setMessage}
          otpId={otpId}
          statusCode={statusCode}
          onResponseUserData={handleOtpVerificationUserDataResponse}
        ></OtpValidation>
      )}

      {messageDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={messageDialogOpen}
            onClose={messageDialogClose}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Sorry.. You are not eligible for this challenge."
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Speak To Us"
                  className={"button-job-detail-page"}
                  linkTo="action"
                  callback={handleSpeakToUsDialog}
                />
                <ButtonWithLinks
                  name="Close"
                  className={"button-job-detail-page"}
                  linkTo={"action"}
                  callback={messageDialogClose}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {speakToUsDialogOpen && (
        <GetInTouch
          setDialogOpen={setSpeakToUsDialogOpen}
          open={speakToUsDialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}

      <ToastContainer
        limit={1}
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId={"getInTouch"}
        enableMultiContainer
      />
    </>
  );
}
