import axios from 'axios';
import {urls} from '../../src/utils/urls';



export const getValidUser = async (fullName, email) => {
    try {
      const response = await axios.post(
        urls.userValidation,
        {
            fullName: fullName,
            emailId: email,
            leadSource : 'Website',
            appInstalled : false
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const getVerifyOtp = async (otpId, userOtp) => {
    try {
      const response = await axios.post(
        urls.otpVerification,
        {
            otpId: otpId,
            otp: userOtp
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const getResendOtp = async (otpId) => {
    try {
      const response = await axios.post(
        urls.resendOtp,
        {
            otpId: otpId
           
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };