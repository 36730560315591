import React from 'react';
import Styles from './BenefitCard.module.scss';


const BenefitCard = ({ benefit }) => {
    return (
        <div className={Styles["card"]}>
            <div className={Styles["card-title-container"]}>
                <h2 className={Styles["card-title"]}>{benefit.title}</h2>
            </div>

            <div className={Styles["card-image-container"]}>
                <img src={benefit.imageURL} alt={benefit.title} className={Styles["card-image"]} />
            </div>

            <div className={Styles["card-text-container"]}>
                {benefit.referrer && <p><div className={Styles["styled-content"]} dangerouslySetInnerHTML={{ __html: benefit.referrer }} /></p>}
                {benefit.referee && <p><div className={Styles["styled-content"]} dangerouslySetInnerHTML={{ __html: benefit.referee }} /></p>}
            </div>
        </div>

    )
}

export default BenefitCard