import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation } from "swiper";
import Styles from "./RelatedBlogs.module.scss";

SwiperCore.use([Navigation]);

const RelatedBlogs = ({ relatedBlogs, handleBlogClick }) => {
    return (
        <div className={Styles["related-blogs-slider"]}>
            {
                relatedBlogs.length > 0 && (
                    <h3>Related Blogs</h3>
                )
            }
            <Swiper
                spaceBetween={20}
                slidesPerView={1}
                navigation
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
            >
                {relatedBlogs.map((blog) => (
                    <SwiperSlide key={blog.id}>
                        <div className={Styles['blog-card']} onClick={() => handleBlogClick(blog)}>
                            <div className={Styles['blog-image']}>
                                <img src={blog.PostImageUrl} alt={blog.PostTitle} />
                            </div>
                            <div className={Styles['blog-title']}>
                                <h4>{blog.PostTitle}</h4>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default RelatedBlogs