import io from "socket.io-client";

const userData = JSON.parse(localStorage.getItem("userdata"));

export const socket = io("https://api.iamironlady.com/communityChat", {
  transports: ["websocket", "polling", "flashsocket"],
});

export const onlineStatusSocket = io(
  "https://api.iamironlady.com/userCommunityOnlineStatus",
  {
    transports: ["websocket", "polling", "flashsocket"],
    auth: {
      userId: userData?.zohoUserId,
    },
  }
);

export const communityGroupDetailsSocket = io(
  "https://api.iamironlady.com/communityGroupDetails",
  {
    transports: ["websocket", "polling", "flashsocket"],
  }
);

export const communityGroupUserDetailsSocket = io(
  "https://api.iamironlady.com/allCommunityGroupUser",
  {
    transports: ["websocket", "polling", "flashsocket"],
  }
);


export const deleteMessagesByAdmin = io(
  "https://api.iamironlady.com/messageDeleteForAdmin",
  {
    transports: ["websocket", "polling", "flashsocket"],
    secure: true,
  }
);
