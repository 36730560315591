import React, { useState, useEffect } from 'react';
import Styles from "./BlogsContainer.module.scss"
import { useNavigate } from 'react-router-dom';
import { getAllPostsData } from '../../service/communityService.js';
import { ThreeCircles } from 'react-loader-spinner';
import { urls } from '../../utils/urls.js';

const BlogsContainer = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleBlogs, setVisibleBlogs] = useState(6);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await getAllPostsData();
        const data = await JSON.parse(response);
        setBlogs(data.blog);

        const categoriesSet = new Set();
        const subCategoriesSet = new Set();

        data.blog.forEach(blog => {
          categoriesSet.add(blog.PostCategory);
          subCategoriesSet.add(blog.PostSubCategory);
        });

        setCategories([...categoriesSet]);
        setSubCategories([...subCategoriesSet]);

        setLoading(false);

      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    }

    fetchBlogs();
  }, [])

  const handleCategoryChange = (e) => {
    setCategory(e.target.value.toLowerCase());
    setVisibleBlogs(6);
  };

  const handleSubCategoryChange = (e) => {
    setSubCategory(e.target.value.toLowerCase());
    setVisibleBlogs(6);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setVisibleBlogs(6);
  };

  const filteredBlogs = blogs.filter(blog => {
    return (
      blog.PostTitle.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (category === '' || blog.PostCategory === category) &&
      (subCategory === '' || blog.PostSubCategory === subCategory)
    );
  });

  const loadMoreBlogs = () => {
    setVisibleBlogs(prevVisibleBlogs => prevVisibleBlogs + 6);
  };

  const capitalizeWords = (str) => {
    return str.split(/[\s-]+/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // <p className={Styles["blog-category"]}>Category : {blog.PostCategory.charAt(0).toUpperCase() + blog.PostCategory.slice(1) + " - " + blog.PostSubCategory}</p>
                      

  return (
    <div className={Styles["blogs-container"]}>
      {
        loading ? (
          <ThreeCircles
            height="70"
            width="70"
            color="#F52929"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "20vh",
            }}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
          />
        ) : (
          <>
            <div className={Styles["blog-main-title"]}>
              <span>Blogs</span>
            </div>
            <div className={Styles["blogs-filters"]}>
              <input
                type="text"
                placeholder="Search blogs..."
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
              <select value={category} onChange={handleCategoryChange}>
                <option value="">All Categories</option>
                {categories.map(cat => (
                  <option key={cat} value={cat}>{capitalizeWords(cat)}</option>
                ))}
              </select>
              <select value={subCategory} onChange={handleSubCategoryChange}>
                <option value="">All Subcategories</option>
                {subCategories.map(subCat => (
                  <option key={subCat} value={subCat}>{capitalizeWords(subCat)}</option>
                ))}
              </select>
            </div>
            <div className={Styles["blogs-list"]}>
              {
                filteredBlogs.length > 0 ? (
                  filteredBlogs.slice(0, visibleBlogs).map((blog) => (
                    <div key={blog.id} className={Styles["blog-card"]} onClick={() => navigate(`/blogs/${blog.PostCategory}/${blog.PostSubCategory}/${blog.PostSlug}`, { state: { id: blog.id, slug: blog.PostSlug, imageURL: blog.PostImageUrl } })} >
                      <img src={blog.PostImageUrl} alt={blog.PostTitle} className={Styles["blog-image"]} />
                      <h3 className={Styles["blog-title"]}>{blog.PostTitle}</h3>
                      <p className={Styles["blog-date"]}>Published : {new Date(blog.PostDate).toLocaleDateString('en-IN').replace(/'/g, '')}</p>
                    </div>
                  ))
                ) : (
                  <div className={Styles["no-data-container"]}>
                    <img src={urls.noData} alt="" className={Styles["no-data-image"]}></img>
                  </div>
                )
              }
            </div>

            <div className={Styles["load-more-container"]}>
              {visibleBlogs < filteredBlogs.length && (
                <button onClick={loadMoreBlogs} className={Styles["load-more-button"]}>Load More</button>
              )}
            </div>
          </>
        )
      }

    </div >
  )
}

export default BlogsContainer;