import "swiper/swiper-bundle.css";
import { useEffect, useRef  } from "react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, {Autoplay, EffectCoverflow, Pagination, Navigation } from "swiper";
import Styles from "./Glimpses.module.scss";
import {getGlimsesImages} from '../../service/urlService';
import { useState } from "react";

export default function Glimpses() {
  const swiperRef = useRef(null);
  const [glimsesImgUrls, setGlimsesImgUrls] = useState([]);
  SwiperCore.use([Autoplay, EffectCoverflow, Pagination, Navigation]);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance) {
      swiperInstance.autoplay.start();
    }
  }, []);

  useEffect(() => {
    glimsesImages();    
  }, [])

  const glimsesImages = async () => {      
    try{
      const result = await getGlimsesImages();
    setGlimsesImgUrls(result.data.data.urls);
    }catch(error) {
      console.log(error, 'error')
    }    
  }

  return (
    <div className={Styles["parent-div"]}>
      <h1 className={Styles["heading"]}>
        Glimpses from our Programs and Events
      </h1>
      <Swiper
      ref={swiperRef}
        effect={"coverflow"}
        autoplay={{
          delay: 2000,
        }}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className={Styles["swiper_container"]}
      >
        {glimsesImgUrls?.map((item, index) => {
          return(
            <SwiperSlide className={Styles["swiper"]} id={index}>
          <img src={item} className={Styles["img"]} alt="slide_image" />
        </SwiperSlide>
          )
        })
      }
      </Swiper>
    </div>
  );
}
