import React from "react";
import Styles from "./ScholarshipTestimonial.module.scss";
import Slider from "react-slick";
import { useLocation, useParams } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";
import { urls } from "../../../utils/urls";

const ScholarshipTestimonial = ({ title, data, buttonId }) => {
  const location = useLocation();
  const isIndividualPage = location.pathname.includes("/individualPrograms");
  const { language } = useParams();

  const handleClick = () => {
    if (isIndividualPage) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.location.href = "/programs";
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <div className={Styles["main-parent-div"]}>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        className={Styles["main-div"]}
      >
        <div className={Styles["main-container"]}>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            className={Styles["title-div"]}
          >
            <span className={Styles["title"]}>{title}</span>
          </div>

          <Slider {...settings}>
            {data &&
              data.map((item) => {
                return (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    key={item.id}
                    className={Styles["testimonial-div"]}
                  >
                    <div className={Styles["img-div"]}>
                      <img
                        className={Styles["img"]}
                        alt=""
                        src={item.image}
                      ></img>
                    </div>

                    <div className={Styles["details-div"]}>
                      <span className={Styles["name"]}>{item.name}</span>
                      <span className={Styles["designation"]}>{item.designation}</span>
                      <span className={Styles["description"]}>{item.text}</span>
                      <div
                        className={Styles["join-community"]}
                        onClick={handleClick}
                        role="button"
                        tabIndex={0}
                      >
                        <button id={buttonId} className={Styles["join"]} type="button">
                          {language === "hindi" ? "कार्यक्रमों का पता लगाएं" : "Explore Programs"}
                        </button>
                        <img
                          alt=""
                          src={urls.rightUpArrow}
                          className={Styles["right-arrow"]}
                        />
                      </div>

                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipTestimonial;