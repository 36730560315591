import React from "react";
import Styles from "./IndividualProgramsQuestions.module.scss";
import AOS from "aos";
import { useEffect, useState } from "react";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { urls } from "../../utils/urls";
import { useParams } from "react-router-dom";

export default function IndividualProgramsQuestions({ filteredData }) {
  useEffect(() => {
    AOS.init();
  }, []);

  const { programName, language } = useParams();

  let faqList;

  if (language === 'hindi') {
    faqList = appLevelConstants.individualProgramsFAQData_Hindi.faq[programName] || [];
  } else {
    faqList = appLevelConstants.individualProgramsFAQData.faq[programName] || [];
  }

  return (
    <div className={Styles["details"]}>
      <div className={Styles["structure-section-divide"]}>
        <div className={Styles["structure-main-div"]}>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            className={Styles["program-structure-main-container"]}
          >
            <div className={Styles["div-container-1"]}>
              <span className={Styles["title"]}>
                {filteredData?.programStructure.title}
              </span>
            </div>

            <div className={Styles["div-container-2"]}>
              <div className={Styles["left-div"]}>
                <span className={Styles["left-div-title"]}>
                  {filteredData?.programStructure.preparationTitle}
                </span>
                {filteredData?.programStructure.preparation &&
                  filteredData?.programStructure.preparation.map((item) => {
                    return (
                      <div>
                        <ul key={item.id}>
                          <li>{item.value}</li>
                        </ul>
                      </div>
                    );
                  })}
              </div>

              <div className={Styles["right-div"]}>
                <span className={Styles["right-div-title"]}>
                  {filteredData?.programStructure.sessionTitle}
                </span>
                {filteredData?.programStructure.session &&
                  filteredData?.programStructure.session.map((item) => {
                    return (
                      <div>
                        <ul key={item.id}>
                          <li>{item.value}</li>
                        </ul>
                      </div>
                    );
                  })}
              </div>

              <div className={Styles["left-div"]}>
                <span className={Styles["left-div-title"]}>
                  {filteredData?.programStructure.certificateTitle}
                </span>
                {filteredData?.programStructure.certificate &&
                  filteredData?.programStructure.certificate.map((item) => {
                    return (
                      <div>
                        <ul key={item.id}>
                          <li>{item.value}</li>
                        </ul>
                      </div>
                    );
                  })}
              </div>

              <div className={Styles["right-div"]}>
                <span className={Styles["right-div-title"]}>
                  {filteredData?.programStructure.alsoIncludesTitle}
                </span>
                {filteredData?.programStructure.alsoIncludes &&
                  filteredData?.programStructure.alsoIncludes.map((item) => {
                    return (
                      <div>
                        <ul key={item.id}>
                          <li>{item.value}</li>
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles["program-details"]}>
        <span
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["program"]}
        >
          {
            language === 'hindi' ? "सामान्य प्रश्नोत्तर" : "FAQ Related to Program"
          }

        </span>

        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["question-box-div"]}
        >
          {faqList.map((faq) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                id={faq.id}
                className={faq.id === 5 ? Styles["box-last"] : Styles["box"]}
              >
                <Accordion
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  showQuestion={true}
                  isExpand={faq.id === 1 ? true : false}
                  title={faq.question}
                  className={Styles["question"]}
                >
                  <span className={Styles["answer"]}>A. </span>
                  <div>{faq.answer}</div>
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const Accordion = ({ children, title, isExpand = false, showQuestion }) => {
  const [expand, setExpand] = useState(isExpand);

  return (
    <div>
      <div
        className={Styles["title-box"]}
        onClick={() => setExpand((expand) => !expand)}
      >
        <span className={Styles["question"]}>
          {showQuestion === true ? "Q" : " "}
        </span>
        <span
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["title"]}
        >
          {title}
        </span>
        <span
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["icon"]}
        >
          <img
            src={urls.upArrow}
            alt=""
            className={`${Styles["playCircleClassName"]}  ${!expand ? Styles["down"] : ""
              }`}
          ></img>
        </span>
        <div className={Styles["clearfix"]}></div>
      </div>
      {expand && <div className={Styles["content"]}>{children}</div>}
    </div>
  );
};
