import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonWithLink from "../../../buttons/ButtonWithLink";
import Styles from "./UserValidation.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { validateEmail } from "../../../../helper-fuction/helper";
import { DialogContent } from "@mui/material";
import { appLevelConstants } from "../../../../constants/AppLevelConstants";
import { getValidUser } from "../../../../service/userValidation";
import "react-toastify/dist/ReactToastify.css";

const UserValidation = ({ open, setDialogOpen, onResponse }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  let hasError = false;

  const submitUserValidation = async (fullName, email) => {
    try {
      setLoader(true);
      const response = await getValidUser(fullName, email);
      setDialogOpen(false);
      onResponse(response);
    } catch (error) {
      setDialogOpen(false);
      console.log(error);
      setLoader(false);
    }
  };

  const handleUserValidationSubmit = async (e) => {
    e.preventDefault();

    if (fullName.trim() === "") {
      setFullNameError(appLevelConstants.Errors.fullName);
      hasError = true;
    } else {
      setFullNameError("");
    }

    if (!validateEmail(email)) {
      setEmailError(appLevelConstants.Errors.email);
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!hasError) {
      submitUserValidation(fullName, email);
    }
  };
  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  return (
    <>
      <div className={Styles["dialog-main-div"]}>
        <Dialog
          className={Styles["dialog-div"]}
          open={open}
          onClose={handleClose}
        >
          <form
            id="speak-to-our-counsellor"
            onSubmit={handleUserValidationSubmit}
          >
            <DialogTitle
              sx={{ bgcolor: "#F52929", color: "black" }}
              className={Styles["dialog-title"]}
            >
              Enrollment
            </DialogTitle>

            <DialogContent className={Styles["dialog-content-div"]}>
              <TextField
                autoFocus
                // margin="dense"
                id="fullName"
                name="fullName"
                label="Full Name"
                type="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                fullWidth
                sx={{ marginTop: "10px" }}
              />
              {fullNameError && (
                <div style={{ color: "#F52929" }}>{fullNameError}</div>
              )}

              <TextField
                // margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                sx={{ marginTop: "10px" }}
              />
              {emailError && (
                <div style={{ color: "#F52929" }}>{emailError}</div>
              )}
            </DialogContent>
            <DialogActions className={Styles["dialog-action-button"]}>
              <div onClick={handleUserValidationSubmit}>
                <ButtonWithLink
                  name={loader ? "Submtting" : "Submit"}
                  type="submit"
                  className={"button"}
                />
              </div>

              <div onClick={handleClose}>
                <ButtonWithLink name="Cancel" className={"button"} />
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </>
  );
};

export default UserValidation;
