import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonWithLink from "../../buttons/ButtonWithLink";
import Styles from "./GetInTouch.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { encryptURL } from "../../../helper-fuction/helper";
import { decryptResponse } from "../../../helper-fuction/helper";
import { validateEmail } from "../../../helper-fuction/helper";
import { validatePhoneNumber } from "../../../helper-fuction/helper";
import { WordCount } from "../../../helper-fuction/helper";

import {
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../../helper-fuction/helper";
import { appLevelConstants } from "../../../constants/AppLevelConstants";
import { getInTouch } from "../../../service/formService";

const GetInTouch = ({
  open,
  setDialogOpen,
  sucessToasterGIT,
  errorToasterGIT,
  programName,
  isDemo,
  isRegister,
  isWorkProfile,
  isExp,
  isSeeYourSelf,
}) => {
  let getContactUsDateTime = new Date().toJSON();
  const leadSource = capitalizeFirstLetter(
    localStorage.getItem("leadSourceName")
  );

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [preference, setPreference] = useState("");
  const [workProfile, setWorkProfile] = useState("NA");
  const [message, setMessage] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneerror] = useState("");
  const [preferenceError, setPreferenceError] = useState("");
  const [workProfileError, setWorkProfileError] = useState("");
  const [experience, setExperience] = useState("NA");
  const [seeYourself, setSeeYourself] = useState("");
  const [experienceError, setExperienceError] = useState("");

  let count = WordCount(fullName);
  let space, firstName, lastName;
  if (count < 2) {
    space = fullName.indexOf(" ");
    firstName = fullName.substring(space + 1);
    lastName = "------";
  } else {
    space = fullName.indexOf(" ");
    firstName = fullName.substring(0, space);
    lastName = fullName.substring(space + 1);
  }

  var urlToCallGetInTouch = encryptURL(
    "https://www.zohoapis.in/crm/v2/functions/create_il_lead/actions/execute?auth_type=apikey&zapikey=1003.fc9035d274089c149e601eaab0f7bb2c.42d592278f3009bdbe4ac29ee38008f5" +
      "&firstName=" +
      firstName +
      "&lastName=" +
      lastName +
      "&fullName=" +
      fullName +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&preference=" +
      preference +
      "&message=" +
      message +
      "&leadSource=" +
      leadSource +
      "&currentLeadStatus=" +
      appLevelConstants.forms.getInTouch +
      "&getContactUsDateTime=" +
      getContactUsDateTime +
      "&program=" +
      programName +
      "&experience=" +
      experience +
      "&workProfile=" +
      workProfile +
      "&seeYourself=" +
      seeYourself +
      ""
  );

  let hasError = false;

  const submitGetIntouch = async (url) => {
    try {
      const response = await getInTouch(url);
      const encryptedMessage = response.data.data;
      const decryptMessage = decryptResponse(
        encryptedMessage,
        appLevelConstants.REACT_APP_SECRET_KEY
      );
      setDialogOpen(false);
      sucessToasterGIT(decryptMessage);
    } catch (error) {
      setDialogOpen(false);
      console.log(error);
      errorToasterGIT(error.response.data.message);
    }
  };
  const handleGITSubmit = async (e) => {
    e.preventDefault();

    if (fullName.trim() === "") {
      setFullNameError(appLevelConstants.Errors.fullName);
      hasError = true;
    } else {
      setFullNameError("");
    }

    if (!validateEmail(email)) {
      setEmailError(appLevelConstants.Errors.email);
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!validatePhoneNumber(phone)) {
      setPhoneerror(appLevelConstants.Errors.phone);
      hasError = true;
    } else {
      setPhoneerror("");
    }

    if (preference === "") {
      setPreferenceError(appLevelConstants.Errors.preference);
      hasError = true;
    } else {
      setPreferenceError("");
    }

    if (workProfile === "") {
      setWorkProfileError(appLevelConstants.Errors.workProfile);
      hasError = true;
    } else {
      setWorkProfileError("");
    }

    if (experience === "") {
      setExperienceError(appLevelConstants.Errors.experience);
      hasError = true;
    } else {
      setExperienceError("");
    }

    if (!hasError) {
      submitGetIntouch(urlToCallGetInTouch);
    }
  };
  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  return (
    <>
      <div className={Styles["dialog-main-div"]}>
        <Dialog
          className={Styles["dialog-div"]}
          open={open}
          // onClose={handleClose}
        >
          <form id="speak-to-our-counsellor" onSubmit={handleGITSubmit}>
            <DialogTitle
              sx={{ bgcolor: "#F52929", color: "black" }}
              className={Styles["dialog-title"]}
            >
              {appLevelConstants.forms.getInTouchTitle}
            </DialogTitle>

            <DialogContent className={Styles["dialog-content-div"]}>
              <TextField
                autoFocus
                // margin="dense"
                id="fullName"
                name="fullName"
                label="Full Name"
                type="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                fullWidth
                sx={{ marginTop: "10px" }}
              />
              {fullNameError && (
                <div style={{ color: "#F52929" }}>{fullNameError}</div>
              )}

              <TextField
                // margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                sx={{ marginTop: "10px" }}
              />
              {emailError && (
                <div style={{ color: "#F52929" }}>{emailError}</div>
              )}

              <TextField
                // margin="dense"
                id="phone"
                name="phone"
                label="Phone Number"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                fullWidth
                sx={{ marginTop: "10px", marginBottom: "13px" }}
              />
              {phoneError && (
                <div style={{ color: "#F52929" }}>{phoneError}</div>
              )}
              <FormLabel
                id="demo-radio-buttons-group-label"
                margin="normal"
                sx={{ fontWeight: "bold", color: "black", marginTop: "10px" }}
              >
                {appLevelConstants.forms.preferenceLabel}
              </FormLabel>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                id="preference"
                name="preference"
                value={preference}
                onChange={(e) => setPreference(e.target.value)}
              >
                <FormControlLabel
                  value="Get a call from our counsellor"
                  control={
                    <Radio
                      sx={{
                        color: "#F52929",
                        "&.Mui-checked": {
                          color: "#F52929",
                        },
                      }}
                    />
                  }
                  label="Get a call from our counsellor"
                  className={Styles["radio-button"]}
                />
                <FormControlLabel
                  value="Get program information over email & WA"
                  control={
                    <Radio
                      sx={{
                        color: "#F52929",
                        "&.Mui-checked": {
                          color: "#F52929",
                        },
                      }}
                    />
                  }
                  label="Get program information over email & WA"
                />
                {isRegister && (
                  <FormControlLabel
                    value="Register for the program"
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label="Register for the program"
                  />
                )}
                {isDemo && (
                  <FormControlLabel
                    value="Get a demo class"
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label="Get a demo class"
                  />
                )}
              </RadioGroup>
              {preferenceError && (
                <div style={{ color: "#F52929" }}>{preferenceError}</div>
              )}

              {isWorkProfile && (
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  margin="normal"
                  sx={{ fontWeight: "bold", color: "black" }}
                >
                  {appLevelConstants.forms.workProfile}
                </FormLabel>
              )}

              {isWorkProfile && (
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  id="workProfile"
                  name="workProfile"
                  value={workProfile}
                  onChange={(e) => setWorkProfile(e.target.value)}
                >
                  <FormControlLabel
                    value="Working Professional"
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label="Working Professional"
                    className={Styles["radio-button"]}
                  />
                  <FormControlLabel
                    value="Entrepreneur"
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label="Entrepreneur"
                  />
                  <FormControlLabel
                    value="Not working"
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label="Not working"
                  />
                  <FormControlLabel
                    value="Other"
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label="Other"
                  />
                </RadioGroup>
              )}

              {workProfileError && isWorkProfile && (
                <div style={{ color: "#F52929" }}>{workProfileError}</div>
              )}

              {isExp && (
                <TextField
                  // margin="dense"
                  id="experience"
                  name="experience"
                  label="No. of years of experience"
                  type="text"
                  value={experience !== "NA" ? experience : ""}
                  onChange={(e) => setExperience(e.target.value)}
                  // required
                  fullWidth
                  sx={{ marginTop: "10px" }}
                />
              )}

              {experienceError && isExp && (
                <div style={{ color: "#F52929" }}>{experienceError}</div>
              )}

              {isSeeYourSelf && (
                <TextField
                  // margin="dense"
                  id="seeYourself"
                  name="seeYourself"
                  label="Where do you want to see yourself in 3 years?"
                  type="text"
                  value={seeYourself}
                  onChange={(e) => setSeeYourself(e.target.value)}
                  fullWidth
                  sx={{ marginTop: "10px" }}
                />
              )}

              <TextField
                // autoFocus
                margin="dense"
                id="Message"
                label="Anything that you want to let us know"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                fullWidth
                sx={{ color: "black", marginTop: "10px" }}
                multiline
                rows={4}
              />
            </DialogContent>
            <DialogActions className={Styles["dialog-action-button"]}>
              <div onClick={handleGITSubmit}>
                <ButtonWithLink
                  name="Submit"
                  type="submit"
                  className={"button"}
                />
              </div>

              <div onClick={handleClose}>
                <ButtonWithLink name="Cancel" className={"button"} />
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </>
  );
};

export default GetInTouch;
