import { appLevelConstants } from "../../../constants/AppLevelConstants";
import Styles from "./Footer.module.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaRegNewspaper,
  FaYoutube
} from "react-icons/fa";
import { XIcon } from 'react-share';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { useMemo } from "react";
import { urls } from "../../../utils/urls";
import { Link } from "react-router-dom";

export default function Footer(props) {
  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  const location = useLocation();
  const currentPath = location.pathname;
  let { footer } = appLevelConstants;
  let currentYear = new Date().getFullYear();

  const id = useMemo(() => {
    return location.pathname.split("/")[3];
  }, [location]);

  return (
    <>
      <div
        className={
          Styles[
          props.style !== "active"
            ? "footer-border-bg-color"
            : "footer-border-bg-color-pages"
          ]
        }
        style={
          currentPath === "/success-story/author/" + id ||
            currentPath === "/" ||
            currentPath === "/latest"
            ? { marginTop: 0 }
            : {}
        }
      >
        {screen >= 769 ? (
          <>
            <div className={Styles["footer-f"]}>
              <div className={Styles["left-container"]}>
                <img
                  src={urls.whiteLogo}
                  alt=""
                  className={Styles["red-logo"]}
                ></img>
                <div className={Styles["footer-message-div"]}>
                  <span className={Styles["footer-message"]}>
                    {appLevelConstants.footer.elevating}
                  </span>
                </div>
              </div>

              {/* <div className={Styles["center-container"]}>
                <div className={Styles["download"]}>
                  {appLevelConstants.footer.download}
                </div>

                <div className={Styles["logo-container"]}>
                  <a
                    href={urls.appStore}
                    target="_blank"
                    rel="noreferrer"
                    className={Styles["logo-box"]}
                  >
                    <FontAwesomeIcon
                      icon={faGooglePlay}
                      size="3x"
                      className={Styles["logo-icon"]}
                    />
                    <span className={Styles["logo-name"]}>
                      {appLevelConstants.footer.android}
                    </span>
                  </a>

                  <a
                    href={urls.appStore}
                    target="_blank"
                    rel="noreferrer"
                    className={Styles["logo-box"]}
                  >
                    <FontAwesomeIcon
                      icon={faApple}
                      size="3x"
                      className={Styles["logo-icon"]}
                    />
                    <span className={Styles["logo-name"]}>
                      {appLevelConstants.footer.ios}
                    </span>
                  </a>
                </div>
              </div> */}

              <div className={Styles["right-container"]}>
                <div className={Styles["follow-us"]}>
                  {appLevelConstants.footer.follow}
                </div>

                <div className={Styles["footer-container"]}>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilYoutube} target="_blank" rel="noreferrer">
                      <FaYoutube className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilTwitter} target="_blank" rel="noreferrer">
                      <XIcon size={20} className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilFacebook} target="_blank" rel="noreferrer">
                      <FaFacebookF style={{'color': 'blue'}} className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilLinkedon} target="_blank" rel="noreferrer">
                      <FaLinkedinIn style={{'color': 'blue'}}
                        className={Styles["footer-icon-linkedin"]}
                      />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilInstagram} target="_blank" rel="noreferrer">
                      <FaInstagram style={{'color': '#ff5a77'}} className={Styles["footer-icon-insta"]} />
                    </a>
                  </div>

                  <div className={Styles["socialMediaHandler-container"]}>
                    <Link to="/newsletter">
                      <FaRegNewspaper className={Styles["footer-icon-insta"]} />
                    </Link>
                  </div>

                </div>
              </div>
            </div>

            <div className={Styles["terms-div"]}>
              <a
                href="https://d1ixtxt2uzttul.cloudfront.net/Documents/Terms%20%26%20Conditions%20for%20Leadership%20Essential%20Program.pdf"
                target="_blank"
                rel="noreferrer"
                className={Styles["terms"]}
              >
                Terms & Conditions
              </a>

              <a
                href="https://iamironlady.com/individualPrograms/Leadership_Essentials_Program/policy"
                target="_blank"
                rel="noreferrer"
                className={Styles["terms"]}
              >
                Cancellation policy
              </a>

              <a
                href="https://d1ixtxt2uzttul.cloudfront.net/Documents/PRIVACY+STATEMENT+-+IRON+LADY.pdf"
                target="_blank"
                rel="noreferrer"
                className={Styles["terms"]}
              >
                Privacy policy
              </a>

              <a
                href="https://iamironlady.com/contactUs"
                rel="noreferrer"
                className={Styles["terms"]}
              >
                Contact Us
              </a>

              <a
                href="https://careers.iamironlady.com/jobs/Careers"
                rel="noreferrer"
                className={Styles["terms"]}
              >
                Careers
              </a>
            </div>

            <div className={Styles["footer-copyright"]}>
              <span className={Styles["red-line"]}></span>
              <label className={Styles["copyright"]}>
                {footer.c}
                {currentYear}
                {footer.copyRight}
              </label>

              <label style={{ 'textTransform': 'uppercase' }} className={Styles["copyright"]}>
                {footer.unit}
              </label>
            </div>
          </>
        ) : (
          <>
            <div className={Styles["footer-f"]}>
              <div className={Styles["left-container"]}>
                <img
                  src={urls.whiteLogo}
                  alt=""
                  className={Styles["red-logo"]}
                ></img>
                <div className={Styles["footer-message-div"]}>
                  <span className={Styles["footer-message"]}>
                    {appLevelConstants.footer.elevating}
                  </span>
                </div>
              </div>

              <div className={Styles["center-container"]}>
                {/* <div className={Styles["download"]}>
                  {appLevelConstants.footer.download}
                </div>

                <div className={Styles["logo-container"]}>
                  <a
                    href={urls.appStore}
                    target="_blank"
                    rel="noreferrer"
                    className={Styles["logo-box"]}
                  >
                    <FontAwesomeIcon
                      icon={faGooglePlay}
                      size="3x"
                      className={Styles["logo-icon"]}
                    />
                    <span className={Styles["logo-name"]}>
                      {appLevelConstants.footer.android}
                    </span>
                  </a>

                  <a
                    href={urls.appStore}
                    target="_blank"
                    rel="noreferrer"
                    className={Styles["logo-box"]}
                  >
                    <FontAwesomeIcon
                      icon={faApple}
                      size="3x"
                      className={Styles["logo-icon"]}
                    />
                    <span className={Styles["logo-name"]}>
                      {appLevelConstants.footer.ios}
                    </span>
                  </a>
                </div> */}

                <div className={Styles["follow-us"]}>
                  {appLevelConstants.footer.follow}
                </div>

                <div className={Styles["footer-container"]}>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilYoutube} target="_blank" rel="noreferrer">
                      <FaYoutube className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilTwitter} target="_blank" rel="noreferrer">
                      <XIcon size={20} className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilFacebook} target="_blank" rel="noreferrer">
                      <FaFacebookF style={{'color': 'blue'}} className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilLinkedon} target="_blank" rel="noreferrer">
                      <FaLinkedinIn style={{'color': 'blue'}}
                        className={Styles["footer-icon-linkedin"]}
                      />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilInstagram} target="_blank" rel="noreferrer">
                      <FaInstagram style={{'color': '#ff5a77'}} className={Styles["footer-icon-insta"]} />
                    </a>
                  </div>

                  <div className={Styles["socialMediaHandler-container"]}>
                    <Link to="/newsletter">
                      <FaRegNewspaper className={Styles["footer-icon-insta"]} />
                    </Link>
                  </div>

                </div>
              </div>
            </div>

            <div className={Styles["footer-copyright"]}>
              <div className={Styles["right-container"]}>
                {/* <div className={Styles["follow-us"]}>
                  {appLevelConstants.footer.follow}
                </div>

                <div className={Styles["footer-container"]}>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilTwitter} target="_blank" rel="noreferrer">
                      <XIcon className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilFacebook} target="_blank" rel="noreferrer">
                      <FaFacebookF className={Styles["footer-icon-linkedin"]} />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilLinkedon} target="_blank" rel="noreferrer">
                      <FaLinkedinIn
                        className={Styles["footer-icon-linkedin"]}
                      />
                    </a>
                  </div>
                  <div className={Styles["socialMediaHandler-container"]}>
                    <a href={urls.ilInstagram} target="_blank" rel="noreferrer">
                      <FaInstagram className={Styles["footer-icon-insta"]} />
                    </a>
                  </div>
                </div> */}

                <div className={Styles["terms-div"]}>
                  <a
                    href="https://d1ixtxt2uzttul.cloudfront.net/Documents/Terms%20%26%20Conditions%20for%20Leadership%20Essential%20Program.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles["terms"]}
                  >
                    Terms & Conditions
                  </a>

                  <a
                    href="https://iamironlady.com/individualPrograms/Leadership_Essentials_Program/policy"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles["terms"]}
                  >
                    Cancellation policy
                  </a>

                  <a
                    href="https://d1ixtxt2uzttul.cloudfront.net/Documents/PRIVACY+STATEMENT+-+IRON+LADY.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className={Styles["terms"]}
                  >
                    Privacy policy
                  </a>
                  <a
                    href="https://iamironlady.com/contactUs"
                    rel="noreferrer"
                    className={Styles["terms"]}
                  >
                    Contact Us
                  </a>

                  <a
                    href="https://careers.iamironlady.com/jobs/Careers"
                    rel="noreferrer"
                    className={Styles["terms"]}
                  >
                    Careers
                  </a>
                </div>
              </div>
              <div className={Styles["red-line-div"]}>
                <span className={Styles["red-line"]}></span>
                <label className={Styles["copyright"]}>
                  {footer.c}
                  {currentYear}
                  {footer.copyRight}
                </label>
                <br />
                <label style={{ 'textTransform': 'uppercase', 'marginBottom': '8px' }} className={Styles["copyright"]}>
                  {footer.unit}
                </label>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
