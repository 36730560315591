// import React, { forwardRef } from "react";
// import Styles from "./ProgramCurtators.module.scss";
// import AOS from "aos";
// import { useState, useEffect } from "react";
// import { appLevelConstants } from "../../constants/AppLevelConstants";
// import { FaLinkedinIn } from "react-icons/fa";

// const ProgramCurtators = forwardRef((props, ref) => {
//   useEffect(() => {
//     AOS.init();
//   }, []);

//   const [screen, setScreenWidth] = useState(window.innerWidth);
//   const handleResize = () => {
//     setScreenWidth(window.innerWidth);
//   };

//   useEffect(() => {
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     setScreenWidth(screen);
//   }, [screen]);

//   return (
//     <div className={Styles["program-curtators"]}>
//       <span
//         data-aos="fade-up"
//         data-aos-duration="1000"
//         data-aos-easing="ease-in-out"
//         className={Styles["heading"]}
//       >
//         Meet the Iron Lady Team
//       </span>
//       {appLevelConstants.program.programs.map((item) => {
//         return (
//           <>
//             <div className={Styles["leader"]}>
//               {screen >= 768 ? (
//                 <div
//                   data-aos="fade-up"
//                   data-aos-duration="1000"
//                   data-aos-easing="ease-in-out"
//                   className={Styles["leaders"]}
//                 >
//                   <div className={Styles["profile"]}>
//                     <div className={Styles["profile-image"]}>
//                       <img
//                         className={Styles["leaders-image"]}
//                         alt=""
//                         src={item.img}
//                       ></img>
//                     </div>
//                   </div>
//                   <div>
//                     <span className={Styles["leader-name"]}>{item.name} </span>
//                     <div className={Styles["leader-post-container"]}>
//                       <span className={Styles["leader-post"]}>{item.post}</span>
//                       <a href={item.linkedin} target="_blank" rel="noreferrer">
//                         <div className={Styles["icon-linkedin-container"]}>
//                           <FaLinkedinIn className={Styles["icon-linkedin"]} />
//                         </div>
//                       </a>
//                     </div>
//                     <div className={Styles["description"]}>{item.content}</div>
//                   </div>
//                 </div>
//               ) : (
//                 <div
//                   data-aos="fade-up"
//                   data-aos-duration="1000"
//                   data-aos-easing="ease-in-out"
//                   className={Styles["leaders"]}
//                 >
//                   <div className={Styles["profile"]}>
//                     <div className={Styles["profile-image"]}>
//                       <div className={Styles["profile-image-mob"]}>
//                         <div
//                           style={{
//                             display: "flex",
//                             justifyContent: "flex-end",
//                           }}
//                         >
//                           <span className={Styles["leader-name"]}>
//                             {item.name}
//                           </span>
//                         </div>
//                         {screen <= 600 && item.id === 5 ? (
//                           <div
//                             className={Styles["leader-post-mobile-container"]}
//                           >
//                             <span className={Styles["leader-post-mobile"]}>
//                               {item.post}
//                             </span>
//                           </div>
//                         ) : (
//                           <span className={Styles["leader-post"]}>
//                             {item.post}
//                           </span>
//                         )}
//                         <a
//                           className={Styles["icon-linkedin-mob"]}
//                           href={item.linkedin}
//                           target="_blank"
//                           rel="noreferrer"
//                         >
//                           <div className={Styles["icon-linkedin-container"]}>
//                             <FaLinkedinIn className={Styles["icon-linkedin"]} />
//                           </div>
//                         </a>
//                       </div>
//                       <div className={Styles["leaders-image-mob"]}>
//                         <img
//                           className={Styles["leaders-image"]}
//                           alt=""
//                           src={item.img}
//                         ></img>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <div className={Styles["description"]}>{item.content}</div>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </>
//         );
//       })}
//     </div>
//   );
// });

// export default ProgramCurtators;

import React, { forwardRef, useRef } from "react";
import Styles from "./ProgramCurtators.module.scss";
import AOS from "aos";
import { useState, useEffect } from "react";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { FaLinkedinIn } from "react-icons/fa";

const ProgramCurtators = forwardRef(({ scrollToId }, ref) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  const profileRefs = useRef({});

  const scrollToProfile = (id) => {
    const profileRef = profileRefs.current[id];
    if (profileRef) {
      profileRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        profileRef.style.marginTop = '160px'; 
      }, 500);
    }
  };
  
  useEffect(() => {
    if (scrollToId !== undefined) {
      const timer = setTimeout(() => {
        scrollToProfile(scrollToId);
      }, 300); 
  
      return () => clearTimeout(timer);
    }
  }, [scrollToId]);

  return (
    <div className={Styles["program-curtators"]}>
      <span
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        className={Styles["heading"]}
      >
        Meet the Iron Lady Team
      </span>
      {appLevelConstants.program.programs.map((item) => {
        return (
          <>
            <div  key={item.id}
            ref={(el) => (profileRefs.current[item.id] = el)} className={Styles["leader"]}>
              {screen >= 768 ? (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["leaders"]}
                >
                  <div className={Styles["profile"]}>
                    <div className={Styles["profile-image"]}>
                      <img
                        className={Styles["leaders-image"]}
                        alt=""
                        src={item.img}
                      ></img>
                    </div>
                  </div>
                  <div>
                    <span className={Styles["leader-name"]}>{item.name} </span>
                    <div className={Styles["leader-post-container"]}>
                      <span className={Styles["leader-post"]}>{item.post}</span>
                      <a href={item.linkedin} target="_blank" rel="noreferrer">
                        <div className={Styles["icon-linkedin-container"]}>
                          <FaLinkedinIn className={Styles["icon-linkedin"]} />
                        </div>
                      </a>
                    </div>
                    <div className={Styles["description"]} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                  </div>
                </div>
              ) : (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["leaders"]}
                >
                  <div className={Styles["profile"]}>
                    <div className={Styles["profile-image"]}>
                      <div className={Styles["profile-image-mob"]}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span className={Styles["leader-name"]}>
                            {item.name}
                          </span>
                        </div>
                        {screen <= 600 && item.id === 5 ? (
                          <div
                            className={Styles["leader-post-mobile-container"]}
                          >
                            <span className={Styles["leader-post-mobile"]}>
                              {item.post}
                            </span>
                          </div>
                        ) : (
                          <span className={Styles["leader-post"]}>
                            {item.post}
                          </span>
                        )}
                        <a
                          className={Styles["icon-linkedin-mob"]}
                          href={item.linkedin}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className={Styles["icon-linkedin-container"]}>
                            <FaLinkedinIn className={Styles["icon-linkedin"]} />
                          </div>
                        </a>
                      </div>
                      <div className={Styles["leaders-image-mob"]}>
                        <img
                          className={Styles["leaders-image"]}
                          alt=""
                          src={item.img}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={Styles["description"]} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                  </div>
                </div>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
});

export default ProgramCurtators;