import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonWithLink from "../../../buttons/ButtonWithLink";
import Styles from "./otpValidation.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DialogContent } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { getVerifyOtp, getResendOtp } from "../../../../service/userValidation";
import OtpInput from "react-otp-input";

export default function OtpValidation({
  open,
  setOtpDialogOpen,
  statusCode,
  message,
  setMessage,
  otpId,
  onResponseUserData,
}) {
  const [userOtp, setUserOtp] = useState("");
  const [userOtpError, setUserOtpError] = useState("");
  const [otpResendMessage, setOtpResendMessage] = useState("");
  const [isResend, setIsResend] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  let hasError = false;

  const handleClose = () => {
    setOtpDialogOpen(false);
  };

  const submitUserOtpVerification = async (otpId, userOtp) => {
    try {
      setIsResend(false);
      const response = await getVerifyOtp(otpId, userOtp);
      // setMessage(response.data.message);

      if (response.data.statusCode !== 200) {
        setMessage(response.data.message);
        handleClearInput();
      } else {
        localStorage.setItem("userdata", JSON.stringify(response.data.data));
        onResponseUserData(response);
      }
    } catch (error) {
      setOtpDialogOpen(false);
      console.log(error);
    }
  };

  const handleClearInput = () => {
    setUserOtp("");
  };

  const resendOtp = async (otpId) => {
    try {
      setResendLoading(true);
      const response = await getResendOtp(otpId);
      setOtpResendMessage(response.data.message);
    } catch (error) {
      setResendLoading(false);
      setOtpDialogOpen(false);
    } finally {
      setResendLoading(false);
    }
  };

  const handleResendOtpSubmit = async (e) => {
    e.preventDefault();
    setIsResend(true);
    handleClearInput();
    resendOtp(otpId);
  };

  const handleUserValidationSubmit = async (e) => {
    e.preventDefault();

    if (userOtp.trim() === "" || userOtp.length !== 6) {
      setUserOtpError("Please enter valid OTP");
      hasError = true;
    } else {
      setUserOtpError("");
    }
    if (!hasError) {
      submitUserOtpVerification(otpId, userOtp);
    }
  };

  // useEffect(() => {
  //   localStorage.setItem("userdata", JSON.stringify(userData))
  // }, [userData]);

  return (
    <div className={Styles["dialog-main-div"]}>
      <Dialog
        className={Styles["dialog-div"]}
        open={open}
        onClose={handleClose}
      >
        <form id="otp-validation">
          <DialogTitle
            sx={{ bgcolor: "#F52929", color: "black" }}
            className={Styles["dialog-title"]}
          >
            OTP Verification
          </DialogTitle>

          <DialogContent className={Styles["dialog-content-div"]}>
            <div className={Styles["otp-msg"]}>{!isResend && message}</div>
            <div className={Styles["otp-msg"]}>
              {isResend && otpResendMessage}
            </div>
            <DialogTitle className={Styles["otp-dialog-title"]}>
              Enter OTP
            </DialogTitle>

            {/* <TextField
                autoFocus
                id="otp"
                name="Otp"
                label="Enter Otp"
                type="Enter Otp"
                value={userOtp}
                onChange={(e) => setUserOtp(e.target.value)}
                required
                fullWidth
                sx={{ marginTop: "10px" }}
              /> */}

            <OtpInput
              value={userOtp}
              onChange={setUserOtp}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props) => <input {...props} />}
              containerStyle={Styles["otp-container"]}
              inputStyle={Styles["otp-input-style"]}
            />
            {/* )} */}
            {userOtpError && (
              <div style={{ color: "#F52929", justifyContent: "center" }}>
                {userOtpError}
              </div>
            )}

            <div className={Styles["resend"]} onClick={handleResendOtpSubmit}>
              {resendLoading ? "Resending..." : "Resend?"}
            </div>
          </DialogContent>
          <DialogActions className={Styles["dialog-action-button"]}>
            <div onClick={handleUserValidationSubmit}>
              <ButtonWithLink
                name="Verify"
                type="submit"
                className={"button"}
              />
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
