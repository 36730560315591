import React from "react";
import Styles from "./video-view.module.scss";
import Dialog from "@mui/material/Dialog";
import ButtonWithLinks from "../buttons/ButtonWithLink";

function VideoView({ open, setDialogOpen, url }) {
  const handleClose = () => {
    setDialogOpen(false);
  };
  return (
    <div className={Styles["main-container"]}>
      <Dialog
        PaperProps={{
          style: { borderRadius: "0px" },

        }}
        className={Styles["dialog-div"]}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='md'
      >
        <div className={Styles["video-section"]}>
          <div className={Styles["inner-card"]}>
            <iframe
              title=" "
              className={Styles["iframe"]}
              src={url}
              allowFullScreen
              id="fitvid725928"
            ></iframe>
          </div>
        </div>
        <div className={Styles["joinUs-button"]}>
          <ButtonWithLinks name="Close" linkTo="action" className={"button"} callback={handleClose} />
        </div>
      </Dialog>
    </div>
  );
}

export default VideoView;
