import "swiper/swiper-bundle.css";
import { useEffect, useRef } from "react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper";
import Styles from "./ScholarshipCarousel.module.scss";
import { useState } from "react";
import { urls } from "../../utils/urls.js";

SwiperCore.use([Autoplay, EffectCoverflow, Pagination, Navigation]);

const ScholarshipCarousel = () => {
  const swiperRef = useRef(null);
  const [glimpsesImgUrls, setGlimpsesImgUrls] = useState([
    urls.scholarshipImg1,
    urls.scholarshipImg2,
    urls.scholarshipImg3
  ]);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance) {
      swiperInstance.autoplay.start();
    }
  }, []);

  return (
    <div className={Styles["parent-div"]}>
      <Swiper
        ref={swiperRef}
        effect={"flip"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={1}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className={Styles["swiper_container"]}
      >
        {glimpsesImgUrls.map((item, index) => (
          <SwiperSlide key={index} className={Styles["swiper"]}>
            <img src={item} className={Styles["img"]} alt={`slide_${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ScholarshipCarousel;