import React, { useEffect, useState } from "react";
import Styles from "./Champions.module.scss";
import { urls } from "../../utils/urls.js";
import { appLevelConstants } from "../../constants/AppLevelConstants.js";
import BenefitCard from "../benefit-card/BenefitCard.js";
import { ImArrowRight } from "react-icons/im";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Refer from "../common_components/forms/Refer.js";
import Referral from "../champion-referral/Referral.js";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ChampionCarousel from "../champion-carousel/ChampionCarousel.js";

const WFCard = ({ title, description }) => {
  return (
    <div className={Styles["wf-card"]}>
      <h2 className={Styles["wf-card-title"]}>{title}</h2>
      <p className={Styles["wf-card-description"]}>{description}</p>
    </div>
  );
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.5rem', 'color': '#f52929' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Champions = () => {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSucessToaster = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "refer",
    });
  };
  const handleErrorToaster = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "refer",
    });
  };

  const openZohoForm = () => {
    window.open('https://zfrmz.in/pSL0dMLey9HQpijevx2S', '_blank');
  };

  useEffect(()=> {
    const script = document.createElement('script');

    script.innerHTML = `
      function ZFLead() {}
      ZFLead.utmPValObj = ZFLead.utmPValObj || {};
      ZFLead.utmPNameArr = new Array('utm_source','utm_medium','utm_campaign','utm_term','utm_content','Referral_Owner','Referral_Owner_Phone','Referral_Owner_Mail');
      ZFLead.prototype.zfutm_getLeadVal = function(pName) {
        var qStr = '';
        try { qStr = window.top.location.search.substring(1); } catch (e) { qStr = ''; }
        var pNameTemp = pName + '=';
        var pValue = '';
        if (typeof qStr !== "undefined" && qStr !== null && qStr.length > 0) {
          var begin = qStr.indexOf(pNameTemp);
          if (begin != -1) {
            begin = begin + pNameTemp.length;
            end = qStr.indexOf('&', begin);
            if (end == -1) { end = qStr.length; }
            pValue = decodeURIComponent(qStr.substring(begin, end));
          }
        }
        if (pValue == undefined || pValue == '') { pValue = this.zfutm_gC(pName); }
        return pValue;
      };
      ZFLead.prototype.zfutm_sC = function(paramName,path,domain,secure) {
        var value = ZFLead.utmPValObj[paramName];
        if (typeof value !== "undefined" && value !== null) {
          var cookieStr = paramName + "=" + encodeURIComponent(value);
          var exdate = new Date();
          exdate.setDate(exdate.getDate() + 7);
          cookieStr += "; expires=" + exdate.toGMTString();
          cookieStr += "; path=/";
          if (domain) { cookieStr += "; domain=" + encodeURIComponent(domain); }
          if (secure) { cookieStr += "; secure"; }
          document.cookie = cookieStr;
        }
      };
      ZFLead.prototype.zfutm_ini = function() {
        for (var i = 0; i < ZFLead.utmPNameArr.length; i++) {
          var zf_pN = ZFLead.utmPNameArr[i];
          var zf_pV = this.zfutm_getLeadVal(zf_pN);
          if (typeof zf_pV !== "undefined" && zf_pV !== null) { ZFLead.utmPValObj[zf_pN] = zf_pV; }
        }
        for (var pkey in ZFLead.utmPValObj) { this.zfutm_sC(pkey); }
      };
      ZFLead.prototype.zfutm_gC = function(cookieName) {
        var cookieArr = document.cookie.split('; ');
        for (var i = 0; i < cookieArr.length; i++) {
          var cookieVals = cookieArr[i].split('=');
          if (cookieVals[0] === cookieName && cookieVals[1]) { return decodeURIComponent(cookieVals[1]); }
        }
      };
      ZFLead.prototype.zfutm_gC_enc = function(cookieName) {
        var cookieArr = document.cookie.split('; ');
        for (var i = 0; i < cookieArr.length; i++) {
          var cookieVals = cookieArr[i].split('=');
          if (cookieVals[0] === cookieName && cookieVals[1]) { return cookieVals[1]; }
        }
      };
      ZFLead.prototype.zfutm_iframeSprt = function() {
        var zf_frame = document.getElementsByTagName("iframe");
        for (var i = 0; i < zf_frame.length; ++i) {
          if ((zf_frame[i].src).indexOf('formperma') > 0) {
            var zf_src = zf_frame[i].src;
            for (var prmIdx = 0; prmIdx < ZFLead.utmPNameArr.length; prmIdx++) {
              var utmPm = ZFLead.utmPNameArr[prmIdx];
              var utmVal = this.zfutm_gC_enc(ZFLead.utmPNameArr[prmIdx]);
              if (typeof utmVal !== "undefined") {
                if (zf_src.indexOf('?') > 0) { zf_src = zf_src + '&' + utmPm + '=' + utmVal; } else { zf_src = zf_src + '?' + utmPm + '=' + utmVal; }
              }
            }
            if (zf_frame[i].src.length < zf_src.length) { zf_frame[i].src = zf_src; }
          }
        }
      };
      ZFLead.prototype.zfutm_DHtmlSprt = function() {
        var zf_formsArr = document.forms;
        for (var frmInd = 0; frmInd < zf_formsArr.length; frmInd++) {
          var zf_form_act = zf_formsArr[frmInd].action;
          if (zf_form_act && zf_form_act.indexOf('formperma') > 0) {
            for (var prmIdx = 0; prmIdx < ZFLead.utmPNameArr.length; prmIdx++) {
              var utmPm = ZFLead.utmPNameArr[prmIdx];
              var utmVal = this.zfutm_gC(ZFLead.utmPNameArr[prmIdx]);
              if (typeof utmVal !== "undefined") {
                var fieldObj = zf_formsArr[frmInd][utmPm];
                if (fieldObj) { fieldObj.value = utmVal; }
              }
            }
          }
        }
      };
      ZFLead.prototype.zfutm_jsEmbedSprt = function(id) {
        document.getElementById('zforms_iframe_id').removeAttribute("onload");
        var jsEmbdFrm = document.getElementById("zforms_iframe_id");
        var embdSrc = jsEmbdFrm.src;
        for (var prmIdx = 0; prmIdx < ZFLead.utmPNameArr.length; prmIdx++) {
          var utmPm = ZFLead.utmPNameArr[prmIdx];
          var utmVal = this.zfutm_gC_enc(ZFLead.utmPNameArr[prmIdx]);
          if (typeof utmVal !== "undefined") {
            if (embdSrc.indexOf('?') > 0) { embdSrc = embdSrc + '&' + utmPm + '=' + utmVal; } else { embdSrc = embdSrc + '?' + utmPm + '=' + utmVal; }
          }
        }
        jsEmbdFrm.src = embdSrc;
      };
      var zfutm_zfLead = new ZFLead();
      zfutm_zfLead.zfutm_ini();
      if (document.readyState == "complete") {
        zfutm_zfLead.zfutm_iframeSprt();
        zfutm_zfLead.zfutm_DHtmlSprt();
      } else {
        window.addEventListener('load', function() {
          zfutm_zfLead.zfutm_iframeSprt();
          zfutm_zfLead.zfutm_DHtmlSprt();
        }, false);
      }
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    
  }, [])

  return (
    <div className={Styles["champions-container"]}>
      {/*<div className={Styles["banner-section"]}>
        <img
          src={urls.championBanner}
          alt="banner"
          className={Styles["banner-image"]}
        />
      </div>*/}

      <ChampionCarousel />

      <div className={Styles["purpose-container"]}>
        <span className={Styles["main-title"]}>Purpose</span>

        <span className={Styles["purpose-para"]}>
          Play a crucial role with your valuable referrals, creating a supportive community and enabling a "Million Women at the Top" together!        
        </span>
      </div>

      <div className={Styles["purpose-container"]}>
        <span className={Styles["main-title"]}>Outcome</span>

        <span className={Styles["purpose-para"]}>
        Foster an ecosystem where women support each other and achieve collective success. Just like you, referrals will harness the power of our 78,000+ strong community of successful professionals and entrepreneurs to win in life.
        </span>
      </div>

      <div style={{ marginTop: "10px" }}>
        <span className={Styles["main-title"]}>How Does It Work?</span>
      </div>

      <div className={Styles["card-layout"]}>
        {appLevelConstants.referralSteps.map((card, index) => (
          <React.Fragment key={index}>
            <WFCard title={card.title} description={card.description} />
            {index < appLevelConstants.referralSteps.length - 1 && (
              <div>
                <ImArrowRight className={Styles["arrow"]} />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <div style={{ 'textAlign': "center", margin: '2rem' }}>
        <button className={Styles["button-black"]} onClick={openZohoForm}>Refer Here</button>
      </div>

      <div>
        <span className={Styles["main-title"]}>Benefits</span>
      </div>

      <div className={Styles["benefits-container"]}>
        {appLevelConstants.referralBenefits.map((benefit) => (
          <BenefitCard key={benefit.id} benefit={benefit} />
        ))}
      </div>

      {/*<Referral
        sucessToaster={handleSucessToaster}
        errorToaster={handleErrorToaster}
      />*/}

      <div className={Styles["main-container"]}>
        <span className={Styles["main-title"]}>FAQ Related to Program</span>

        <div className={Styles["faq-container"]}>
          {appLevelConstants.referralProgram.faq.map((item, index) => (
            <Accordion
              key={item.id}
              expanded={expanded === `panel${item.id}`}
              onChange={handleChange(`panel${item.id}`)}
            >
              <AccordionSummary
                aria-controls={`panel${item.id}d-content`}
                id={`panel${item.id}d-header`}
              >
              <Typography className={Styles["summary-typography"]}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography className={Styles["details-typography"]}>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>


    </div>
  );
};

export default Champions;
