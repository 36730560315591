import ImpactMain from "../components/impact/ImpactMain";
import SuccessStory from "../components/impact-success-story/SuccessStory";
import { Helmet } from "react-helmet";

export default function Impact() {
  return (
    <>
      <Helmet>
        <title>Women Creating Impact with Iron Lady</title>
        <meta name="description" content="Meet the extraordinary women who achieved top leadership success and created lasting impact with Iron Lady. Explore their inspiring journeys and discover how our programs empower women to lead with confidence, strategy, and purpose." />
      </Helmet>

      <ImpactMain />
      <SuccessStory />
    </>
  );
}
