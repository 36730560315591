import { useNavigate } from "react-router-dom";
import Style from "../buttons/ButtonWithLink.module.scss";

export default function ButtonWithLink({ className, linkTo, name, callback, id }) {
  let navigate = useNavigate();

  const handleClick = () => {
    if (callback) callback();
  };

  const handleNavigation = () => {
    if (linkTo === 'action') {
      handleClick();
    } else if (linkTo.startsWith('http')) {
      window.open(linkTo, '_self'); 
    } else {
      navigate(linkTo); 
    }
  };

  return (
    <button
      id={id}
      className={Style[className]}
      onClick={handleNavigation}
    >
      {name}
    </button>
  );
}
