import Styles from "./ProgramCurriculum.module.scss";
import AOS from "aos";
import { useEffect, useState } from "react";
import { urls } from "../../utils/urls";

export default function ProgramCurriculum({ filteredData }) {
  useEffect(() => {
    AOS.init();
  }, []);

  const [ID, setId] = useState(true);
  useEffect(() => {
    AOS.init();
  }, []);

  const showDiv = (id) => {
    if (ID === null) {
      setId(id);
    } else if (ID === id) {
      setId(null);
    } else {
      setId(id);
    }
  };

  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["main-container"]}>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["main-title-div"]}
        >
          <span className={Styles["main-title"]}>
            {filteredData?.programModuleDetails.title}
          </span>
        </div>
        <div className={Styles["collapse-div"]}>
          {filteredData?.programModuleDetails?.programModuleDetails &&
            filteredData?.programModuleDetails?.programModuleDetails.map(
              (item, index) => {
                return (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    className={
                      Styles[
                        index !==
                        filteredData?.programModuleDetails?.programModuleDetails
                          .length -
                          1
                          ? "session-div"
                          : "session-div-last"
                      ]
                    }
                    // className={Styles["session-div"]}
                    key={index}
                  >
                    <div className={Styles["session-1"]}>{item.session}</div>

                    <div className={Styles["session-arrow-div"]}>
                      <div
                        className={
                          Styles[item.id !== ID ? "session" : "session-after"]
                        }
                        onClick={() => showDiv(item.id)}
                      >
                        {item.title}
                      </div>
                      <img
                        src={urls.upArrow}
                        alt=""
                        className={
                          Styles[item.id !== ID ? "up-arrow" : "up-arrow-after"]
                        }
                        onClick={() => showDiv(item.id)}
                      ></img>
                    </div>

                    {item.id === ID && (
                      <div className={Styles["session-details-div"]}>
                        {item.value.map((val) => {
                          return (
                            <ul className={Styles["details"]} key={val.id}>
                              <li>{val.value1}</li>
                            </ul>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
}
