import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useEffect } from "react";
import Styles from "./ProgramInformation.module.scss";
import ButtonWithLink from "../buttons/ButtonWithLink";
import Refer from "../common_components/forms/Refer";
import AOS from "aos";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { ThreeCircles } from "react-loader-spinner";
import { urls } from "../../utils/urls";

function ProgramInformation(_props, ref) {
  const elementsRef = useRef([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const apiData = useSelector((state) => state.apiData);
  const [loading, setLoading] = useState(false);
  const [programInformation, setProgramInformation] = useState([]);

  useImperativeHandle(ref, () => ({ scrollToElement }), [elementsRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    if (Object.keys(apiData)?.length) {
      setProgramInformation(apiData.programInformation);
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [apiData]);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleSucessToaster = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "refer",
    });
  };
  const handleErrorToaster = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "refer",
    });
  };

  const scrollToElement = (index) => {
    if (elementsRef.current[index]) {
      const targetElement = elementsRef.current[index];
      const targetOffset = targetElement.offsetTop - 100; // Add 100 pixels to the target element's offsetTop
      window.scrollTo({
        top: targetOffset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3vw",
            marginBottom: "3vw",
          }}
        >
          <ThreeCircles
            height="100"
            width="100"
            color="#F52929"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      )}
      {dialogOpen && (
        <Refer
          setDialogOpen={setDialogOpen}
          open={dialogOpen}
          sucessToaster={handleSucessToaster}
          errorToaster={handleErrorToaster}
        />
      )}
      {loading === false && programInformation?.length !== 0 && (
        <div className={Styles["container"]}>
          <span className={Styles["title"]}>Our Programs</span>
          {programInformation &&
            programInformation.map((program, index) => (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["programs"]}
                key={index}
                ref={(ref) => (elementsRef.current[index] = ref)}
              >
                <div className={Styles["image"]}>
                  <img
                    className={Styles["programImage"]}
                    alt=""
                    src={program.image}
                  ></img>
                </div>

                <Link to={program.to} className={Styles["showSection"]}>
                  <p className={Styles["mainTitle"]}>{program.title}</p>
                  <p className={Styles["title"]}>
                    <p className={Styles["heading"]}>{program.duration}</p>
                    <p className={Styles["description"]}>
                      {program.description}
                    </p>

                    <p className={Styles["value"]}>{program.value1}</p>

                    <p className={Styles["value"]}>{program.value2}</p>
                  </p>
                  <Link className={Styles["know-more-div"]} to={program.to}>
                    <a className={Styles["know-more"]}>Know More</a>
                    <img
                      alt=""
                      src={urls.rightUpArrow}
                      className={Styles["right-arrow"]}
                    ></img>
                  </Link>

                  <span className={Styles["red-line"]}></span>
                </Link>
              </div>
            ))}

          <div className={Styles["join_us"]}>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out" 
              onClick={() => window.open('https://zfrmz.in/boZr9Cru7BVFkZsqt5dV', '_self')}
            >
              <ButtonWithLink
                name="Learn More"
                className={"button"}
                id="refer_programs"
              />
            </div>
          </div>
          <ToastContainer
            limit={1}
            position="bottom-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            containerId={"refer"}
            enableMultiContainer
          />
        </div>
      )}
    </>
  );
}

export default forwardRef(ProgramInformation);
