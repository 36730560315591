import React from 'react';

const TestPDF = () => {

  return (
    <div>
      <embed
        src="https://iron-lady.s3.ap-south-1.amazonaws.com/transfer/Newsletter_-_May_FINAL.pdf"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
  );
};

export default TestPDF;
