import Styles from "./JobPostsDetailPage.module.scss";
import { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import ButtonWithLinks from "../buttons/ButtonWithLink";
import { getJobPostsSummary, getJobPostsData } from "../../service/jobPosts";
import { urls } from "../../utils/urls";

export default function JobPostsDetailPage() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredItem, setFilteredItem] = useState({});
  const [summary, setSummary] = useState({});

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const result = await getJobPostsData(page);
      const summary = await getJobPostsSummary();
      setSummary(summary);
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && data.length > 0) {
      const filtered = data.find((item) => item.jobPostId === parseInt(id));
      setFilteredItem(filtered || null);
    }
  }, [id, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && !filteredItem && currentPage < summary.totalPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [id, filteredItem, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToRegistration = () => {
    window.open(filteredItem?.jobPostRegistrationLink, "_blank");
  };

  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["back-button-container"]}>
        <ButtonWithLinks
          id="jobPostsDetailPageBack"
          name="Back"
          linkTo="/jobposts"
          className={"button-job-detail-page"}
        />
      </div>

      {loading ? (
        <ThreeCircles
          height="70"
          width="70"
          color="#F52929"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "20vh",
          }}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
        />
      ) : (
        <>
          <div className={Styles["top-part"]}>
            <div className={Styles["card"]} id={filteredItem?.jobPostId}>
              <div className={Styles["card-top"]}>
                <img
                  alt=""
                  className={Styles["company-logo"]}
                  src={filteredItem?.companyDetails?.companyLogo}
                ></img>
                <div className={Styles["right-div"]}>
                  <span className={Styles["job-post-title"]}>
                    {filteredItem?.jobPostTitle}
                  </span>
                  <span className={Styles["company-name"]}>
                    {filteredItem?.companyDetails?.companyName}
                  </span>
                </div>
              </div>
              <div className={Styles["card-bottom"]}>
                <div className={Styles["icon-div"]}>
                  <img alt="" className={Styles["icon"]} src={urls.locationIcon}></img>
                  <span className={Styles["location"]}>
                    {filteredItem?.jobLocation}
                  </span>
                </div>
                <div className={Styles["icon-div"]}>
                  <img alt="" className={Styles["icon"]} src={urls.experienceIcon}></img>
                  <span className={Styles["location"]}>
                    {filteredItem?.experienceRange}
                  </span>
                </div>
                <div className={Styles["icon-div"]}>
                  <img alt="" className={Styles["icon"]} src={urls.rupeeIcon}></img>
                  <span className={Styles["location"]}>Confidential</span>
                </div>
              </div>
              <div className={Styles["work-type-div"]}>
                <span className={Styles["work-type-title"]}>Work Type: </span>
                <span className={Styles["work-type"]}>
                  {" "}
                  {filteredItem?.jobMethodology}
                </span>
              </div>
            </div>
          </div>

          <div className={Styles["bottom-part"]}>
            <div className={Styles["card-2"]}>
              <div className={Styles["description-title"]}>Description</div>
              {filteredItem &&
              filteredItem?.jobDescription?.startsWith("<s") ? (
                <>
                  <div
                    className={Styles["description"]}
                    dangerouslySetInnerHTML={{
                      __html: filteredItem?.jobDescription,
                    }}
                  />
                </>
              ) : (
                <>
                  <span className={Styles["description"]}>
                    {filteredItem?.jobDescription}
                  </span>
                </>
              )}
              <div className={Styles["buttons-section"]}>
                <div className={Styles["register-button"]}>
                  <ButtonWithLinks
                    id="viewCompanyJobpostsDetailPage"
                    name="View Company"
                    linkTo={
                      "/companyView/" + filteredItem?.companyDetails?.companyId
                    }
                    className={"button-job-detail-page"}
                    //   callback={handleCallback}
                  />
                </div>
                <div className={Styles["program-button"]}>
                  <ButtonWithLinks
                    id="instantApplyJobpostsDetailPage"
                    name="Instant Apply"
                    linkTo="action"
                    callback={redirectToRegistration}
                    className={"button-job-detail-page"}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
