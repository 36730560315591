import Styles from "./AdminDashboard.module.scss";
import BarChart from "../bar-charts/BarCharts";
import LineCharts from "../line-chart/LineCharts";
import Select from "react-select";
import {
  getAdminDahsboardData,
  getProgramAndBatchDetails,
} from "../../service/dashboardService";
import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import ButtonWithLinks from "../buttons/ButtonWithLink";

export default function AdminDashboard() {
  const [selectedProgramOption, setSelectedProgramOption] = useState(null);
  const [selectedBatchOption, setSelectedBatchOption] = useState(null);
  const [showRightDiv, setShowRightDiv] = useState(false);
  const [showLeftDiv, setShowLeftDiv] = useState(true);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [detailNotFound, setDetailNotFound] = useState(false);
  const [noBachesPopUpOpen, setNoBachesPopUpOpen] = useState(false);
  const [summeryData, setSummeryData] = useState({});
  const [individualProgressTracker, setIndividualProgressTracker] = useState(
    []
  );
  const [individual100BmData, setIndividual100BmData] = useState([]);
  const [individualLineData, setIndividualLineData] = useState([]);
  const [summeryFinalData, setSummeryFinalData] = useState([]);
  const [graphFilter, setGraphFilter] = useState({});
  const [filterClick, setFilterClick] = useState(null);
  const [graph100BMFilter, setGraph100BMFilter] = useState([]);
  const [filter100BMClick, setFilter100BMClick] = useState(null);
  const [allUnFilteredLineData, setAllUnFilteredLineData] = useState({});

  const [userDataFilter, setUserDataFIlter] = useState([]);
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [batchOptions, setBatchOptions] = useState({});
  const [programOptions, setProgramOptions] = useState([]);
  const [details, setDetails] = useState([]);

  const handleCloseDetails = () => {
    setDetailNotFound(false);
  };
  const handleCloseBatchesPopUp = () => {
    setNoBachesPopUpOpen(false);
  };

  useEffect(() => {
    handleProgramBatch();
  }, []);
  const handleProgramBatch = async () => {
    try {
      const result = await getProgramAndBatchDetails();
      if (result?.statusCode === 200) {
        setDetails(result.data.programs);
        const transData = result?.data?.programs?.map((item) => ({
          label: item.programName,
          value: item.programName,
        }));
        setProgramOptions(transData);
      } else {
        setDetailNotFound(true);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleFilterButtonClick = (val) => {
    filterBarGraph(val);
    setFilterClick(val);
  };

  useEffect(() => {
    if (graphFilter.length > 0) {
      setFilterClick(graphFilter[0]);
    }
  }, [graphFilter]);

  const handleProgramDropdownChange = (selected) => {
    setSelectedProgramOption(selected);
    if (selected?.label === "100 Board Members Program") {
      setShowLeftDiv(false);
      setShowRightDiv(true);
    }
    setSelectedBatchOption(null);
    setSelectedUserOption(null);
    setUserDataFIlter(null);
    const filteredProgram = details.find(
      (item) => item.programName === selected.label
    );
    if (filteredProgram) {
      const batchData = filteredProgram.programBatches.map((batch) => ({
        label: batch,
        value: batch,
      }));
      setBatchOptions(batchData);
    }
    // if (batchData.length === 0) {
    //   setNoBachesPopUpOpen(true);
    // }
  };

  const filteredUserLineData = (data, user) => {
    var rawData = [];
    data?.forEach((val) => {
      if (Object.keys(val)[0] === user) {
        rawData = val;
      }
    });
    return rawData;
  };

  //LEP
  const handleUserDropdownChange = (selected) => {
    setSelectedUserOption(selected);
    let rawData = {
      dailyRituals: filteredUserLineData(
        allUnFilteredLineData?.dailyRituals,
        selected.label
      ),
      BHAGProgress: filteredUserLineData(
        allUnFilteredLineData?.BHAGProgress,
        selected.label
      ),
      challengeParticipationDays: filteredUserLineData(
        allUnFilteredLineData?.challengeParticipationDays,
        selected.label
      ),
      hoursSavedThisWeekThroughERRC: filteredUserLineData(
        allUnFilteredLineData?.hoursSavedThisWeekThroughERRC,
        selected.label
      ),
      leadershipDashboard: filteredUserLineData(
        allUnFilteredLineData?.leadershipDashboard,
        selected.label
      ),
      expressRitual: filteredUserLineData(
        individual100BmData?.ExpressRitual,
        selected.label
      ),
      onePerson: filteredUserLineData(
        individual100BmData?.OnePerson,
        selected.label
      ),

      reachOutRitual: filteredUserLineData(
        individual100BmData?.ReachOutRitual,
        selected.label
      ),
      reachedOutTopMgmt: filteredUserLineData(
        individual100BmData?.ReachedOutTopMgmt,
        selected.label
      ),
      visualization: filteredUserLineData(
        individual100BmData?.Visualization,
        selected.label
      ),
      ceosBoardMembersConnected: filteredUserLineData(
        individual100BmData?.CeosBoardMembersConnected,
        selected.label
      ),
    };
    const graphData = {
      dailyRituals: formatDailyRitualsData(rawData?.dailyRituals),
      BHAGProgress: formatLineData(rawData?.BHAGProgress),
      challengeParticipationDays: formatLineData(
        rawData?.challengeParticipationDays
      ),
      hoursSavedThisWeekThroughERRC: formatLineData(
        rawData?.hoursSavedThisWeekThroughERRC
      ),
      leadershipDashboard: formatLineData(rawData?.leadershipDashboard),

      expressRitual: formatBarGraph100BMData(rawData?.expressRitual),
      onePerson: formatBarGraph100BMData(rawData?.onePerson),
      reachOutRitual: formatBarGraph100BMData(rawData?.reachOutRitual),
      reachedOutTopMgmt: formatBarGraph100BMData(rawData?.reachedOutTopMgmt),
      visualization: formatBarGraph100BMData(rawData?.visualization),
      ceosBoardMembersConnected: formatBarGraph100BMData(
        rawData?.ceosBoardMembersConnected
      ),
    };
    setIndividualLineData(graphData);
  };

  const handleBatchDropdownChange = (selected) => {
    handleGetAdminDashboardData(
      selected.value,
      selectedProgramOption.value,
      selected
    );
    setGraph100BMFilter([]);
    setSelectedUserOption(null);
  };

  const handleCloseAlert = () => {
    setAlertDialogOpen(false);
    setSelectedBatchOption(null);
  };

  const handleLeft = () => {
    setShowLeftDiv(true);
    setShowRightDiv(false);
  };
  const handleRight = () => {
    setShowRightDiv(true);
    setShowLeftDiv(false);
  };

  const yAxisConfigPercentage = {
    beginAtZero: true,
    max: 100,
    ticks: {
      callback: function (value) {
        return value + "%";
      },
    },
    title: {
      display: true,
      text: "Progress",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigHoursSaved = {
    beginAtZero: true,
    max: 100,
    title: {
      display: true,
      text: "Hours",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigDailyRituals = {
    beginAtZero: true,
    max: 35,
    ticks: {
      stepSize: 5,
    },
    title: {
      display: true,
      text: "Days",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigWeeklyChallenge = {
    beginAtZero: true,
    max: 7,
    ticks: {
      stepSize: 1,
    },
    title: {
      display: true,
      text: "Days",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigLeadershipScore = {
    beginAtZero: true,
    max: 20,
    ticks: {
      stepSize: 2,
    },
    title: {
      display: true,
      text: "Score",
      fontFamily: "Gemunu-regular",
    },
  };

  const handleGetAdminDashboardData = async (
    batchId,
    programName,
    selected
  ) => {
    try {
      const response = await getAdminDahsboardData(batchId, programName);
      if (response.data.statusCode === 200) {
        if (programName === "Leadership Essentials Program") {
          setIndividualProgressTracker(
            response.data.data.individualProgressTracker
          );
          setSummeryData(response.data.data.summaryDashboard);
        } else {
          setIndividual100BmData(response.data.data.individualProgressTracker);
        }
      } else {
        setAlertDialogOpen(true);
      }
      setSelectedBatchOption(selected);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const formatDailyRitualsData = (data) => {
    const weeksNumber = [];
    const weekScore = [];
    for (const name in data) {
      if (Array.isArray(data[name])) {
        data[name].forEach((value) => {
          weeksNumber.push("Week " + value.weekNumber);
          weekScore.push(value.sum);
        });
      }
    }
    return {
      weeksNumber: weeksNumber,
      weekScore: weekScore,
    };
  };
  const formatLineData = (data) => {
    const weeksNumber = [];
    const weekScore = [];
    for (const name in data) {
      if (Array.isArray(data[name])) {
        data[name].forEach((value) => {
          weeksNumber.push("Week " + value.weekNumber);
          weekScore.push(value.weekScore);
        });
      }
    }
    return {
      weeksNumber: weeksNumber,
      weekScore: weekScore,
    };
  };

  const createLineDataNew = (data) => {
    const transformedData = {};

    data?.forEach((user) => {
      const name = user.fullName;
      if (!transformedData[name]) {
        transformedData[name] = [];
      }
      transformedData[name].push(user);
    });
    const result = Object.entries(transformedData).map(([key, value]) => ({
      [key]: value,
    }));

    setUserDataFIlter(
      result.map((item) => ({
        label: Object.keys(item)[0],
        value: Object.keys(item)[0],
      }))
    );
    return result;
  };

  const createDailyRitualsDataNew = (data) => {
    const transformedData = {};

    data?.forEach((user) => {
      const name = user.fullName;
      if (!transformedData[name]) {
        transformedData[name] = [];
      }
      const sum = [
        "faizen",
        "powerfulRequests",
        "playingGame",
        "graditude",
        "mirrorWork",
      ]
        .map((prop) => parseInt(user[prop] || 0)) // Convert to integers and handle missing values
        .reduce((sum, value) => sum + value, 0);

      transformedData[name].push({
        ...user,
        sum: sum.toString(), // Convert the sum to a string
      });
    });

    const result = Object.entries(transformedData).map(([key, value]) => ({
      [key]: value,
    }));
    setUserDataFIlter(
      result.map((item) => ({
        label: Object.keys(item)[0],
        value: Object.keys(item)[0],
      }))
    );

    return result;
  };

  const setLineGraphData = (individualProgressTracker) => {
    if (individualProgressTracker) {
      const dailyRituals = createDailyRitualsDataNew(
        individualProgressTracker?.dailyRituals
      );
      const BHAGProgress = createLineDataNew(
        individualProgressTracker?.BHAGProgress
      );
      const challengeParticipationDays = createLineDataNew(
        individualProgressTracker?.challengeParticipationDays
      );
      const hoursSavedThisWeekThroughERRC = createLineDataNew(
        individualProgressTracker?.hoursSavedThisWeekThroughERRC
      );
      const leadershipDashboard = createLineDataNew(
        individualProgressTracker?.leadershipDashboard
      );

      setAllUnFilteredLineData({
        dailyRituals: dailyRituals,
        BHAGProgress: BHAGProgress,
        challengeParticipationDays: challengeParticipationDays,
        hoursSavedThisWeekThroughERRC: hoursSavedThisWeekThroughERRC,
        leadershipDashboard: leadershipDashboard,
      });
      const graphData = {
        dailyRituals: formatDailyRitualsData(dailyRituals[0]),
        BHAGProgress: formatLineData(BHAGProgress[0]),
        challengeParticipationDays: formatLineData(
          challengeParticipationDays[0]
        ),
        hoursSavedThisWeekThroughERRC: formatLineData(
          hoursSavedThisWeekThroughERRC[0]
        ),
        leadershipDashboard: formatLineData(leadershipDashboard[0]),
      };
      setIndividualLineData(graphData);
    }
  };

  useEffect(() => {
    if (individualProgressTracker) {
      setLineGraphData(individualProgressTracker);
    }
  }, [individualProgressTracker]); // eslint-disable-line react-hooks/exhaustive-deps

  const createBarGraphData = (data) => {
    var result = {};
    data?.forEach((item) => {
      const weekNumber = item?.WeekNumber;
      const name = item?.Details?.userName;
      const score = parseInt(item?.Details?.bhagScore);
      if (!result[weekNumber]) {
        result[weekNumber] = { names: [], weeks: [], data: [] };
      }
      result[weekNumber].names.push(name);
      result[weekNumber].weeks.push(weekNumber);
      result[weekNumber].data.push(score);
    });
    return result;
  };

  const formatBarGraphData = (data) => {
    return {
      labels: data?.names,
      values: data?.data,
    };
  };

  const barGraph = (summeryData) => {
    const dailyRituals = createBarGraphData(summeryData?.dailyRituals);
    const BHAGProgress = createBarGraphData(summeryData?.BHAGProgress);
    const challengeParticipationDays = createBarGraphData(
      summeryData?.challengeParticipationDays
    );
    const hoursSavedThisWeekThroughERRC = createBarGraphData(
      summeryData?.hoursSavedThisWeekThroughERRC
    );
    const leadershipDashboard = createBarGraphData(
      summeryData?.leadershipDashboard
    );

    setSummeryFinalData({
      dailyRituals: formatBarGraphData(
        dailyRituals[Object.keys(dailyRituals)[0]]
      ),
      BHAGProgress: formatBarGraphData(
        BHAGProgress[Object.keys(BHAGProgress)[0]]
      ),
      challengeParticipationDays: formatBarGraphData(
        challengeParticipationDays[Object.keys(challengeParticipationDays)[0]]
      ),
      hoursSavedThisWeekThroughERRC: formatBarGraphData(
        hoursSavedThisWeekThroughERRC[
          Object.keys(hoursSavedThisWeekThroughERRC)[0]
        ]
      ),
      leadershipDashboard: formatBarGraphData(
        leadershipDashboard[Object.keys(leadershipDashboard)[0]]
      ),
    });
    setGraphFilter(Object.keys(dailyRituals));
  };

  const filterBarGraph = (val) => {
    const dailyRituals = createBarGraphData(summeryData?.dailyRituals);
    const BHAGProgress = createBarGraphData(summeryData?.BHAGProgress);
    const challengeParticipationDays = createBarGraphData(
      summeryData?.challengeParticipationDays
    );
    const hoursSavedThisWeekThroughERRC = createBarGraphData(
      summeryData?.hoursSavedThisWeekThroughERRC
    );
    const leadershipDashboard = createBarGraphData(
      summeryData?.leadershipDashboard
    );
    setSummeryFinalData({
      dailyRituals: formatBarGraphData(dailyRituals[val]),
      BHAGProgress: formatBarGraphData(BHAGProgress[val]),
      challengeParticipationDays: formatBarGraphData(
        challengeParticipationDays[val]
      ),
      hoursSavedThisWeekThroughERRC: formatBarGraphData(
        hoursSavedThisWeekThroughERRC[val]
      ),
      leadershipDashboard: formatBarGraphData(leadershipDashboard[val]),
    });
  };

  useEffect(() => {
    if (summeryData !== null || Object.keys(summeryData)?.length !== 0) {
      barGraph(summeryData);
    }
  }, [summeryData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(summeryFinalData)?.length !== 0) {
      setSummeryFinalData(summeryFinalData);
    }
  }, [summeryFinalData]);

  const createUserList100BMData = (data) => {
    let userNames = [];
    let months = [];
    let names = [];

    data?.forEach((monthData) => {
      monthData.data.forEach((item) => {
        if (!userNames.includes(item.userName)) {
          userNames.push(item.userName);
        }
      });
      const monthLabel = `Month ${monthData.monthNumber}`;
      if (!months.includes(monthLabel)) {
        months.push(monthLabel);
      }
    });
    userNames.forEach((name) => {
      names.push({
        label: name,
        value: name,
      });
    });
    setUserDataFIlter(names);
  };

  const formatBarGraph100BMData = (data) => {
    const flattenedWeekNo = data?.weekNo?.flat();
    const newLabel = flattenedWeekNo?.map((item) => `Week ${item}`);
    return {
      weeksNumber: newLabel,
      weekScore: data?.data?.flat(),
    };
  };

  const set100BMLineGraphData = (individual100BmData) => {
    if (individual100BmData) {
      createUserList100BMData(individual100BmData?.PerformedYourDailyRituals);
    }
  };

  const createLine100BMData = (data, user) => {
    const result = {};

    data?.forEach((item) => {
      const monthNumber = item?.monthNumber;
      const userData = item?.data?.filter((item) => item?.userName === user);

      if (userData.length > 0) {
        const weekNo = userData.map((item) => item?.weekNumber);
        const score = userData.map((item) => item?.weekScore);

        if (!result[monthNumber]) {
          result[monthNumber] = { weekNo: [], months: [], data: [] };
        }

        result[monthNumber].weekNo.push(weekNo);
        result[monthNumber].months.push(monthNumber);
        result[monthNumber].data.push(score);
      }
    });

    return result;
  };

  const handle100BMUserDropDown = (selected) => {
    setSelectedUserOption(selected);

    if (individual100BmData) {
      const dailyRituals = createLine100BMData(
        individual100BmData?.PerformedYourDailyRituals,
        selected.label
      );
      const BHAGProgress = createLine100BMData(
        individual100BmData?.BHAGProgress,
        selected.label
      );
      const challengeParticipationDays = createLine100BMData(
        individual100BmData?.challengeParticipationDays,
        selected.label
      );
      const hoursSavedThisWeekThroughERRC = createLine100BMData(
        individual100BmData?.hoursSavedThisWeekThroughERRC,
        selected.label
      );
      const leadershipDashboard = createLine100BMData(
        individual100BmData?.leadershipDashboard,
        selected.label
      );
      const expressRitual = createLine100BMData(
        individual100BmData?.ExpressRitual,
        selected.label
      );
      const onePerson = createLine100BMData(
        individual100BmData?.OnePerson,
        selected.label
      );

      const reachOutRitual = createLine100BMData(
        individual100BmData?.ReachOutRitual,
        selected.label
      );
      const reachedOutTopMgmt = createLine100BMData(
        individual100BmData?.ReachedOutTopMgmt,
        selected.label
      );
      const visualization = createLine100BMData(
        individual100BmData?.Visualization,
        selected.label
      );
      const ceosBoardMembersConnected = createLine100BMData(
        individual100BmData?.CeosBoardMembersConnected,
        selected.label
      );

      const graphData = {
        dailyRituals: formatBarGraph100BMData(
          dailyRituals[Object.keys(dailyRituals)[0]]
        ),
        BHAGProgress: formatBarGraph100BMData(
          BHAGProgress[Object.keys(BHAGProgress)[0]]
        ),
        challengeParticipationDays: formatBarGraph100BMData(
          challengeParticipationDays[Object.keys(challengeParticipationDays)[0]]
        ),
        hoursSavedThisWeekThroughERRC: formatBarGraph100BMData(
          hoursSavedThisWeekThroughERRC[
            Object.keys(hoursSavedThisWeekThroughERRC)[0]
          ]
        ),
        leadershipDashboard: formatBarGraph100BMData(
          leadershipDashboard[Object.keys(leadershipDashboard)[0]]
        ),
        expressRitual: formatBarGraph100BMData(
          expressRitual[Object.keys(expressRitual)[0]]
        ),
        onePerson: formatBarGraph100BMData(
          onePerson[Object.keys(onePerson)[0]]
        ),
        reachOutRitual: formatBarGraph100BMData(
          reachOutRitual[Object.keys(reachOutRitual)[0]]
        ),
        reachedOutTopMgmt: formatBarGraph100BMData(
          reachedOutTopMgmt[Object.keys(reachedOutTopMgmt)[0]]
        ),
        visualization: formatBarGraph100BMData(
          visualization[Object.keys(visualization)[0]]
        ),
        ceosBoardMembersConnected: formatBarGraph100BMData(
          ceosBoardMembersConnected[Object.keys(ceosBoardMembersConnected)[0]]
        ),
      };
      setIndividualLineData(graphData);
      const monthFilterdata = Object.keys(dailyRituals);
      setGraph100BMFilter(monthFilterdata?.map((val) => `Month ${val}`));
    }
  };

  const filter100BMGraph = (val) => {
    if (individual100BmData) {
      const dailyRituals = createLine100BMData(
        individual100BmData?.PerformedYourDailyRituals,
        selectedUserOption.label
      );
      const BHAGProgress = createLine100BMData(
        individual100BmData?.BHAGProgress,
        selectedUserOption.label
      );
      const challengeParticipationDays = createLine100BMData(
        individual100BmData?.challengeParticipationDays,
        selectedUserOption.label
      );
      const hoursSavedThisWeekThroughERRC = createLine100BMData(
        individual100BmData?.hoursSavedThisWeekThroughERRC,
        selectedUserOption.label
      );
      const leadershipDashboard = createLine100BMData(
        individual100BmData?.leadershipDashboard,
        selectedUserOption.label
      );
      const expressRitual = createLine100BMData(
        individual100BmData?.ExpressRitual,
        selectedUserOption.label
      );
      const onePerson = createLine100BMData(
        individual100BmData?.OnePerson,
        selectedUserOption.label
      );

      const reachOutRitual = createLine100BMData(
        individual100BmData?.ReachOutRitual,
        selectedUserOption.label
      );
      const reachedOutTopMgmt = createLine100BMData(
        individual100BmData?.ReachedOutTopMgmt,
        selectedUserOption.label
      );
      const visualization = createLine100BMData(
        individual100BmData?.Visualization,
        selectedUserOption.label
      );
      const ceosBoardMembersConnected = createLine100BMData(
        individual100BmData?.CeosBoardMembersConnected,
        selectedUserOption.label
      );
      const graphData = {
        dailyRituals: formatBarGraph100BMData(
          Object.values(dailyRituals).find(
            (item) => parseInt(item.months) === val
          )
        ),

        BHAGProgress: formatBarGraph100BMData(
          Object.values(BHAGProgress).find(
            (item) => parseInt(item.months) === val
          )
        ),
        challengeParticipationDays: formatBarGraph100BMData(
          Object.values(challengeParticipationDays).find(
            (item) => parseInt(item.months) === val
          )
        ),
        hoursSavedThisWeekThroughERRC: formatBarGraph100BMData(
          Object.values(hoursSavedThisWeekThroughERRC).find(
            (item) => parseInt(item.months) === val
          )
        ),
        leadershipDashboard: formatBarGraph100BMData(
          Object.values(leadershipDashboard).find(
            (item) => parseInt(item.months) === val
          )
        ),
        expressRitual: formatBarGraph100BMData(
          Object.values(expressRitual).find(
            (item) => parseInt(item.months) === val
          )
        ),
        onePerson: formatBarGraph100BMData(
          Object.values(onePerson).find((item) => parseInt(item.months) === val)
        ),
        reachOutRitual: formatBarGraph100BMData(
          Object.values(reachOutRitual).find(
            (item) => parseInt(item.months) === val
          )
        ),
        reachedOutTopMgmt: formatBarGraph100BMData(
          Object.values(reachedOutTopMgmt).find(
            (item) => parseInt(item.months) === val
          )
        ),
        visualization: formatBarGraph100BMData(
          Object.values(visualization).find(
            (item) => parseInt(item.months) === val
          )
        ),
        ceosBoardMembersConnected: formatBarGraph100BMData(
          Object.values(ceosBoardMembersConnected).find(
            (item) => parseInt(item.months) === val
          )
        ),
      };
      setIndividualLineData(graphData);
    }
  };

  const handleMonthFilterButtonClick = (val) => {
    filter100BMGraph(val);
    setFilter100BMClick(val);
  };

  useEffect(() => {
    if (graph100BMFilter.length > 0) {
      setFilter100BMClick(graph100BMFilter[0].match(/\d+/)[0], 10);
    }
  }, [graph100BMFilter]);

  useEffect(() => {
    if (individual100BmData) {
      set100BMLineGraphData(individual100BmData);
    }
  }, [individual100BmData]); // eslint-disable-line react-hooks/exhaustive-deps

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className={Styles["main-parent-div"]}>
        <div className={Styles["back-button-container"]}>
          <ButtonWithLinks
            id="gdashboardPageBack"
            name="Back"
            linkTo="action"
            callback={goBack}
            className={"button"}
          />
        </div>

        <div className={Styles["title"]}>Dashboards</div>
        <div className={Styles["dropdown-div"]}>
          <div className={Styles["program-dropdown"]}>
            <Select
              options={programOptions}
              placeholder="Select Program"
              value={selectedProgramOption}
              onChange={handleProgramDropdownChange}
              className={Styles["dropdown"]}
            />
          </div>

          <div className={Styles["batch-dropdown"]}>
            <Select
              options={batchOptions}
              placeholder="Select Batch"
              value={selectedBatchOption}
              onChange={handleBatchDropdownChange}
              className={Styles["dropdown"]}
            />
          </div>
        </div>

        {!alertDialogOpen && selectedBatchOption && (
          <>
            <div
              className={
                selectedProgramOption?.label !== "100 Board Members Program"
                  ? Styles["column-div"]
                  : Styles["column-div-single"]
              }
            >
              {selectedProgramOption?.label !== "100 Board Members Program" && (
                <div
                  className={
                    showLeftDiv
                      ? Styles["left-column-afterClick"]
                      : Styles["left-column"]
                  }
                  onClick={handleLeft}
                >
                  Summary
                </div>
              )}

              <div
                className={
                  showRightDiv
                    ? Styles["right-column-afterClick"]
                    : Styles["right-column"]
                }
                onClick={handleRight}
              >
                Individual Progress
              </div>
            </div>

            {showLeftDiv && (
              <>
                <div className={Styles["filter-div"]}>
                  {graphFilter.length > 0 &&
                    graphFilter?.map((item) => {
                      return (
                        <div className={Styles["filter-button"]}>
                          <button
                            onClick={() => handleFilterButtonClick(item)}
                            className={
                              filterClick === item
                                ? Styles["filter-button-click"]
                                : Styles["filter-button"]
                            }
                          >
                            {item}
                          </button>
                        </div>
                      );
                    })}
                </div>

                <div className={Styles["bar-div"]}>
                  <div className={Styles["bar-title"]}>Daily Rituals</div>
                  <BarChart
                    data={summeryFinalData?.dailyRituals}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigDailyRituals}
                  />

                  <div className={Styles["bar-title"]}>
                    Leadership Dashboard
                  </div>
                  <BarChart
                    data={summeryFinalData?.leadershipDashboard}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigLeadershipScore}
                  />

                  <div className={Styles["bar-title"]}>B-HAG Progress</div>
                  <BarChart
                    data={summeryFinalData?.BHAGProgress}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigPercentage}
                  />

                  <div className={Styles["bar-title"]}>
                    Challenge Participation
                  </div>
                  <BarChart
                    data={summeryFinalData?.challengeParticipationDays}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigWeeklyChallenge}
                  />

                  <div className={Styles["bar-title"]}>Hours Saved</div>
                  <BarChart
                    data={summeryFinalData?.hoursSavedThisWeekThroughERRC}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigHoursSaved}
                  />
                </div>
              </>
            )}

            {showRightDiv && (
              <>
                <div className={Styles["program-dropdown"]}>
                  <Select
                    options={userDataFilter}
                    placeholder="Select User"
                    value={selectedUserOption}
                    onChange={
                      selectedProgramOption?.label !==
                      "100 Board Members Program"
                        ? handleUserDropdownChange
                        : handle100BMUserDropDown
                    }
                    className={Styles["dropdown"]}
                  />
                </div>
                {selectedProgramOption?.label !==
                  "Leadership Essentials Program" && (
                  <div className={Styles["filter-div"]}>
                    {graph100BMFilter.length > 0 &&
                      graph100BMFilter?.map((item) => {
                        return (
                          <div className={Styles["filter-button"]}>
                            <button
                              onClick={() =>
                                handleMonthFilterButtonClick(
                                  parseInt(item.match(/\d+/)[0], 10)
                                )
                              }
                              className={
                                parseInt(filter100BMClick) ===
                                parseInt(item.match(/\d+/)[0])
                                  ? Styles["filter-button-click"]
                                  : Styles["filter-button"]
                              }
                            >
                              {item}
                            </button>
                          </div>
                        );
                      })}
                  </div>
                )}

                {selectedUserOption && (
                  <div className={Styles["line-div"]}>
                    {parseInt(individualLineData.dailyRituals?.weekScore[0]) !==
                      0 && (
                      <>
                        {" "}
                        <div className={Styles["line-title"]}>
                          Daily Rituals
                        </div>
                        <LineCharts
                          labels={individualLineData.dailyRituals?.weeksNumber}
                          values={individualLineData.dailyRituals?.weekScore}
                          yAxisConfig={
                            selectedProgramOption?.label !==
                            "100 Board Members Program"
                              ? yAxisConfigDailyRituals
                              : yAxisConfigWeeklyChallenge
                          }
                        />{" "}
                      </>
                    )}
                    {selectedProgramOption?.label !==
                      "100 Board Members Program" && (
                      <>
                        <div className={Styles["line-title"]}>
                          Leadership Dashboard
                        </div>
                        <LineCharts
                          labels={
                            individualLineData.leadershipDashboard.weeksNumber
                          }
                          values={
                            individualLineData.leadershipDashboard.weekScore
                          }
                          yAxisConfig={yAxisConfigLeadershipScore}
                        />{" "}
                      </>
                    )}

                    {selectedProgramOption?.label ===
                      "100 Board Members Program" && (
                      <>
                        {parseInt(
                          individualLineData.expressRitual?.weekScore[0]
                        ) !== 0 && (
                          <>
                            {" "}
                            <div className={Styles["line-title"]}>
                              Express Ritual
                            </div>
                            <LineCharts
                              labels={
                                individualLineData.expressRitual?.weeksNumber
                              }
                              values={
                                individualLineData.expressRitual?.weekScore
                              }
                              yAxisConfig={yAxisConfigWeeklyChallenge}
                            />{" "}
                          </>
                        )}

                        {parseInt(
                          individualLineData.onePerson?.weekScore[0]
                        ) !== 0 && (
                          <>
                            {" "}
                            <div className={Styles["line-title"]}>
                              Reach Out At Least One Person
                            </div>
                            <LineCharts
                              labels={individualLineData.onePerson?.weeksNumber}
                              values={individualLineData.onePerson?.weekScore}
                              yAxisConfig={yAxisConfigWeeklyChallenge}
                            />{" "}
                          </>
                        )}

                        {parseInt(
                          individualLineData.reachOutRitual?.weekScore[0]
                        ) !== 0 && (
                          <>
                            {" "}
                            <div className={Styles["line-title"]}>
                              Reach out Ritual
                            </div>
                            <LineCharts
                              labels={
                                individualLineData.reachOutRitual?.weeksNumber
                              }
                              values={
                                individualLineData.reachOutRitual?.weekScore
                              }
                              yAxisConfig={yAxisConfigWeeklyChallenge}
                            />{" "}
                          </>
                        )}

                        {parseInt(
                          individualLineData.reachedOutTopMgmt?.weekScore[0]
                        ) !== 0 && (
                          <>
                            {" "}
                            <div className={Styles["line-title"]}>
                              Reached Out To Any CXO/BM/Top management people
                            </div>
                            <LineCharts
                              labels={
                                individualLineData.reachedOutTopMgmt
                                  ?.weeksNumber
                              }
                              values={
                                individualLineData.reachedOutTopMgmt?.weekScore
                              }
                              yAxisConfig={yAxisConfigWeeklyChallenge}
                            />{" "}
                          </>
                        )}

                        {parseInt(
                          individualLineData.visualization?.weekScore[0]
                        ) !== 0 && (
                          <>
                            {" "}
                            <div className={Styles["line-title"]}>
                              Practice Visualization
                            </div>
                            <LineCharts
                              labels={
                                individualLineData.visualization?.weeksNumber
                              }
                              values={
                                individualLineData.visualization?.weekScore
                              }
                              yAxisConfig={yAxisConfigWeeklyChallenge}
                            />{" "}
                          </>
                        )}

                        {individualLineData.ceosBoardMembersConnected
                          ?.weekScore &&
                          parseInt(
                            individualLineData.ceosBoardMembersConnected
                              ?.weekScore[0]
                          ) !== 0 && (
                            <>
                              {" "}
                              <div className={Styles["line-title"]}>
                                CEOs or Board Members could you connect in
                                Linked In
                              </div>
                              <LineCharts
                                labels={
                                  individualLineData.ceosBoardMembersConnected
                                    ?.weeksNumber
                                }
                                values={
                                  individualLineData.ceosBoardMembersConnected
                                    ?.weekScore
                                }
                                yAxisConfig={yAxisConfigWeeklyChallenge}
                              />{" "}
                            </>
                          )}
                      </>
                    )}

                    <div className={Styles["line-title"]}>B-HAG Progress</div>
                    <LineCharts
                      labels={individualLineData.BHAGProgress.weeksNumber}
                      values={individualLineData.BHAGProgress.weekScore}
                      yAxisConfig={yAxisConfigPercentage}
                    />

                    <div className={Styles["line-title"]}>
                      Challenge Participation
                    </div>
                    <LineCharts
                      labels={
                        individualLineData.challengeParticipationDays
                          .weeksNumber
                      }
                      values={
                        individualLineData.challengeParticipationDays.weekScore
                      }
                      yAxisConfig={yAxisConfigWeeklyChallenge}
                    />

                    <div className={Styles["line-title"]}>Hours Saved</div>
                    <LineCharts
                      labels={
                        individualLineData.hoursSavedThisWeekThroughERRC
                          .weeksNumber
                      }
                      values={
                        individualLineData.hoursSavedThisWeekThroughERRC
                          .weekScore
                      }
                      yAxisConfig={yAxisConfigHoursSaved}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {alertDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={alertDialogOpen}
            onClose={handleCloseAlert}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Progress Not Found"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={handleCloseAlert}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {detailNotFound && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={detailNotFound}
            onClose={handleCloseDetails}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Program and batch details not found"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={handleCloseDetails}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {noBachesPopUpOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={noBachesPopUpOpen}
            onClose={handleCloseBatchesPopUp}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Batches not found for this program"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={handleCloseBatchesPopUp}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}
    </>
  );
}
