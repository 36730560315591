// import Styles from "./NewsletterLH.module.scss";
// import { useNavigate, useParams } from "react-router-dom";
// import { useState, useEffect, useRef } from "react";
// import { ThreeCircles } from "react-loader-spinner";
// import ButtonWithLink from "../buttons/ButtonWithLink";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import { urls } from "../../utils/urls";
// import { getAllNewsletterData, getNewsletterCount } from "../../service/communityService.js";

// export default function NewsletterLH() {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [isLoadingMore, setIsLoadingMore] = useState(false);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(0);
//     const [isAtBeginning, setIsAtBeginning] = useState(true);
//     const [isAtEnd, setIsAtEnd] = useState(false);
//     const [newsletterImages, setNewsletterImages] = useState([]);
//     const [selectedMonth, setSelectedMonth] = useState('');
//     const [selectedYear, setSelectedYear] = useState('');
//     const { month: paramMonth, year: paramYear } = useParams();

//     let navigate = useNavigate();
//     const swiperRef = useRef(null);
//     const BASE_URL = "https://api.iamironlady.com/api";
//     // const BASE_URL = "http://localhost:3001/api";

//     const [screen, setScreenWidth] = useState(window.innerWidth);
//     const handleResize = () => {
//         setScreenWidth(window.innerWidth);
//     };
//     useEffect(() => {
//         window.addEventListener("resize", handleResize);
//         return () => {
//             window.removeEventListener("resize", handleResize);
//         };
//     }, []);
//     useEffect(() => {
//         setScreenWidth(screen);
//     }, [screen]);

//     const slidesPerView = Math.min(3, data.length);
//     const swiperOptions = {
//         slidesPerView: 1,
//         // spaceBetween: 10,
//         navigation: {
//             nextEl: ".my-swiper-button-next",
//             prevEl: ".my-swiper-button-prev",
//         },
//         breakpoints: {
//             768: {
//                 slidesPerView: slidesPerView,
//             },
//         },
//     };

//     useEffect(() => {
//         fetchData(currentPage);
//     }, [currentPage]);

//     const fetchData = async (page) => {
//         try {
//             if (page === 1) {
//                 setLoading(true);
//             } else {
//                 setIsLoadingMore(true);
//             }
//             const summary = await getNewsletterCount();
//             console.log(summary)
//             setTotalPages(summary.totalPage);
//             const result = await getAllNewsletterData();
//             console.log(result)
//             setData((prevData) => [...prevData, ...result]);
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         } finally {
//             if (page === 1) {
//                 setLoading(false);
//             } else {
//                 setIsLoadingMore(false);
//             }
//         }
//     };

//     const fetchNewsletterImages = async (month, year) => {
//         try {
//             const response = await fetch(`${BASE_URL}/newsletter-details?month=${month}&year=${year}`);
//             const data = await response.json();
//             console.log(data.data)
//             setNewsletterImages(data.data);
//         } catch (error) {
//             console.error("Error fetching images:", error);
//         }
//     };

//     const handleMonthClick = (month, year) => {
//         setSelectedMonth(month);
//         setSelectedYear(year);
//         fetchNewsletterImages(month, year);
//         navigate(`/newsletter/${year}/${month}`);
//     };

//     const handleLoadMore = () => {
//         setIsAtEnd(false);
//         if (currentPage < totalPages) {
//             setCurrentPage((prevPage) => prevPage + 1);
//         }
//     };

//     const handlePrevClick = () => {
//         if (swiperRef.current && swiperRef.current.swiper) {
//             swiperRef.current.swiper.slidePrev();
//             swiperRef.current.swiper.emit('slideChange');
//             setIsAtEnd(false);
//             if (screen > 768) {
//                 if (data.length <= 3) {
//                     setIsAtEnd(true);
//                 } else {
//                     setIsAtEnd(false);
//                 }
//             } else {
//                 if (data.length <= 1) {
//                     setIsAtEnd(true);
//                 } else {
//                     setIsAtEnd(false);
//                 }
//             }
//         }
//     }; 

//     const handleNextClick = () => {
//         if (swiperRef.current && swiperRef.current.swiper) {
//             swiperRef.current.swiper.slideNext();
//             swiperRef.current.swiper.emit('slideChange');
//             setIsAtBeginning(false);
//             if (screen > 768) {
//                 if (data.length <= 3) {
//                     setIsAtBeginning(true);
//                 } else {
//                     setIsAtBeginning(false);
//                 }
//             } else {
//                 if (data.length <= 1) {
//                     setIsAtBeginning(true);
//                 } else {
//                     setIsAtBeginning(false);
//                 }
//             }
//         }
//     };    

//     return (
//         <div className={Styles["main-parent-div"]}>
//             {data.length > 0 && (
//                 <span className={Styles["main-title"]}>Iron Lady Speaks - Monthly News letter</span>
//             )}
//             {data.length > 0 && (
//                 <div className={Styles["bullatin-main-div"]}>
//                     {loading ? (
//                         <ThreeCircles
//                             height="70"
//                             width="70"
//                             color="#F52929"
//                             wrapperStyle={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                                 width: "100%",
//                                 height: "20vh",
//                             }}
//                             wrapperClass=""
//                             visible={true}
//                             ariaLabel="three-circles-rotating"
//                         />
//                     ) : (
//                         <>
//                             {data.length !== 0 ? (
//                                 <>
//                                     <img
//                                         alt=""
//                                         src={urls.prevArrow}
//                                         className={
//                                             isAtBeginning ? Styles["disabled"] : Styles["prev"]
//                                         }
//                                         onClick={handlePrevClick}
//                                     ></img>

//                                     <Swiper
//                                     ref={swiperRef}
//                                         {...swiperOptions}
//                                         className="my-swiper-container-comp1"
//                                         onReachBeginning={() => {
//                                             setIsAtBeginning(true);
//                                         }}
//                                         onReachEnd={() => {
//                                             setIsAtEnd(true);
//                                         }}
//                                     >
//                                         {data.map((item) => {
//                                             return (
//                                                 <SwiperSlide key={item.id}>
//                                                     <div
//                                                         key={item.id}
//                                                         className={Styles["bullatin-card"]}
//                                                         onClick={() => handleMonthClick(item.month, item.year)}
//                                                     >
//                                                         <div className={Styles["bullatin-top"]}>
//                                                             <img
//                                                                 className={Styles["bullatin-img"]}
//                                                                 src={item.bannerImage}
//                                                                 alt=""
//                                                             ></img>
//                                                         </div>
//                                                         <div className={Styles["bullatin-bottom"]}>
//                                                             <span className={Styles["bullatin-date"]}>
//                                                                 {`Newsletter - ${item.month} ${item.year}`}
//                                                             </span>
//                                                             <div
//                                                                 className={Styles["know-more-div"]}
//                                                                 onClick={() => {

//                                                                 }}
//                                                             >
//                                                                 <span className={Styles["know-more"]}>
//                                                                     Know More
//                                                                 </span>
//                                                                 <img
//                                                                     alt=""
//                                                                     src={urls.rightUpArrow}
//                                                                     className={Styles["right-arrow"]}
//                                                                 ></img>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </SwiperSlide>
//                                             );
//                                         })}

//                                         {currentPage < totalPages && (
//                                             <SwiperSlide>
//                                                 <div className={Styles["load-more-div"]}>
//                                                     <ButtonWithLink
//                                                         linkTo="action"
//                                                         callback={handleLoadMore}
//                                                         name={isLoadingMore ? "Loading..." : "Load More"}
//                                                         className={"button"}
//                                                         disabled={isLoadingMore}
//                                                     ></ButtonWithLink>
//                                                 </div>
//                                             </SwiperSlide>
//                                         )}
//                                     </Swiper>

//                                     <img
//                                         alt=""
//                                         src={urls.nextArrow}
//                                         className={isAtEnd ? Styles["disabled"] : Styles["next"]}
//                                         onClick={handleNextClick}
//                                     ></img>
//                                 </>
//                             ) : (
//                                 <div
//                                     data-aos="fade-up"
//                                     data-aos-duration="1000"
//                                     data-aos-easing="ease-in-out"
//                                     className={Styles["no-data"]}
//                                 >
//                                     <img
//                                         alt=""
//                                         src={urls.noData}
//                                         className={Styles["no-data-image"]}
//                                     ></img>
//                                 </div>
//                             )}
//                         </>
//                     )}
//                 </div>
//             )}
//         </div>
//     );
// }


import Styles from "./NewsletterLH.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { urls } from "../../utils/urls";
import { getAllNewsletterFromCMS } from "../../service/communityService.js";

export default function NewsletterLH() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newsletterImages, setNewsletterImages] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const { month: paramMonth, year: paramYear } = useParams();
    const [isAtBeginning, setIsAtBeginning] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    let navigate = useNavigate();
    const swiperRef = useRef(null);

    const [screen, setScreenWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const slidesPerView = Math.min(3, data.length);
    const swiperOptions = {
        slidesPerView: 1,
        navigation: {
            nextEl: ".my-swiper-button-next",
            prevEl: ".my-swiper-button-prev",
        },
        breakpoints: {
            768: {
                slidesPerView: slidesPerView,
            },
        },
    };

    useEffect(() => {
        const fetchNewsletterDetails = async () => {
            try {
                const response = await getAllNewsletterFromCMS();
                if (response.statusCode === 200 && Array.isArray(response.data)) {
                    const newsletters = response.data.map(item => {
                        try {
                            return {
                                id: item.id,
                                name: item.name,
                                month: item.name.split(" ")[0],
                                year: item.name.split(" ")[1],
                                description: JSON.parse(item.description),
                            };
                        } catch (error) {
                            console.error("Error parsing description:", error);
                            return {
                                id: item.id,
                                name: item.name,
                                description: null,
                            };
                        }
                    }).sort((a, b) => b.id - a.id);
    
                    setData(newsletters);
    
                    const initialMonth = paramMonth || newsletters[0]?.month;
                    const initialYear = paramYear || newsletters[0]?.year;
    
                    setSelectedMonth(initialMonth);
                    setSelectedYear(initialYear);
    
                    const filteredImages = newsletters.filter(item => {
                        return item.month === initialMonth && item.year === initialYear;
                    });
    
                    if (filteredImages.length > 0) {
                        setNewsletterImages(filteredImages[0].description.images);
                    }
                }
            } catch (error) {
                console.error("Error fetching newsletters:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchNewsletterDetails();
    }, [paramMonth, paramYear]);    

    const handleMonthClick = (month, year) => {
        setSelectedMonth(month);
        setSelectedYear(year);
        navigate(`/newsletter/${year}/${month}`);
    };

    const handlePrevClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            swiperRef.current.swiper.emit('slideChange');
            setIsAtEnd(false);
            if (screen > 768) {
                if (data.length <= 3) {
                    setIsAtEnd(true);
                } else {
                    setIsAtEnd(false);
                }
            } else {
                if (data.length <= 1) {
                    setIsAtEnd(true);
                } else {
                    setIsAtEnd(false);
                }
            }
        }
    }; 

    const handleNextClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            swiperRef.current.swiper.emit('slideChange');
            setIsAtBeginning(false);
            if (screen > 768) {
                if (data.length <= 3) {
                    setIsAtBeginning(true);
                } else {
                    setIsAtBeginning(false);
                }
            } else {
                if (data.length <= 1) {
                    setIsAtBeginning(true);
                } else {
                    setIsAtBeginning(false);
                }
            }
        }
    };    

    return (
        <div className={Styles["main-parent-div"]}>
            {data.length > 0 && (
                <span className={Styles["main-title"]}>Iron Lady Speaks - Monthly Newsletter</span>
            )}
            {data.length > 0 && (
                <div className={Styles["bullatin-main-div"]}>
                    {loading ? (
                        <ThreeCircles
                            height="70"
                            width="70"
                            color="#F52929"
                            wrapperStyle={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "20vh",
                            }}
                            visible={true}
                        />
                    ) : (
                        <>
                            {data.length !== 0 ? (
                                <>
                                    <img
                                        alt=""
                                        src={urls.prevArrow}
                                        className={Styles["prev"]}
                                        onClick={handlePrevClick}
                                    />

                                    <Swiper
                                        ref={swiperRef}
                                        {...swiperOptions}
                                        onReachBeginning={() => {
                                            setIsAtBeginning(true);
                                        }}
                                        onReachEnd={() => {
                                            setIsAtEnd(true);
                                        }}
                                        className="my-swiper-container-comp1"
                                    >
                                        {data.map((item) => (
                                            <SwiperSlide key={item.id}>
                                                <div
                                                    key={item.id}
                                                    className={Styles["bullatin-card"]}
                                                    onClick={() => handleMonthClick(item.month, item.year)}
                                                >
                                                    <div className={Styles["bullatin-top"]}>
                                                        <img
                                                            className={Styles["bullatin-img"]}
                                                            src={item.description.bannerImage} 
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className={Styles["bullatin-bottom"]}>
                                                        <span className={Styles["bullatin-date"]}>
                                                            {`Newsletter - ${item.month} ${item.year}`}
                                                        </span>
                                                        <div className={Styles["know-more-div"]}>
                                                            <span className={Styles["know-more"]}>Know More</span>
                                                            <img
                                                                alt=""
                                                                src={urls.rightUpArrow}
                                                                className={Styles["right-arrow"]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>

                                    <img
                                        alt=""
                                        src={urls.nextArrow}
                                        className={Styles["next"]}
                                        onClick={handleNextClick}
                                    />
                                </>
                            ) : (
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    data-aos-easing="ease-in-out"
                                    className={Styles["no-data"]}
                                >
                                    <img
                                        alt=""
                                        src={urls.noData}
                                        className={Styles["no-data-image"]}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}