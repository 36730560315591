import axios from "axios";
import { urls } from "../utils/urls";

export const registerProgram = async (payload) => {
  try {
    const response = await axios.post(
      urls.programEnroll,payload
    );
    return response.data;
    
  } catch (error) {
    throw error;
  }
};

export const validateCoupon = async (payload) => {
  try {
    const response = await axios.post(
      urls.validateCoupon,payload
    );
    return response.data;
    
  } catch (error) {
    console.log(error, 'error')
    throw error;
  }
};