// import ProgramCutators from "../../components/program_curtators/ProgramCurtators";
// import AboutUs from "../../components/about-sec-one/AboutUs";
// import OurStoryNew from "../../components/our-story-new/OurStoryNew";
// import WhatDiffNew from "../../components/what-diff-new/WhatDiff";
// import WhatDriveUs from "../../components/what-drive-us/WhatDriveUs";
// import { appLevelConstants } from "../../constants/AppLevelConstants";

// export default function About() {

//   return (
//     <>
//       <AboutUs />
//       <WhatDriveUs />
//       <WhatDiffNew
//         data={appLevelConstants.aboutUs.whatDifference}
//         title="What Differentiates Iron Lady"
//         buttonId='WhatDiff_AboutUs'
//       />
//       <OurStoryNew />
//       <ProgramCutators />
//     </>
//   );
// }

import ProgramCutators from "../../components/program_curtators/ProgramCurtators";
import AboutUs from "../../components/about-sec-one/AboutUs";
import OurStoryNew from "../../components/our-story-new/OurStoryNew";
import WhatDiffNew from "../../components/what-diff-new/WhatDiff";
import WhatDriveUs from "../../components/what-drive-us/WhatDriveUs";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

export default function About() {
  const location = useLocation();
  const programCurtatorsRef = useRef(null);

  useEffect(() => {
    if (location.state?.scrollTo === 'programCurtators') {
      const timer = setTimeout(() => {
        programCurtatorsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [location]);
  return (
    <>
      <Helmet>
        <title>Iron Lady Vision and Mission</title>
        <meta name="description" content="Learn about Iron Lady, the top leadership program for women, dedicated to empowering ambitious women to break barriers and excel in leadership roles. Discover our mission, vision, and commitment to creating impactful leaders who drive change." />
        <meta name="keywords" content="About Iron Lady, Iron Lady Rajesh Bhat, Rajesh Bhat" />
      </Helmet>

      <AboutUs />
      <WhatDriveUs />
      <WhatDiffNew
        data={appLevelConstants.aboutUs.whatDifference}
        title="What Differentiates Iron Lady"
        buttonId='WhatDiff_AboutUs'
      />
      <OurStoryNew />
      <ProgramCutators ref={programCurtatorsRef}
        scrollToId={location.state?.itemId} />
    </>
  );
}

