import axios from "axios";

export const fetchStoriesData = async (pageNumber) => {
  try {
    const response = await axios.get(
      "https://api.iamironlady.com/api/success-stories?page="+pageNumber
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchInspirationData = async (pageNumber) => {
  try {
    const response = await axios.get(
      "https://api.iamironlady.com/api/success-stories-inspiration?page="+pageNumber
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getCatgoryData = async (params) => {
  try {
    const response = await axios.get(
      `https://api.iamironlady.com/api/success-story-by-filter`,{params}
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getCounts = async () => {
  try {
    const response = await axios.get(
      "https://api.iamironlady.com/api/get-all-success-stories-summary-details"
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getIndividualAuthor = async (storyId) => {
  try {
    const response = await axios.get(
      "https://api.iamironlady.com/api/success-story-by-id?storyId=" +
        storyId
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
