import ButtonWithLinks from "../buttons/ButtonWithLink";
import Styles from "./WisdomInsightDetailPage.module.scss";
import { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { extractYouTubeVideoId } from "../../helper-fuction/helper";
// import VideoView from "../video-view/video-view";
import {
  getWisdomData,
  getWisdomSummary,
} from "../../service/communityService";

export default function WisdomInsightDetailPage() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredItem, setFilteredItem] = useState({});
  const [summary, setSummary] = useState({});
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [popupVideoUrl, setPopupVideoUrl] = useState("");

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const result = await getWisdomData(page);
      console.log(result)
      const summary = await getWisdomSummary();
      setSummary(summary);
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && data.length > 0) {
      const filtered = data.find((item) => item.id === parseInt(id));
      console.log(filtered)
      setFilteredItem(filtered || null);
    }
  }, [id, data]);

  useEffect(() => {
    if (id && !filteredItem && currentPage < summary.totalPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [id, filteredItem, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* <VideoView
        setDialogOpen={setDialogOpen}
        open={dialogOpen}
        url={popupVideoUrl}
      ></VideoView> */}

      <div className={Styles["main-parent-div"]}>
        <div className={Styles["back-button-container"]}>
          <ButtonWithLinks
            id="wisdomInsightDetailPageBack"
            name="Back"
            linkTo="/latest"
            className={"button"}
          />
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3vw",
            }}
          >
            <ThreeCircles
              height="100"
              width="100"
              color="#F52929"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <>
            <div id={filteredItem?.id} className={Styles["main-section"]}>
              <div className={Styles["imgVideo-container"]}>
                {filteredItem?.knw_video_url_S3 ? (
                  <video
                    className={Styles["imgVideo"]}
                    src={
                      "https://d1ixtxt2uzttul.cloudfront.net/videos/impact_video.mp4"
                    }
                    controls
                    autoPlay
                    muted
                  ></video>
                ) : filteredItem?.knw_video_url ? (
                  <>
                    <iframe
                      className={Styles["imgVideo"]}
                      title="youtube Video Player"
                      src={
                        "https://www.youtube.com/embed/" +
                        extractYouTubeVideoId(filteredItem?.knw_video_url)
                      }
                      id="fitvid725928"
                      allowFullScreen
                    ></iframe>
                  </>
                ) : (
                  <img
                  alt=""
                    src={filteredItem?.knw_img_url}
                    className={Styles["img"]}
                  ></img>
                )}
              </div>

              <div className={Styles["description-container"]}>
                <span className={Styles["title"]}>
                  {filteredItem?.knw_title}
                </span>

                {filteredItem &&
                filteredItem?.knw_description?.startsWith("<s") ? (
                  <>
                    <div
                      className={Styles["description"]}
                      dangerouslySetInnerHTML={{
                        __html: filteredItem?.knw_description,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span className={Styles["description"]}>
                      {filteredItem?.knw_description}
                    </span>
                  </>
                )}

                {/* <span className={Styles["description"]}>
                  {filteredItem?.knw_description}
                </span> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
