import React from "react";
import Styles from "./TrustedWomens.module.scss";
import { useEffect, useState } from "react";
import AOS from "aos";
import { appLevelConstants } from "../../constants/AppLevelConstants";

export default function TrustedWomens() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["main-div"]}>
        <span
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["title"]}
        >
          {appLevelConstants.trustedWoman.trustedWomanTitle}
        </span>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["content-div"]}
        >
          {appLevelConstants.trustedWoman.trustedWoman.map((item,index) => {
            return (
              <div key={item.id} className={Styles["card-div"]}>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["img-div"]}
                >
                  <img alt="" src={item.img} className={Styles["img"]}></img>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["description"]}
                >
                  {screenWidth >= 768 ? item.description : item.mobileDescription} 
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
