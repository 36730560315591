import React from 'react';
import Styles from './Partnership.module.scss';
import { FaAward, FaHandshake, FaLightbulb, FaUsers, FaRegHandshake, FaChartLine, FaNetworkWired } from 'react-icons/fa';
import { urls } from '../../utils/urls';
import { useNavigate } from 'react-router-dom';

const Partnership = () => {
    const navigate = useNavigate();

    const handleCardClick = (link) => {
        if (link.startsWith('http://') || link.startsWith('https://')) {
            window.open(link, '_blank');
        } else {
            navigate(link);
        }
    }
    const serviceList = [
        {
            id: 1,
            icon: <FaAward className={Styles["card-icon"]} />,
            title: "Enhanced Leadership Development",
            description: "Expand opportunities for your employees and stakeholders to build essential leadership skills."
        },
        {
            id: 2,
            icon: <FaHandshake className={Styles["card-icon"]} />,
            title: "Brand Strengthening",
            description: "Align with a trusted and recognized name in women’s leadership, elevating your brand's social impact."
        },
        {
            id: 3,
            icon: <FaLightbulb className={Styles["card-icon"]} />,
            title: "Revenue-Generating Initiatives",
            description: "Access potential revenue-sharing models through collaborative programs."
        },
        {
            id: 4,
            icon: <FaUsers className={Styles["card-icon"]} />,
            title: "Exclusive Program Access",
            description: "Engage with our renowned leadership workshops, programs, and exclusive masterclasses."
        },
        {
            id: 5,
            icon: <FaRegHandshake className={Styles["card-icon"]} />,
            title: "Ongoing Training and Support",
            description: "Receive continuous guidance for your leaders to ensure impactful results."
        },
        {
            id: 6,
            icon: <FaChartLine className={Styles["card-icon"]} />,
            title: "Leadership Insights & Trends",
            description: "Leverage our research-driven insights into the latest in leadership practices and trends."
        },
        {
            id: 7,
            icon: <FaNetworkWired className={Styles["card-icon"]} />,
            title: "Expert Network Access",
            description: "Connect with Iron Lady’s community of expert coaches, mentors, and industry leaders."
        }
    ];

    const offeringsList = [
        {
            id: 1,
            link: 'https://workshops.iamironlady.com/masterclass_app',
            image: 'https://d1ixtxt2uzttul.cloudfront.net/images/100_board_member.png',
            title: 'Master Class',
            description: 'For every woman who feels underpaid and overlooked, this is your moment. Learn industry-proven strategies to double your salary, smash through glass ceilings, and claim your space at the top.'
        },
        {
            id: 2,
            link: '/individualPrograms/Leadership_Essentials_Program',
            image: 'https://d1ixtxt2uzttul.cloudfront.net/images/100_board_member.png',
            title: 'Leadership Essentials Program (LEP)',
            description: 'A foundational program designed to equip women with essential leadership skills, strategies for navigating workplace dynamics, and tools to amplify their impact in professional environments.'
        },
        {
            id: 3,
            link: '/individualPrograms/100_Board_Members_Program',
            image: 'https://d1ixtxt2uzttul.cloudfront.net/images/100_board_member.png',
            title: '100 Board Members Program',
            description: 'A flagship initiative aimed at preparing women to take on board-level roles, this program combines mentorship, skill-building, and industry connections to create future-ready boardroom leaders.'
        },
        {
            id: 4,
            link: '/individualPrograms/Master_of_Business_Warfare',
            image: 'https://d1ixtxt2uzttul.cloudfront.net/images/100_board_member.png',
            title: 'Master of Business Warfare (MBW)',
            description: 'A transformative program that teaches advanced business tactics, high-level negotiation, and leadership strategies to help women thrive in complex, competitive environments.'
        }
    ];

    return (
        <div className={Styles["partnership"]}>
            <img className={Styles["banner"]} src={urls.partnership_banner} alt='scholarship banner' />

            <h1 className={Styles["headline"]}>Partnership Opportunities with Iron Lady</h1>
            <h2 className={Styles["subheadline"]}>Empowering Leadership, Together</h2>

            <div className={Styles["description-container"]}>

                <p className={Styles["description"]}>Join forces with Iron Lady to create impactful leadership solutions.</p>
                <p className={Styles["description"]}>Explore our tailored partnership models that offer mutual benefits, including access to premier leadership programs, brand alignment with a leading name in women's empowerment, and opportunities for growth.</p>

            </div>

            <div className={Styles["service-container"]}>
                <div className={Styles["service-description-container"]}>
                    <h1 className={Styles["service-heading"]}>
                        What Iron Lady Offers Through Partnership
                    </h1>

                    <p className={Styles["service-description"]}>
                        At Iron Lady, we believe growth thrives on collaboration. Partner with us to empower women in leadership, elevate your brand, and drive lasting impact through high-quality leadership development.
                    </p>
                </div>

                <div className={Styles["card-container"]}>
                    {
                        serviceList.map((item) => (
                            <div className={Styles["card"]}>
                                {item.icon}
                                <p className={Styles["card-title"]}>{item.title}</p>
                                <p className={Styles['card-description']}>{item.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={Styles["offerings-container"]}>
                <div className={Styles["offerings-description-container"]}>
                    <h1 className={Styles["offerings-heading"]}>
                    Our Offerings: Empowering Women, Elevating Leadership
                    </h1>
                </div>

                <div className={Styles["card-container"]}>
                    {
                        offeringsList.map((item) => (
                            <div onClick={() => handleCardClick(item.link)} className={Styles["card"]}>
                                <img className={Styles["card-image"]} src={item.image} alt={`${item.title} image`} />
                                <p className={Styles["card-title"]}>{item.title}</p>
                                <p className={Styles['card-description']}>{item.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={Styles["partnership-model"]}>
                <div className={Styles["partnership-image"]}>
                    <img src={urls.partnership_model} alt='scholarship banner' />
                </div>
                <div className={Styles["partnership-content"]}>
                    <h1>Partnership Models</h1>
                    <p>
                        We offer flexible partnership models suited to your unique organizational needs, whether you’re a corporate entity, educational institution, or non-profit. We co-invest in initiatives designed to develop women leaders within your workforceand community, with options to adjust scope, approach, and focus according to your industry and objectives.
                    </p>
                </div>
            </div>

            <div className={Styles['impact-container']}>
                <h2 className={Styles["impact-subheadline"]}>Let's Make an Impact, Together.</h2>
                <p>
                    Partnering with Iron Lady means more than growth — it's about making a lasting difference in women's leadership. Be part of this journey to empower and elevate future leaders.
                </p>
                <h2 className={Styles["impact-subheading"]}>For More Information, Please Contact:</h2>
                <div className={Styles["impact-contact"]}>
                    <h2 className={Styles["impact-contact-name"]}>
                        Rahul P R
                    </h2>
                    <p>General Manager - Marketing & Growth</p>
                    <p>Email: <a href="mailto:rahul@iamironlady.com?subject=Enquiry for Partnership">
                        rahul@iamironlady.com
                    </a></p>
                </div>
            </div>
        </div>
    )
}

export default Partnership