import axios from "axios";
import { urls } from "../utils/urls";

const BASE_URL = "https://api.iamironlady.com/api";
// const BASE_URL = "http://localhost:3001/api";

// export const getAllBannerData = async () => {
//   try {
//     let config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: `${BASE_URL}/getAllBanner`,
//       headers: {},
//     };
//     const response = await axios.request(config);
//     return JSON.stringify(response.data.data);
//   } catch (error) {
//     console.log({ error }, "error");
//     throw error;
//   }
// };

export const getAllPostsData = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/getAllPost`,
      headers: {},
    };
    const response = await axios.request(config);
    return JSON.stringify(response.data.data);
  } catch (error) {
    throw error;
  }
};

export const getLatestHappeningData = async (pageNumber) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/latest-happening?page=` + pageNumber
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getLatestHappeningSummary = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/get-all-latest-happening-summary-details`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getWisdomData = async (pageNumber) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/knowledge-wisdom?page=` + pageNumber
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getWisdomSummary = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/get-all-knowledge-wisdom-summary-details`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getFeedById = async (id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getPostById/${id}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getFeedDetailsById = async (id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getPostDetailsById/${id}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getFeedDetailsByName = async (name) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getPostDetailsByName/${name}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const updateUser = async (userId) => {
  try {
    const response = await axios.post(
      urls.updateUser + "?UserId=" + userId,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNewsletterCount = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getNewsletterCount`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getAllNewsletterData = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getAllNewsletterData`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getAllBlogKeywordDetails = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getAllBlogKeywords`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getAllNewsletterFromCMS = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/getAllNewsletters`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// export const getAllCommunity = async () => {
//   try {
//     const response = await axios.post(urls.getAllCommunity, {});
//     return response;
//   } catch (error) {
//     console.log(error, "error---");
//     throw error;
//   }
// };



// export const getAllCommunityMessages = async (communityId, page, limit) => {
//   try {
//     const response = await axios.post(
//       `${urls.getAllCommunityMessages}?page=${page}&limit=${limit}`,
//       {
//         communityId: communityId,
//       }
//     );
//     return response;
//   } catch (error) {
//     console.log(error, "error--- from get All Community msgs");
//     throw error;
//   }
// };

export const getCommunityUsers = async (communityId) => {
  try {
    const response = await axios.post(urls.getCommunityUsers, {
      communityId: communityId,
    });
    return response;
  } catch (error) {
    console.log(error, "error--- from get All Community users");
    throw error;
  }
};

export const addMessage = async (
  communityId,
  userId,
  userName,
  messageDetails
) => {
  try {
    const response = await axios.post(urls.addMessage, {
      communityId: communityId,
      fromUserId: userId,
      fromUserName: userName,
      messageDetails: messageDetails,
    });
    return response;
  } catch (error) {
    console.log(error, "error--- from add message");
    throw error;
  }
};

// export const uploadFile = async (userId, communityId, file) => {
//   try {
//     const response = await axios.post(
//       `${urls.uploadImgaePdf}?userId=${userId}&communityId=${communityId}`,
//       {
//         file: file,
//         // uniqueKey : Math.random(10).toString(36).substring(2, 12)
//       }
//     );
//     return response;
//   } catch (error) {
//     console.log(error, "error--- from get All Community msgs");
//     throw error;
//   }
// };

export const sendFile = async (userId, communityId, payload) => {  
  try {
    const response = await axios.post(
      `${urls.uploadImgaePdf}?userId=${userId}&communityId=${communityId}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
