import Styles from "./EventChallengeMC.module.scss";
import {
  getLatestHappeningData,
  getLatestHappeningSummary,
} from "../../service/communityService.js";
import { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import ButtonWithLink from "../buttons/ButtonWithLink";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { truncateText, formatDate } from "../../helper-fuction/helper";
import { urls } from "../../utils/urls";

export default function EventChallengeMC() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  let navigate = useNavigate();

  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  const slidesPerView = Math.min(3, data.length);
  const swiperOptions = {
    slidesPerView: 1,
    // spaceBetween: 10,
    navigation: {
      nextEl: ".my-swiper-button-next",
      prevEl: ".my-swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: slidesPerView,
      },
    },
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    try {
      if (page === 1) {
        setLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      const summary = await getLatestHappeningSummary();
      setTotalPages(summary.totalPage);
      const result = await getLatestHappeningData(page);
      setData((prevData) => [...prevData, ...result]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (page === 1) {
        setLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  const handleLoadMore = () => {
    setIsAtEnd(false);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    const swiper = document.querySelector(".my-swiper-container-comp1").swiper;
    swiper.slidePrev();
    setIsAtEnd(false);
    if (screen > 768) {
      if (data.length <= 3) {
        setIsAtEnd(true);
      } else {
        setIsAtEnd(false);
      }
    } else {
      if (data.length <= 1) {
        setIsAtEnd(true);
      } else {
        setIsAtEnd(false);
      }
    }
  };

  const handleNextClick = () => {
    const swiper = document.querySelector(".my-swiper-container-comp1").swiper;
    swiper.slideNext();
    setIsAtBeginning(false);
    if (screen > 768) {
      if (data.length <= 3) {
        setIsAtBeginning(true);
      } else {
        setIsAtBeginning(false);
      }
    } else {
      if (data.length <= 1) {
        setIsAtBeginning(true);
      } else {
        setIsAtBeginning(false);
      }
    }
  };

  return (
    <div className={Styles["main-parent-div"]}>
      {data.length > 0 && (
        <span className={Styles["main-title"]}>Latest Happening</span>
      )}
      {data.length > 0 && (
        <div className={Styles["bullatin-main-div"]}>
          {loading ? (
            <ThreeCircles
              height="70"
              width="70"
              color="#F52929"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "20vh",
              }}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
            />
          ) : (
            <>
              {data.length !== 0 ? (
                <>
                  <img
                    alt=""
                    src={urls.prevArrow}
                    className={
                      isAtBeginning ? Styles["disabled"] : Styles["prev"]
                    }
                    onClick={handlePrevClick}
                  ></img>

                  <Swiper
                    {...swiperOptions}
                    className="my-swiper-container-comp1"
                    onReachBeginning={() => {
                      setIsAtBeginning(true);
                    }}
                    onReachEnd={() => {
                      setIsAtEnd(true);
                    }}
                  >
                    {data.map((item) => {
                      return (
                        <SwiperSlide key={item.id}>
                          <div
                            key={item.id}
                            className={Styles["bullatin-card"]}
                            onClick={() => {
                              if (item.categoryName === "Master Class" || item.categoryName === "Refer and Earn" || item.categoryName === "Fast-Track Session" || item.categoryName === "Scholarship") {
                                window.open(
                                  item.latest_happening_website_url,
                                  "_blank"
                                );
                              } else {
                                navigate(
                                  "/latestHappeningDetailPage/" +
                                    item.latest_happening_title
                                      .split(" ")
                                      .join("_") +
                                    "/" +
                                    item.id
                                );
                              }
                            }}
                          >
                            <div className={Styles["bullatin-top"]}>
                              <img
                                className={Styles["bullatin-img"]}
                                src={item.latest_happening_banner}
                                alt=""
                              ></img>
                            </div>
                            <div className={Styles["bullatin-bottom"]}>
                              <span className={Styles["bullatin-category"]}>
                                {item.categoryName}
                              </span>
                              <span className={Styles["bullatin-heading"]}>
                                {truncateText(item.latest_happening_title, 30)}
                              </span>
                              <span className={Styles["bullatin-date"]}>
                                Start Date :{" "}
                                {formatDate(item.latest_happening_start_date)}
                              </span>
                              <span className={Styles["bullatin-date"]}>
                                End Date :{" "}
                                {formatDate(item.latest_happening_end_date)}
                              </span>
                              {/* <span className={Styles["bullatin-website-link"]}>
                          Website Link :{" "}
                          <a
                            className={Styles["link"]}
                          >
                            click here
                          </a>
                        </span> */}

                              <tr>
                                <td>
                                  <div className={Styles["bullatin-mode"]}>
                                    {
                                      item?.latest_happening_service_payment_mode
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div className={Styles["bullatin-mode"]}>
                                    {" "}
                                    |{" "}
                                  </div>
                                </td>

                                <td>
                                  <div className={Styles["bullatin-mode"]}>
                                    {
                                      item?.latest_happening_conducting_methodology
                                    }
                                  </div>
                                </td>
                              </tr>
                              <div
                                className={Styles["know-more-div"]}
                                onClick={() => {
                                  if (item.categoryName !== "Master Class") {
                                    navigate(
                                      "/latestHappeningDetailPage/" +
                                        item.latest_happening_title
                                          .split(" ")
                                          .join("_") +
                                        "/" +
                                        item.id
                                    );
                                    // window.open(
                                    //   item.latest_happening_website_url,
                                    //   "_blank"
                                    // );
                                  } else {
                                    navigate("");
                                  }
                                }}
                              >
                                <span className={Styles["know-more"]}>
                                  Know More
                                </span>
                                <img
                                  alt=""
                                  src={urls.rightUpArrow}
                                  className={Styles["right-arrow"]}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}

                    {currentPage < totalPages && (
                      <SwiperSlide>
                        <div className={Styles["load-more-div"]}>
                          <ButtonWithLink
                            linkTo="action"
                            callback={handleLoadMore}
                            name={isLoadingMore ? "Loading..." : "Load More"}
                            className={"button"}
                            disabled={isLoadingMore}
                          ></ButtonWithLink>
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>

                  <img
                    alt=""
                    src={urls.nextArrow}
                    className={isAtEnd ? Styles["disabled"] : Styles["next"]}
                    onClick={handleNextClick}
                  ></img>
                </>
              ) : (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["no-data"]}
                >
                  <img
                    alt=""
                    src={urls.noData}
                    className={Styles["no-data-image"]}
                  ></img>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
