import Styles from "./ProgramOutcome.module.scss";
import AOS from "aos";
import { useEffect } from "react";
import { urls } from "../../utils/urls";

export default function ProgramOutcome({filteredData}) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["main-div"]}>
        <span
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["title"]}
        >
            {filteredData?.programOutcomes.title}
          
        </span>
        <div className={Styles["container"]}>
          {filteredData?.programOutcomes?.programOutcomes &&
            filteredData?.programOutcomes?.programOutcomes.map((item) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                key={item.id}
                className={Styles["element-div"]}
              >
                <img alt="" src={urls.redTickIcon} className={Styles["tick"]}></img>
                <span className={Styles["element"]}>{item.value}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
