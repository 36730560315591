import axios from 'axios';

  export const getDahsboardData = async (batchId, userId, programName) => {
    try {
      const response = await axios.get(
        `https://api.iamironlady.com/api/get-zoho-program-engagement-detail?batchId="${batchId}"&userId=${userId}&programName="${programName}"`)
      return response;
     
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      throw error;
    }
  };

  export const getAdminDahsboardData = async (batchId, programName) => {
    try {
      const response = await axios.get(
        `https://api.iamironlady.com/api/get-zoho-program-engagement-detail-for-admin?batchId="${batchId}"&programName="${programName}"`);
      return response;
     
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      throw error;
    }
  };


  export const getProgramAndBatchDetails = async () => {
    try {
      const response = await axios.get(
        "https://api.iamironlady.com/api/get-available-program-and-batch");
      return response.data;
     
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      throw error;
    }
  };