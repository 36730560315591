import axios from "axios";
import {urls} from "../utils/urls";

export const getInTouch = async (urlToCallGetInTouch) => {
  try {
    const response = await axios.post(
      urls.getInTouch,
      {
        urlDetails: urlToCallGetInTouch,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const refer = async (urlToCallRefer) => {
  try {
    const response = await axios.post( urls.refer, {
      urlDetails: urlToCallRefer,
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const getSelectedBatch = async (urlToCallBatchSelection) => {
  try {
    const response = await axios.post(
      urls.selectBatch,
      {
        urlDetails: urlToCallBatchSelection,
      }
    );
        return response;
  } catch (error) {
    throw error;
  }
};

export const individualProgram = async (urlToGetForm) => {
  try {
    const response = await axios.get(urls.individualProgramApi, {
      params: {
        urlDetails: urlToGetForm,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const registerProgram = async (params) => {
  try {
    const response = await axios.get(`${urls.registerPogram}?programName=${params.programName}&price=${params.price}&gstPricePercentage=${params.gstPricePercentage}&formType=${params?.formType}&fullPayment=${params?.fullPayment}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const decryptProgramResponse = async (val) => {
  try {
    const response = await axios.post(urls.decryptProgramResponse, val);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBatchDetails = async () => {
  try{
    const response = await axios.get(urls.getBatchDetails);
    return response;
  }catch (error) {
    throw error;
  }
}