import Styles from "../what-diff-new/WhatDiff.module.scss";
import AOS from "aos";
import ReactCardFlip from "react-card-flip";
import { useEffect, useState, useRef } from "react";
import ButtonWithLinks from "../buttons/ButtonWithLink";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GetInTouch from "../common_components/forms/GetInTouch";

export default function SuccessStory({ title, data, buttonId, letsconnect, handleSucess, handleError }) {
  const sectionRef = useRef(null);
  useEffect(() => {
    AOS.init();
  }, []);

  const [flipCard, setFlipCard] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT)
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT)
  };

  useEffect(() => {
    const sectionRefCurrent = sectionRef.current;
    const sectionObserver = new IntersectionObserver(([entry]) => {
      setFlipCard(0);
      setTimeout(() => {
        setFlipCard(null);
      }, 1000);
    });

    if (sectionRefCurrent) {
      sectionObserver.observe(sectionRefCurrent);
    }

    return () => {
      if (sectionRefCurrent) {
        sectionObserver.unobserve(sectionRefCurrent);
      }
    };
  }, []);

  return (
    <>
     {dialogOpen && (
        <GetInTouch setDialogOpen={setDialogOpen} open={dialogOpen} programName = 'NA' sucessToasterGIT={handleSucessToasterGIT} errorToasterGIT={handleErrorToasterGIT} isDemo={true} />
      )}
      <div className={Styles["main-parent-div"]}>
      <div className={Styles["main-div"]}>
        <span
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["success-story-title"]}
        >
          {title}
        </span>

        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["main-card-div"]}
          ref={sectionRef}
          id="section2"
        >
          {data.map((item, index) => {
            return (
              <>
                <ReactCardFlip
                  key={item.id}
                  isFlipped={flipCard === index}
                  flipDirection="horizontal"
                >
                  <div
                    className={`${Styles["front-card"]} ${
                      flipCard === index ? Styles["back-card"] : ""
                    }`}
                    onMouseEnter={() => setFlipCard(index)}
                  >
                    <div className={Styles["img-div"]}>
                      <img
                        className={Styles["card-img"]}
                        src={item.img}
                        alt={item.title}
                      />
                    </div>
                    <p className={Styles["card-title"]}>{item.title}</p>
                  </div>

                  <div
                    className={`${Styles["back-card"]} ${
                      flipCard === index ? Styles["front-card"] : ""
                    }`}
                    onMouseOut={() => setFlipCard(null)}
                  >
                    {item.description.map((des, desIndex) => {
                      return (
                        <div key={des.id}>
                          <span className={Styles["card-description"]}>
                            {item.id === 2 ? (
                              des.value.split(" ").map((word, wordIndex) => {
                                if (
                                  wordIndex === 1 ||
                                  wordIndex === 3 ||
                                  wordIndex === 14 ||
                                  wordIndex === 15
                                ) {
                                  return (
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      key={wordIndex}
                                    >
                                      {word} &nbsp;
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span key={wordIndex}>{word}&nbsp; </span>
                                  );
                                }
                              })
                            ) : item.id === 5 ? (
                              des.value.split(" ").map((word, wordIndex) => {
                                if (
                                  wordIndex === 8 ||
                                  wordIndex === 9 ||
                                  wordIndex === 10 ||
                                  wordIndex === 12 ||
                                  wordIndex === 26 ||
                                  wordIndex === 27 ||
                                  wordIndex === 29
                                ) {
                                  return (
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      key={wordIndex}
                                    >
                                      {word} &nbsp;
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span key={wordIndex}>{word}&nbsp; </span>
                                  );
                                }
                              })
                            ) : (
                              <>{des.value}</>
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </ReactCardFlip>
              </>
            );
          })}
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["joinUs-button"]}
        >
          <ButtonWithLinks
            id={buttonId}
            name="Explore Programs"
            linkTo="/programs"
            className={"button"}
          />
        </div>
      </div>
    </div>
    </>
    
  );
}
