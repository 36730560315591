import Styles from "./LeadershipCourses.module.scss";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThreeCircles } from "react-loader-spinner";
import { urls } from "../../utils/urls";

export default function LeadershipCourses() {
  const [leadershipCourses, setLeadershipCourses] = useState([]);
  const apiData = useSelector((state) => state.apiData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (Object.keys(apiData)?.length) {
      setLeadershipCourses(apiData.leadershipCourses);
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [apiData]);

  useEffect(() => {
    AOS.init();
  }, []);

  // let leadershipCourses = appLevelConstants.LeadershipCourses.leadershipCourses;

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3vw",
            marginBottom: "3vw",
          }}
        >
          <ThreeCircles
            height="100"
            width="100"
            color="#F52929"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      )}
      {loading === false && leadershipCourses?.length !== 0 && (
        <div id={Styles["leadership-courses"]}>
          <label
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            className={Styles["leadership-courses-title"]}
          >
            {appLevelConstants.LeadershipCourses.title}
          </label>
          <div className={Styles["leadership-courses-list"]}>
            {leadershipCourses &&
              leadershipCourses.map((course, index) => {
                return (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    key={course.title}
                    className={Styles["leadership-courses-card"]}
                  >
                    <Link
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      className={Styles["title"]}
                      to={course.to}
                    >
                      {course.title}
                    </Link>
                    <div
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      className={Styles["description"]}
                    >
                      {course.des.map((des) => {
                        return (
                          <div key={des.id}>
                            <span>{des.des}</span>
                          </div>
                        );
                      })}
                      <div></div>
                    </div>
                    <Link
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      to={course.to}
                      className={Styles["know-more-div"]}
                    >
                      <span className={Styles["know-more"]}>
                        {course.knowMore}
                      </span>
                      <img
                        src={urls.rightUpArrow}
                        alt=""
                        className={Styles["right-arrow"]}
                      ></img>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
