import Styles from "./Feeds.module.scss";
import { useState } from "react";
import { getAllPostsData } from "../../service/communityService";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import ButtonWithLink from "../buttons/ButtonWithLink";
import AOS from "aos";
import { truncateText } from "../../helper-fuction/helper";
import { urls } from "../../utils/urls";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

export default function Feeds() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  let navigate = useNavigate();

  const handleClose = () => {
    setDialogOpen(false);
  };
  const [selectedFilters, setSelectedFilters] = useState(["all"]);

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(name)) {
        return prevFilters.filter((filter) => filter !== name);
      } else {
        return [...prevFilters, name];
      }
    });
  };

  const filterContent = (item) => {
    if (selectedFilters.length === 0 || selectedFilters.includes("all")) {
      return true; // Show all items if no filters selected
    } else {
      return selectedFilters.includes(item.categoryName);
    }
  };

  const [blogData, setBlogData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const blogDataFiltered = blogData.filter(
    () => selectedFilters.includes("all") || selectedFilters.includes("Blog")
  );

  const newsDataFiltered = newsData.filter(
    () => selectedFilters.includes("all") || selectedFilters.includes("News")
  );

  const articleDataFiltered = articleData.filter(
    () => selectedFilters.includes("all") || selectedFilters.includes("Article")
  );

  const eventDataFiltered = eventData.filter(
    () => selectedFilters.includes("all") || selectedFilters.includes("Event")
  );

  const allData = [
    ...blogDataFiltered,
    ...newsDataFiltered,
    ...articleDataFiltered,
    ...eventDataFiltered
  ];

  useEffect(() => {
    fetchBannerData();
  }, []);
  const fetchBannerData = async () => {
    try {
      setLoading(true);
      const bulletinsResponse = await getAllPostsData();
      const bulletins = JSON.parse(bulletinsResponse);

      const recentBlogs = bulletins.blog.slice(0, 10);

      setBlogData(recentBlogs);
      setNewsData(bulletins.news);
      setArticleData(bulletins.article);
      setEventData(bulletins.event);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (item) => {
    // setIframeSrc(url);
    // setDialogOpen(true);
    const {
      id,
      categoryName,
      PostUrl,
      PostCategory,
      PostSubCategory,
      PostTitle,
      PostSlug
    } = item;

    if (id === 3061 || id === 3121) {
      window.open(PostUrl, '_blank');
    } else if (categoryName === 'Article') {
      navigate(`/articles/${PostSlug}`, { state: { id, slug: PostSlug } });

    } else if (categoryName === 'Blog') {
      // navigate(`/blogs/${PostCategory}/${PostSubCategory}/${PostTitle}`, { state: { id, name: PostTitle } });
      navigate(`/blogs/${PostCategory}/${PostSubCategory}/${PostSlug}`, { state: { id, slug: PostSlug } });
    } else {
      navigate("/feedsDescription/" + id, { state: { url: PostUrl } });
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["main-div"]}>
        <span className={Styles["feeds"]}>Feeds</span>
        <div className={Styles["checkbox-div"]}>
          <img className={Styles["filter"]} src={urls.filterIcon} alt=""></img>
          <div className={Styles["checkbox"]}>
            <label>
              <input
                type="checkbox"
                name="all"
                checked={selectedFilters.includes("all")}
                onChange={handleCheckboxChange}
              />
              All
            </label>
            <label>
              <input
                type="checkbox"
                name="Blog"
                checked={selectedFilters.includes("Blog")}
                onChange={handleCheckboxChange}
              />
              Blog
            </label>
            <label>
              <input
                type="checkbox"
                name="Article"
                checked={selectedFilters.includes("Article")}
                onChange={handleCheckboxChange}
              />
              Article
            </label>
            <label>
              <input
                type="checkbox"
                name="News"
                checked={selectedFilters.includes("News")}
                onChange={handleCheckboxChange}
              />
              News
            </label>
            <label>
              <input
                type="checkbox"
                name="Event"
                checked={selectedFilters.includes("Event")}
                onChange={handleCheckboxChange}
              />
              Event
            </label>
          </div>
        </div>

        {loading ? (
          <ThreeCircles
            height="70"
            width="70"
            color="#F52929"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "20vh",
            }}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
          />
        ) : allData?.filter(filterContent).length > 0 ? (
          allData?.map((item, index) => {
            return (
              <div
                className={Styles["card-div"]}
                key={index}
                // onClick={() => handleClick(item.PostUrl, item.id, item.categoryName)}
                onClick={() => handleClick(item)}
              >
                <div className={Styles["right-side"]}>
                  <img
                    className={Styles["img"]}
                    src={item.PostImageUrl}
                    alt=""
                  />
                </div>
                <div className={Styles["left-side"]}>
                  <span className={Styles["heading"]}>{item.categoryName}</span>
                  <span className={Styles["title"]}>
                    {" "}
                    {truncateText(item.PostName, 40)}
                  </span>
                  <span className={Styles["date"]}>{item.PostDate}</span>
                </div>
              </div>
            );
          })
        ) : (
          <img src={urls.noData} alt=""></img>
        )}
      </div>

      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          onClose={handleClose}
          open={dialogOpen}
        >
          <iframe
            title="detailed-feed"
            src={iframeSrc}
            width="100%"
            height="600"
          ></iframe>
          <div onClick={handleClose} className={Styles["close-iframe"]}>
            <ButtonWithLink name="Close" className={"button"} />
          </div>
        </Dialog>
      </div>
    </div>
  );
}
