import React from "react";
import Styles from "./AboutUs.module.scss";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import AOS from "aos";
import { useEffect , useState} from "react";
import { urls } from "../../utils/urls";
export default function AboutUs() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [profession, setProfession] = useState("CEOs");
  const getNextProfession = (currentProfession) => {
    switch (currentProfession) {
      case "CEOs":
        return "CHRO";
      case "CHRO":
        return "COOs";
      case "COOs":
        return "CFOs";
      case "CFOs":
        return "CTOs";
      case "CTOs":
        return "CxOs";
      case "CxOs":
        return "Entrepreneurs";
      case "Entrepreneurs":
        return "Doctors ";
      case "Doctors" : 
        return "Lawyers ";
      default:
        return "CEOs";
    }
  };
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setProfession(prevProfession => getNextProfession(prevProfession));
    }, 2000);

    return () => {
      clearInterval(toggleInterval);
    };
  }, []);

  return (
    <>
      <div className={Styles["main-parent-div"]}>
      <div className={Styles["main-div"]}>
        <video
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["aboutus-banner"]}
          controls
          poster={urls.aboutUsThumbneil}
        >
          <source src={urls.aboutUsVideo} type="video/mp4" />
        </video>

        <div>
          <div className={Styles["content1-div"]}>
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              className={Styles["con1"]}
            >
              {appLevelConstants.aboutUs.mainContent}
              <span className={Styles["con"]}>
                {appLevelConstants.aboutUs.mainContent1}
              </span>
            </span>
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              className={Styles["content1"]}
            >
              {appLevelConstants.aboutUs.mainContent2}
            </span>
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              className={Styles["content1"]}
            >
              {appLevelConstants.aboutUs.mainContent3}
              <span className={Styles["profession"]}>{profession}</span>
              {appLevelConstants.aboutUs.mainContent4}
            </span>
          </div>
        </div>
      </div>
      <div className={Styles["tedx-text"]}>
        {appLevelConstants.aboutUs.mainContent7}
      </div>
    </div>
    </>
    
  );
}
