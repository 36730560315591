
import axios from 'axios';

const BASE_URL = "https://api.iamironlady.com/api";


export const getGlimsesImages = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/get-s3-urls`
      );
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };