import { urls } from "../../utils/urls";
import ButtonWithLink from "../buttons/ButtonWithLink";
import Styles from "./EventRegistration.module.scss";
import AOS from "aos";
import { useEffect } from "react";

export default function EventRegistration() {

    useEffect(() => {
        AOS.init();
      }, []);
  const openRegistrationLink = () => {
    window.open("https://workshops.iamironlady.com/masterclass_app", "_blank");
  };
  return (
    <div className={Styles["main-parent-div"]}>

      <div data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out" className={Styles["container-div"]}>
        <div className={Styles["left-heading"]}>
          <div className={Styles["text"]}>
            {" "}
            Unlock leadership excellence! Register now for the upcoming Iron Lady Leadership Masterclass.
          </div>
        </div>
        <div className={Styles["button-div"]}>
          <ButtonWithLink
            name="REGISTER NOW"
            className={"button-red"}
            linkTo="action"
            callback={openRegistrationLink}
          ></ButtonWithLink>
        </div>
      </div>
    </div>
  );
}
