import axios from 'axios';
import { urls } from '../utils/urls';

  export const fetchIndividualPrograms = () => {
    return axios.get(urls.fetchIndividualProgramsDataApi)
      .then((response) => {
        return response; 
      })
      .catch((error) => {
        console.log('Error:', error);
        throw error; 
      });
  };

