import {createSlice} from '@reduxjs/toolkit';

const appDataSlice = createSlice({
  name: 'app',
  initialState: {},
  reducers: {
    addApiData: (state, action) => {
      return action.payload;
    },
  },
});

export const {addApiData} = appDataSlice.actions;

export default appDataSlice.reducer;
