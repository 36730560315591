import Styles from "./GroupChats.module.scss";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { ThreeCircles } from "react-loader-spinner";
import {
  formatTimestampToTime,
  formatDatestampToDate,
} from "../../helper-fuction/helper";
import {
  addMessage,
  updateUser,
  sendFile,
} from "../../service/communityService";
import { Dialog, DialogActions, DialogTitle, FormLabel } from "@mui/material";
import GetInTouch from "../common_components/forms/GetInTouch";
import { ToastContainer, toast } from "react-toastify";
import ButtonWithLinks from "../buttons/ButtonWithLink";
import { decryptResponse } from "../../helper-fuction/helper";
import UserValidation from "../common_components/forms/user-validation/UserValidation";
import OtpValidation from "../common_components/forms/otpValidatoin/otpValidation";
import {
  socket,
  onlineStatusSocket,
  communityGroupDetailsSocket,
  communityGroupUserDetailsSocket,
  deleteMessagesByAdmin,
} from "../../service/socketService";
import { urls } from "../../utils/urls";

export default function GroupChats() {
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [activeCardId, setActiveCardId] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [fileName, setFileName] = useState(" ");
  const [fileType, setFileType] = useState(" ");
  const [searchQuery, setSearchQuery] = useState("");
  const [showLeftDiv, setShowLeftDiv] = useState(true);
  const [communityData, setCommunityData] = useState([]);
  const [communityAllMsgs, setCommunityAllMsgs] = useState([]);
  const chatWindowRef = useRef(null);
  const currentCommID = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [isRightDivVisible, setRightDivVisible] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [publicMessageDialogOpen, setPublicMessageDialogOpen] = useState(false);
  const [speakToUsDialogOpen, setSpeakToUsDialogOpen] = useState(false);
  const [showUserListDiv, setShowUserListDiv] = useState(false);
  const [communityUsersList, setCommunityUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileData, setFileData] = useState(null);
  const [userValidationDialogOpen, setUserValidationDialogOpen] =
    useState(false);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState("initial");
  const [otpId, setOtpId] = useState("");
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [commId, setCommId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [breakCondition, setBreakCondition] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userdata"));

  const [selectedMessages, setSelectedMessages] = useState([]);
  const isSelected = (messageId) => selectedMessages.includes(messageId);

  const handleToggleSelection = (messageId) => {
    setSelectedMessages((prevSelected) => {
      if (prevSelected.includes(messageId)) {
        return prevSelected.filter((id) => id !== messageId);
      } else {
        return [...prevSelected, messageId];
      }
    });
  };

  const timerRef = useRef(null);

  const handleMouseDown = (messageId) => {
    timerRef.current = setTimeout(() => {
      handleToggleSelection(messageId);
    }, 500);
  };

  const handleMouseUp = () => {
    clearTimeout(timerRef.current);
  };

  useEffect(() => {
    communityGroupDetailsSocket.on(
      "communityActiveGroupDetails",
      function (data) {
        setCommunityData(data);
      }
    );
    communityGroupUserDetailsSocket.on(
      "communityGroupUsersDetails",
      function (data) {
        setCommunityUsersList(data);
        handleUserStatus();
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleUserStatus = () => {
    if (communityUsersList) {
      onlineStatusSocket.emit("updateUserStatus", {
        userId: userData?.zohoUserId,
      });
      onlineStatusSocket.on("userIsOnline", function (data) {
        updateUserList(data);
      });
    }
  };

  const handleDelete = (commId) => {
    deleteMessagesByAdmin.emit("updateMessageFlag", {
      communityId: commId,
      messageIds: selectedMessages,
    });
    deleteMessagesByAdmin.on("updateMessageFlag", (data) => {
      console.log(data, "delete data");
    });
    loadFirstTimeMsgs(commId);
    setTimeout(() => {
      scrollToBottom();
    }, 500);

    setDeletePopUp(false);
    setSelectedMessages([]);
  };

  const updateUserList = (data) => {
    setCommunityUsersList((prevUsersList) => {
      const updatedUsersList = prevUsersList.map((user) =>
        user.userId === data.userId
          ? { ...user, onlineStatus: data.onlineStatus }
          : user
      );
      return updatedUsersList;
    });
  };
  const handleGroupDetails = () => {
    communityGroupDetailsSocket.emit("communityGroupsDetails", {
      userId: userData?.zohoUserId,
    });
  };
  const handleCommunityUsers = (id) => {
    communityGroupUserDetailsSocket.emit("communityGroupsUserDetails", {
      communityId: id,
    });
  };
  const handleCardClick = (id, type, name) => {
    setBreakCondition(false);
    setLoading(true);
    setCommId(id);
    currentCommID.current = id;
    cardClick(id, type, name);
  };
  const cardClick = (communityId, communityType, communityName) => {
    setShowUserListDiv(false);
    updateUserData();
    if (
      updatedUserData?.groupDetailSubscribed !== null &&
      updatedUserData?.groupDetailSubscribed.includes(communityName)
    ) {
      setRightDivVisible(true);
      setActiveCardId(communityId);
      const chat = communityData?.find(
        (item) => item?.communityId === communityId
      );
      loadFirstTimeMsgs(communityId);
      setSelectedChat(chat);
      setShowLeftDiv(false);
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    } else if (communityType === "Public") {
      setPublicMessageDialogOpen(true);
      setRightDivVisible(false);
      setActiveCardId(null);
      setSelectedChat(null);
    } else {
      setMessageDialogOpen(true);
      setRightDivVisible(false);
      setActiveCardId(null);
      setSelectedChat(null);
    }
  };
  const loadFirstTimeMsgs = (communityId) => {
    setCommunityAllMsgs([]);
    const loadMessageReqObj = {
      communityId: communityId,
      page: 1,
      limit: 50,
    };
    socket.emit("loadOldChatMessageReq", loadMessageReqObj);
    socket.on("loadAllChatMesage", (data) => {
      if (
        currentCommID.current === data.chatCommunityId &&
        data?.currentPage === 1
      ) {
        setTotalPages(data.totalPages);
        setCommunityAllMsgs(data?.chatDetails);
        setCurrentPage(2);
        setLoading(false);
      }
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const loadOnScrolMsgs = (communityId) => {
    const loadMessageReqObj = {
      communityId: communityId,
      page: currentPage,
      limit: 50,
    };
    if (currentPage > 1) {
      socket.emit("loadOldChatMessageReq", loadMessageReqObj);
      socket.on("loadAllChatMesage", (data) => {
        if (currentCommID.current === data.chatCommunityId) {
          const updatedChatsMessage = [
            ...data.chatDetails,
            ...communityAllMsgs,
          ];
          setCommunityAllMsgs(updatedChatsMessage);
          setCurrentPage(currentPage + 1);
          setLoading(false);
        } else if (currentPage === totalPages) {
          const updatedChatsMessage = [
            ...data.chatDetails,
            ...communityAllMsgs,
          ];
          setCommunityAllMsgs(updatedChatsMessage);
          setLoading(false);
          setBreakCondition(true);
        }
      });
    }
  };
  const handleScroll = () => {
    if (chatWindowRef.current.scrollTop === 0 && breakCondition === false) {
      loadOnScrolMsgs(selectedChat?.communityId);
    }
  };
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      const start = chatWindowRef.current.scrollTop;
      const end = chatWindowRef.current.scrollHeight;
      const duration = 500;
      const startTime = performance.now();
      const animateScroll = (timestamp) => {
        const progress = Math.min((timestamp - startTime) / duration, 1);
        const easedProgress = 0.5 - Math.cos(progress * Math.PI) / 2;
        chatWindowRef.current.scrollTop = start + easedProgress * (end - start);
        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };
      requestAnimationFrame(animateScroll);
    }
  };
  const updateUserData = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    try {
      if (userData) {
        const response = await updateUser(userData?.zohoUserId);
        setUpdatedUserData(response.data);
      } else {
        setUserValidationDialogOpen(true);
      }
    } catch (error) {
      throw error;
    }
  };
  const handleUserValidationResponse = (response) => {
    setOtpId(response.data.data.otpID);
    setMessage(response.data.message);
    setStatusCode(response.data.statusCode);
    if (response.data.statusCode === 200) {
      setOtpDialogOpen(true);
    }
  };
  const handleOtpVerificationUserDataResponse = (response) => {
    if (response.data.statusCode !== 200) {
      setOtpDialogOpen(true);
    } else {
      setOtpDialogOpen(false);
    }
  };
  useEffect(() => {
    updateUserData();
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window?.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleGroupDetails();
  }, [communityAllMsgs]); // eslint-disable-line react-hooks/exhaustive-deps

  const addMsgData = async (communityId, userId, userName, messageDetails) => {
    try {
      await addMessage(communityId, userId, userName, messageDetails);
      loadFirstTimeMsgs(communityId);
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    } catch (error) {
      console.log(error, "error---");
      throw error;
    }
  };
  const handleFileSelection = (accept) => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = accept;
    inputElement.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2048000) {
          setAlertDialogOpen(true);
        } else {
          if (file.type.startsWith("image/")) {
            setFileData(file);
            setImageFile(file);
            setPreviewOpen(true);
            setIsDropdownOpen(false);
            setFileName(file.name);
            setFileType(file.type);
          } else {
            setFileData(file);
            setPreviewOpen(true);
            setIsDropdownOpen(false);
            setFileName(file.name);
            setFileType(file.type);
          }
        }
      }
    };
    inputElement.click();
    setIsDropdownOpen(false);
    setPreviewOpen(false);
  };
  const handleSentFile = async (communityId) => {
    try {
      const formData = new FormData();
      formData.append("file", fileData);
      const fileResponse = await sendFile(
        userData?.zohoUserId,
        communityId,
        formData
      );
      setMessageText(fileResponse.data.data[1].url);
      addMsgData(
        communityId,
        userData?.zohoUserId,
        decryptResponse(
          userData?.fullName,
          appLevelConstants.REACT_APP_SECRET_KEY
        ),
        fileResponse.data.data[1].url
      );
      closePreview();
      setMessageText("");
    } catch (error) {
      throw error;
    }
  };
  const filteredGroups = communityData?.filter((group) =>
    group?.communityName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closePreview = () => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
    setPreviewOpen(!previewOpen);
    setMessageText("");
    setFileName("");
    setFileType("");
    setImageFile(null);
    setFileData(null);
  };
  const handleSendMessage = (communityId, messageDetails) => {
    addMsgData(
      communityId,
      userData?.zohoUserId,
      decryptResponse(
        userData?.fullName,
        appLevelConstants.REACT_APP_SECRET_KEY
      ),
      messageDetails
    );
    setMessageText("");
  };
  const messageDialogClose = () => {
    setMessageDialogOpen(false);
  };
  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };
  const handleSpeakToUsDialog = () => {
    setSpeakToUsDialogOpen(true);
    setMessageDialogOpen(false);
  };
  const handleDropdownClick = (communityId) => {
    handleCommunityUsers(communityId);
    setShowUserListDiv(true);
  };
  return (
    <>
      <div className={Styles["main-container"]}>
        <div className={Styles["back-button-container"]}>
          <ButtonWithLinks
            id="groupChatPageBack"
            name="Back"
            linkTo="/latest"
            className={"back-button"}
          />
        </div>
        <div className={Styles["main-parent-div"]}>
          {screenWidth >= 768 ? (
            <>
              <div className={Styles["left-div"]}>
                <div className={Styles["header"]}>
                  <span className={Styles["chats"]}>Chats</span>
                </div>

                <div className={Styles["search-bar-div"]}>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={(e) => (e.target.style.outline = "none")}
                    className={Styles["search-bar"]}
                    placeholder="Search or start a new chat"
                  />
                </div>

                <div className={Styles["chat-cards-container"]}>
                  <div className={Styles["chat-cards-div"]}>
                    {searchQuery === " " ? (
                      <>
                        {communityData?.map((item) => {
                          return (
                            <div
                              id={item?.communityId}
                              key={item?.communityId}
                              className={
                                activeCardId === item?.communityId
                                  ? Styles["chat-card-active"]
                                  : Styles["chat-card"]
                              }
                              onClick={() =>
                                handleCardClick(
                                  item?.communityId,
                                  item?.communityType,
                                  item?.communityName
                                )
                              }
                            >
                              <img
                                alt=""
                                className={Styles["profile"]}
                                src={
                                  item?.communityIcon
                                    ? item?.communityIcon
                                    : urls.defaultGroupIcon
                                }
                              ></img>
                              <div className={Styles["profile-details"]}>
                                <div className={Styles["name-time-div"]}>
                                  <div className={Styles["name"]}>
                                    {item?.communityName}{" "}
                                  </div>
                                  <div className={Styles["type"]}>
                                    {item?.communityType}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : filteredGroups.length > 0 ? (
                      <>
                        {filteredGroups.map((item) => {
                          return (
                            <div
                              id={item?.communityId}
                              key={item?.communityId}
                              className={
                                activeCardId === item?.communityId
                                  ? Styles["chat-card-active"]
                                  : Styles["chat-card"]
                              }
                              onClick={() =>
                                handleCardClick(
                                  item?.communityId,
                                  item?.communityType,
                                  item?.communityName
                                )
                              }
                            >
                              <img
                                alt=""
                                className={Styles["profile"]}
                                src={
                                  item?.communityIcon
                                    ? item?.communityIcon
                                    : urls.defaultGroupIcon
                                }
                              ></img>
                              <div className={Styles["profile-details"]}>
                                <div className={Styles["name-time-div"]}>
                                  <div className={Styles["name"]}>
                                    {item?.communityName}{" "}
                                  </div>
                                  <div className={Styles["type"]}>
                                    {item?.communityType}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className={Styles["group-not-found"]}>
                        Groups not found
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={Styles["right-div"]}>
                {!selectedChat && (
                  <div className={Styles["before-select-chat"]}>
                    <img src={urls.groupImage} alt=""></img>
                    <div> Start group chat now </div>
                  </div>
                )}
                {selectedChat && isRightDivVisible && (
                  <>
                    <div className={Styles["header-div"]}>
                      <div className={Styles["profile-div"]}>
                        <img
                          alt=""
                          className={Styles["profile"]}
                          src={
                            selectedChat?.communityIcon
                              ? selectedChat?.communityIcon
                              : urls.defaultGroupIcon
                          }
                        ></img>
                        <div className={Styles["name"]}>
                          {selectedChat?.communityName}{" "}
                        </div>
                      </div>

                      <div className={Styles["dots-delete-div"]}>
                        {selectedMessages.length !== 0 && (
                          <img
                            alt=""
                            src={urls.deleteIcon}
                            className={Styles["delete-icon"]}
                            onClick={() => setDeletePopUp(true)}
                          ></img>
                        )}
                        <img
                          alt=""
                          src={urls.dots}
                          className={Styles["dots"]}
                          onClick={() =>
                            handleDropdownClick(selectedChat?.communityId)
                          }
                        ></img>
                      </div>
                    </div>

                    {!previewOpen && (
                      <>
                        {loading ? (
                          <ThreeCircles
                            height="50"
                            width="50"
                            color="#F52929"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "10vh",
                            }}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                          />
                        ) : communityAllMsgs?.length > 0 ? (
                          <div
                            ref={chatWindowRef}
                            onScroll={handleScroll}
                            className={Styles["chat-window-div"]}
                            style={
                              selectedChat?.communityStatus !== "Inactive"
                                ? { height: "25vw" }
                                : { height: "37vw" }
                            }
                          >
                            <div className={Styles["chat-window"]}>
                              <>
                                {communityAllMsgs
                                  ?.filter(
                                    (e) => commId === currentCommID?.current
                                  )
                                  .map((message, index) => (
                                    <>
                                      {message?.messageDetails &&
                                      appLevelConstants.imageExtensions.some(
                                        (extension) =>
                                          message?.messageDetails.includes(
                                            extension
                                          )
                                      ) ? (
                                        <div
                                          style={
                                            userData.role === "Admin"
                                              ? { cursor: "pointer" }
                                              : ""
                                          }
                                          className={
                                            decryptResponse(
                                              message?.fromUserName,
                                              appLevelConstants.REACT_APP_SECRET_KEY
                                            ) ===
                                            decryptResponse(
                                              userData?.fullName,
                                              appLevelConstants.REACT_APP_SECRET_KEY
                                            )
                                              ? Styles["img-time-div-right"]
                                              : Styles["img-time-div-left"]
                                          }
                                          onMouseDown={
                                            userData.role === "Admin"
                                              ? () =>
                                                  handleMouseDown(
                                                    message?.messageId
                                                  )
                                              : ""
                                          }
                                          onMouseUp={
                                            userData.role === "Admin"
                                              ? () => handleMouseUp()
                                              : ""
                                          }
                                        >
                                          <div
                                            className={Styles["img-div"]}
                                            style={
                                              isSelected(message?.messageId)
                                                ? {
                                                    backgroundColor: "darkgrey",
                                                  }
                                                : {}
                                            }
                                          >
                                            <div
                                              className={Styles["user-name"]}
                                            >
                                              {decryptResponse(
                                                message?.fromUserName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              ) ===
                                              decryptResponse(
                                                userData?.fullName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              )
                                                ? "You"
                                                : decryptResponse(
                                                    message?.fromUserName,
                                                    appLevelConstants.REACT_APP_SECRET_KEY
                                                  )}
                                            </div>

                                            <a
                                              href={message.messageDetails}
                                              download={message.messageDetails}
                                              className={Styles["prev-img-div"]}
                                            >
                                              <img
                                                src={message.messageDetails}
                                                alt="Selected File"
                                                className={Styles["prev-img"]}
                                              ></img>
                                            </a>
                                          </div>
                                          <div
                                            className={Styles["time-date-div"]}
                                          >
                                            <div className={Styles["time"]}>
                                              {message.messageCreatedDate
                                                ? formatTimestampToTime(
                                                    message.messageCreatedDate
                                                  )
                                                : " "}
                                            </div>
                                            <div className={Styles["time"]}>
                                              {message.messageCreatedDate
                                                ? formatDatestampToDate(
                                                    message.messageCreatedDate
                                                  )
                                                : " "}
                                            </div>
                                          </div>
                                        </div>
                                      ) : message?.messageDetails &&
                                        message?.messageDetails.includes(
                                          ".pdf"
                                        ) ? (
                                        <div
                                          style={
                                            userData.role === "Admin"
                                              ? { cursor: "pointer" }
                                              : ""
                                          }
                                          className={
                                            decryptResponse(
                                              message?.fromUserName,
                                              appLevelConstants.REACT_APP_SECRET_KEY
                                            ) ===
                                            decryptResponse(
                                              userData?.fullName,
                                              appLevelConstants.REACT_APP_SECRET_KEY
                                            )
                                              ? Styles["pdf-time-div-right"]
                                              : Styles["pdf-time-div-left"]
                                          }
                                          onMouseDown={
                                            userData.role === "Admin"
                                              ? () =>
                                                  handleMouseDown(
                                                    message?.messageId
                                                  )
                                              : ""
                                          }
                                          onMouseUp={
                                            userData.role === "Admin"
                                              ? () => handleMouseUp()
                                              : ""
                                          }
                                        >
                                          <div
                                            className={Styles["pdf-div"]}
                                            style={
                                              isSelected(message?.messageId)
                                                ? {
                                                    backgroundColor: "darkgrey",
                                                  }
                                                : {}
                                            }
                                          >
                                            <div
                                              className={Styles["user-name"]}
                                            >
                                              {decryptResponse(
                                                message?.fromUserName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              ) ===
                                              decryptResponse(
                                                userData?.fullName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              )
                                                ? "You"
                                                : decryptResponse(
                                                    message?.fromUserName,
                                                    appLevelConstants.REACT_APP_SECRET_KEY
                                                  )}
                                            </div>
                                            <a
                                              href={message?.messageDetails}
                                              download={fileName}
                                              className={Styles["pdf-name"]}
                                            >
                                              <img
                                                src={urls.document}
                                                alt="download"
                                                className={Styles["document"]}
                                              ></img>{" "}
                                              PDF File
                                              <img
                                                src={urls.downloadFile}
                                                alt="download"
                                                className={Styles["download"]}
                                              ></img>
                                            </a>
                                          </div>
                                          <div
                                            className={Styles["time-date-div"]}
                                          >
                                            <div className={Styles["time"]}>
                                              {message.messageCreatedDate
                                                ? formatTimestampToTime(
                                                    message.messageCreatedDate
                                                  )
                                                : " "}
                                            </div>
                                            <div className={Styles["time"]}>
                                              {message.messageCreatedDate
                                                ? formatDatestampToDate(
                                                    message.messageCreatedDate
                                                  )
                                                : " "}
                                            </div>
                                          </div>
                                        </div>
                                      ) : message?.messageDetails &&
                                        (message.messageDetails.includes(
                                          "http:"
                                        ) ||
                                          message.messageDetails.includes(
                                            "https:"
                                          ) ||
                                          message.messageDetails.includes(
                                            ".com"
                                          )) ? (
                                        <>
                                          <div
                                            style={
                                              userData.role === "Admin"
                                                ? { cursor: "pointer" }
                                                : ""
                                            }
                                            className={
                                              decryptResponse(
                                                message?.fromUserName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              ) ===
                                              decryptResponse(
                                                userData?.fullName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              )
                                                ? Styles[
                                                    "message-time-div-right"
                                                  ]
                                                : Styles[
                                                    "message-time-div-left"
                                                  ]
                                            }
                                            onMouseDown={
                                              userData.role === "Admin" &&
                                              message?.messageDetails !==
                                                "This message was deleted."
                                                ? () =>
                                                    handleMouseDown(
                                                      message?.messageId
                                                    )
                                                : ""
                                            }
                                            onMouseUp={
                                              userData.role === "Admin" &&
                                              message?.messageDetails !==
                                                "This message was deleted."
                                                ? () => handleMouseUp()
                                                : ""
                                            }
                                          >
                                            <div
                                              className={Styles["message-div"]}
                                              style={
                                                isSelected(message?.messageId)
                                                  ? {
                                                      backgroundColor:
                                                        "darkgrey",
                                                    }
                                                  : {}
                                              }
                                            >
                                              <div
                                                className={Styles["user-name"]}
                                              >
                                                {decryptResponse(
                                                  message?.fromUserName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                ) ===
                                                decryptResponse(
                                                  userData?.fullName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                )
                                                  ? "You"
                                                  : decryptResponse(
                                                      message?.fromUserName,
                                                      appLevelConstants.REACT_APP_SECRET_KEY
                                                    )}
                                              </div>
                                              <a
                                                key={index}
                                                className={Styles["message"]}
                                                href={message.messageDetails}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {message.messageDetails}
                                              </a>
                                            </div>

                                            <div
                                              className={
                                                Styles["time-date-div"]
                                              }
                                            >
                                              <div className={Styles["time"]}>
                                                {message.messageCreatedDate
                                                  ? formatTimestampToTime(
                                                      message.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                              <div className={Styles["time"]}>
                                                {message.messageCreatedDate
                                                  ? formatDatestampToDate(
                                                      message.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div
                                          style={
                                            userData.role === "Admin" &&
                                            message?.messageDetails !==
                                              "This message was deleted."
                                              ? { cursor: "pointer" }
                                              : {}
                                          }
                                          className={
                                            decryptResponse(
                                              message?.fromUserName,
                                              appLevelConstants.REACT_APP_SECRET_KEY
                                            ) ===
                                            decryptResponse(
                                              userData?.fullName,
                                              appLevelConstants.REACT_APP_SECRET_KEY
                                            )
                                              ? Styles["message-time-div-right"]
                                              : Styles["message-time-div-left"]
                                          }
                                          onMouseDown={
                                            userData.role === "Admin" &&
                                            message?.messageDetails !==
                                              "This message was deleted."
                                              ? () =>
                                                  handleMouseDown(
                                                    message?.messageId
                                                  )
                                              : ""
                                          }
                                          onMouseUp={
                                            userData.role === "Admin" &&
                                            message?.messageDetails !==
                                              "This message was deleted."
                                              ? () => handleMouseUp()
                                              : ""
                                          }
                                        >
                                          <div
                                            style={
                                              isSelected(message?.messageId)
                                                ? {
                                                    backgroundColor: "darkgrey",
                                                  }
                                                : {}
                                            }
                                            className={Styles["message-div"]}
                                          >
                                            <div
                                              className={Styles["user-name"]}
                                            >
                                              {decryptResponse(
                                                message?.fromUserName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              ) ===
                                              decryptResponse(
                                                userData?.fullName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              )
                                                ? "You"
                                                : decryptResponse(
                                                    message?.fromUserName,
                                                    appLevelConstants.REACT_APP_SECRET_KEY
                                                  )}
                                            </div>
                                            <div
                                              key={index}
                                              className={Styles["message"]}
                                            >
                                              {" "}
                                              {message?.messageDetails ===
                                                "This message was deleted." && (
                                                <img
                                                  alt=""
                                                  src={urls.blockIcon}
                                                  className={
                                                    Styles["block-icon"]
                                                  }
                                                />
                                              )}
                                              {message.messageDetails}
                                            </div>
                                          </div>

                                          <div
                                            className={Styles["time-date-div"]}
                                          >
                                            <div className={Styles["time"]}>
                                              {message.messageCreatedDate
                                                ? formatTimestampToTime(
                                                    message.messageCreatedDate
                                                  )
                                                : " "}
                                            </div>
                                            <div className={Styles["time"]}>
                                              {message.messageCreatedDate
                                                ? formatDatestampToDate(
                                                    message.messageCreatedDate
                                                  )
                                                : " "}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                              </>
                            </div>
                          </div>
                        ) : (
                          <div className={Styles["nodata-div"]}>
                            <img
                              alt=""
                              src={urls.noData}
                              className={Styles["nodata"]}
                            ></img>
                          </div>
                        )}

                        <div
                          className={
                            selectedChat?.communityStatus !== "Inactive"
                              ? Styles["text-box-div"]
                              : Styles["text-box-div-hidden"]
                          }
                        >
                          {isDropdownOpen && (
                            <div className={Styles["dropdown"]}>
                              <div
                                className={Styles["dropdown-option"]}
                                onClick={() => handleFileSelection("image/*")}
                              >
                                Photos
                              </div>
                              <div
                                className={Styles["dropdown-option"]}
                                onClick={() => handleFileSelection(".pdf")}
                              >
                                File
                              </div>
                            </div>
                          )}
                          <img
                            alt=""
                            className={Styles["attach-file"]}
                            src={urls.attachFileIcon}
                            onClick={toggleDropdown}
                          ></img>

                          <input
                            type="text"
                            className={Styles["text-box"]}
                            placeholder="Type a message"
                            value={messageText}
                            onFocus={(e) => (e.target.style.outline = "none")}
                            onChange={(e) => setMessageText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSendMessage(
                                  selectedChat?.communityId,
                                  messageText
                                );
                              }
                            }}
                          />
                          <img
                            alt=""
                            className={Styles["submit-arrow"]}
                            src={urls.rightArrow}
                            onClick={() =>
                              handleSendMessage(
                                selectedChat?.communityId,
                                messageText
                              )
                            }
                          ></img>
                        </div>
                      </>
                    )}

                    {previewOpen && (
                      <div className={Styles["preview-container"]}>
                        <div className={Styles["preview-head"]}>
                          <img
                            alt=""
                            className={Styles["close"]}
                            src={urls.closeIcon}
                            onClick={closePreview}
                          ></img>
                          <div className={Styles["img-name"]}>{fileName}</div>
                        </div>

                        <div className={Styles["preview-div"]}>
                          <img
                            src={
                              fileType.includes("pdf")
                                ? urls.document
                                : URL.createObjectURL(imageFile)
                            }
                            alt="Selected File"
                            className={Styles["prev-img"]}
                          />
                        </div>

                        <div className={Styles["text-box-div"]}>
                          <div className={Styles["submit-arrow-div"]}>
                            <img
                              alt=""
                              className={Styles["submit-arrow"]}
                              src={urls.rightArrow}
                              onClick={() =>
                                handleSentFile(selectedChat?.communityId)
                              }
                            ></img>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {selectedChat && isRightDivVisible && showUserListDiv && (
                <div className={Styles["user-list-div"]}>
                  <div className={Styles["user-div-header"]}>
                    <img
                      alt=""
                      src={urls.close}
                      className={Styles["close"]}
                      onClick={() => {
                        setShowUserListDiv(false);
                      }}
                    />
                    <div className={Styles["heading"]}>Community Info</div>
                  </div>
                  <div className={Styles["user-body"]}>
                    <div className={Styles["community-profile-div"]}>
                      <img
                        alt=""
                        src={
                          selectedChat?.communityIcon
                            ? selectedChat?.communityIcon
                            : urls.defaultGroupIcon
                        }
                        className={Styles["community-profile"]}
                      ></img>
                      <span className={Styles["community-name"]}>
                        {selectedChat?.communityName}
                      </span>
                      <span className={Styles["community-participants-count"]}>
                        {" "}
                        {
                          communityUsersList.filter(
                            (user) => user.userStatus !== "Delete"
                          ).length
                        }{" "}
                        participants
                      </span>
                    </div>
                    <div className={Styles["community-user-div"]}>
                      {communityUsersList?.map((item) => {
                        return (
                          <div
                            key={item?.id}
                            className={Styles["community-user-card"]}
                            style={
                              item?.userStatus === "Inactive"
                                ? { opacity: "0.5" }
                                : { opacity: "1" }
                            }
                          >
                            {item?.userStatus !== "Delete" ? (
                              <>
                                <img
                                  alt=""
                                  src={
                                    item?.profilePhoto
                                      ? item?.profilePhoto
                                      : urls.userIcon
                                  }
                                  className={Styles["user-profile"]}
                                ></img>
                                <div className={Styles["user-name-status"]}>
                                  <span className={Styles["user-name"]}>
                                    {decryptResponse(
                                      item?.userName,
                                      appLevelConstants.REACT_APP_SECRET_KEY
                                    )}
                                  </span>
                                  <span className={Styles["user-status"]}>
                                    {item?.onlineStatus === "true"
                                      ? "online"
                                      : "offline"}
                                  </span>
                                </div>
                              </>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {showLeftDiv ? (
                <div className={Styles["left-div"]}>
                  <div className={Styles["header"]}>
                    <span className={Styles["chats"]}>Chats</span>
                  </div>

                  <div className={Styles["search-bar-div"]}>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onFocus={(e) => (e.target.style.outline = "none")}
                      className={Styles["search-bar"]}
                      placeholder="Search or start a new chat"
                    />
                  </div>

                  <div className={Styles["chat-cards-container"]}>
                    <div className={Styles["chat-cards-div"]}>
                      {searchQuery === " " ? (
                        <>
                          {communityData?.map((item) => {
                            return (
                              <div
                                id={item?.communityId}
                                key={item?.communityId}
                                className={
                                  activeCardId === item?.communityId
                                    ? Styles["chat-card-active"]
                                    : Styles["chat-card"]
                                }
                                onClick={() =>
                                  handleCardClick(
                                    item?.communityId,
                                    item?.communityType,
                                    item?.communityName
                                  )
                                }
                              >
                                <img
                                  alt=""
                                  className={Styles["profile"]}
                                  src={
                                    item?.communityIcon
                                      ? item?.communityIcon
                                      : urls.defaultGroupIcon
                                  }
                                ></img>
                                <div className={Styles["profile-details"]}>
                                  <div className={Styles["name-time-div"]}>
                                    <div className={Styles["name"]}>
                                      {item?.communityName}{" "}
                                    </div>
                                    <div className={Styles["type"]}>
                                      {item?.communityType}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : filteredGroups.length > 0 ? (
                        <>
                          {filteredGroups.map((item) => {
                            return (
                              <div
                                id={item?.communityId}
                                key={item?.communityId}
                                className={
                                  activeCardId === item?.communityId
                                    ? Styles["chat-card-active"]
                                    : Styles["chat-card"]
                                }
                                onClick={() =>
                                  handleCardClick(
                                    item?.communityId,
                                    item?.communityType,
                                    item?.communityName
                                  )
                                }
                              >
                                <img
                                  alt=""
                                  className={Styles["profile"]}
                                  src={
                                    item?.communityIcon
                                      ? item?.communityIcon
                                      : urls.defaultGroupIcon
                                  }
                                ></img>
                                <div className={Styles["profile-details"]}>
                                  <div className={Styles["name-time-div"]}>
                                    <div className={Styles["name"]}>
                                      {item?.communityName}{" "}
                                    </div>
                                    <div className={Styles["type"]}>
                                      {item?.communityType}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className={Styles["group-not-found"]}>
                          Group not found
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={Styles["right-div"]}>
                    {selectedChat && isRightDivVisible && (
                      <>
                        <div className={Styles["header-div"]}>
                          <div className={Styles["profile-div"]}>
                            <img
                              alt=""
                              className={Styles["back-button"]}
                              src={urls.backIcon}
                              onClick={() => setShowLeftDiv(true)}
                            ></img>

                            <img
                              alt=""
                              className={Styles["profile"]}
                              src={
                                selectedChat?.communityIcon
                                  ? selectedChat?.communityIcon
                                  : urls.defaultGroupIcon
                              }
                            ></img>

                            <div className={Styles["name"]}>
                              {selectedChat?.communityName}{" "}
                            </div>
                          </div>

                          <div className={Styles["dots-delete-div"]}>
                            {selectedMessages.length !== 0 && (
                              <img
                                className={Styles["delete-icon"]}
                                alt=""
                                src={urls.deleteIcon}
                                onClick={() => setDeletePopUp(true)}
                              />
                            )}

                            <img
                              alt=""
                              src={urls.dots}
                              className={Styles["dots"]}
                              onClick={() => {
                                handleDropdownClick(selectedChat?.communityId);
                              }}
                            ></img>
                          </div>
                        </div>

                        {!previewOpen && (
                          <>
                            {loading ? (
                              <ThreeCircles
                                height="50"
                                width="50"
                                color="#F52929"
                                wrapperStyle={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "50vh",
                                }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                              />
                            ) : communityAllMsgs.length > 0 ? (
                              <div
                                ref={chatWindowRef}
                                className={Styles["chat-window-div"]}
                                onScroll={handleScroll}
                                style={
                                  selectedChat?.communityStatus !== "Inactive"
                                    ? { height: "50vh" }
                                    : { height: "60vh" }
                                }
                              >
                                <div className={Styles["chat-window"]}>
                                  {communityAllMsgs
                                    ?.filter(
                                      (e) => commId === currentCommID.current
                                    )
                                    .map((message, index) => (
                                      <>
                                        {message?.messageDetails &&
                                        appLevelConstants.imageExtensions.some(
                                          (extension) =>
                                            message?.messageDetails.includes(
                                              extension
                                            )
                                        ) ? (
                                          <div
                                            style={
                                              userData.role === "Admin"
                                                ? { cursor: "pointer" }
                                                : ""
                                            }
                                            className={
                                              decryptResponse(
                                                message?.fromUserName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              ) ===
                                              decryptResponse(
                                                userData?.fullName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              )
                                                ? Styles["img-time-div-right"]
                                                : Styles["img-time-div-left"]
                                            }
                                            onTouchStart={
                                              userData.role === "Admin"
                                                ? () =>
                                                    handleMouseDown(
                                                      message?.messageId
                                                    )
                                                : ""
                                            }
                                            onTouchEnd={
                                              userData.role === "Admin"
                                                ? () => handleMouseUp()
                                                : ""
                                            }
                                            onMouseDown={
                                              userData.role === "Admin"
                                                ? () =>
                                                    handleMouseDown(
                                                      message?.messageId
                                                    )
                                                : ""
                                            }
                                            onMouseUp={
                                              userData.role === "Admin"
                                                ? () => handleMouseUp()
                                                : ""
                                            }
                                          >
                                            <div
                                              className={Styles["img-div"]}
                                              style={
                                                isSelected(message?.messageId)
                                                  ? {
                                                      backgroundColor:
                                                        "darkgrey",
                                                    }
                                                  : {}
                                              }
                                            >
                                              <div
                                                className={Styles["user-name"]}
                                              >
                                                {decryptResponse(
                                                  message?.fromUserName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                ) ===
                                                decryptResponse(
                                                  userData?.fullName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                )
                                                  ? "You"
                                                  : decryptResponse(
                                                      message?.fromUserName,
                                                      appLevelConstants.REACT_APP_SECRET_KEY
                                                    )}
                                              </div>

                                              <a
                                                href={message?.messageDetails}
                                                download={
                                                  message?.messageDetails
                                                }
                                                className={
                                                  Styles["prev-img-div"]
                                                }
                                              >
                                                <img
                                                  src={message?.messageDetails}
                                                  alt="Selected File"
                                                  className={Styles["prev-img"]}
                                                ></img>
                                              </a>
                                            </div>
                                            <div
                                              className={
                                                Styles["time-date-div"]
                                              }
                                            >
                                              <div className={Styles["time"]}>
                                                {message?.messageCreatedDate
                                                  ? formatTimestampToTime(
                                                      message?.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                              <div className={Styles["time"]}>
                                                {message?.messageCreatedDate
                                                  ? formatDatestampToDate(
                                                      message?.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                            </div>
                                          </div>
                                        ) : message?.messageDetails &&
                                          message?.messageDetails.includes(
                                            ".pdf"
                                          ) ? (
                                          <div
                                            style={
                                              userData.role === "Admin"
                                                ? { cursor: "pointer" }
                                                : ""
                                            }
                                            className={
                                              decryptResponse(
                                                message?.fromUserName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              ) ===
                                              decryptResponse(
                                                userData?.fullName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              )
                                                ? Styles["pdf-time-div-right"]
                                                : Styles["pdf-time-div-left"]
                                            }
                                            onTouchStart={
                                              userData.role === "Admin"
                                                ? () =>
                                                    handleMouseDown(
                                                      message?.messageId
                                                    )
                                                : ""
                                            }
                                            onTouchEnd={
                                              userData.role === "Admin"
                                                ? () => handleMouseUp()
                                                : ""
                                            }
                                            onMouseDown={
                                              userData.role === "Admin"
                                                ? () =>
                                                    handleMouseDown(
                                                      message?.messageId
                                                    )
                                                : ""
                                            }
                                            onMouseUp={
                                              userData.role === "Admin"
                                                ? () => handleMouseUp()
                                                : ""
                                            }
                                          >
                                            <div
                                              className={Styles["pdf-div"]}
                                              style={
                                                isSelected(message?.messageId)
                                                  ? {
                                                      backgroundColor:
                                                        "darkgrey",
                                                    }
                                                  : {}
                                              }
                                            >
                                              <div
                                                className={Styles["user-name"]}
                                              >
                                                {decryptResponse(
                                                  message?.fromUserName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                ) ===
                                                decryptResponse(
                                                  userData?.fullName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                )
                                                  ? "You"
                                                  : decryptResponse(
                                                      message?.fromUserName,
                                                      appLevelConstants.REACT_APP_SECRET_KEY
                                                    )}
                                              </div>
                                              <a
                                                href={message?.messageDetails}
                                                download={fileName}
                                                className={Styles["pdf-name"]}
                                              >
                                                <img
                                                  src={urls.document}
                                                  alt="download"
                                                  className={Styles["document"]}
                                                ></img>{" "}
                                                PDF File
                                                <img
                                                  src={urls.downloadFile}
                                                  alt="download"
                                                  className={Styles["download"]}
                                                ></img>
                                              </a>
                                            </div>
                                            <div
                                              className={
                                                Styles["time-date-div"]
                                              }
                                            >
                                              <div className={Styles["time"]}>
                                                {message?.messageCreatedDate
                                                  ? formatTimestampToTime(
                                                      message?.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                              <div className={Styles["time"]}>
                                                {message?.messageCreatedDate
                                                  ? formatDatestampToDate(
                                                      message?.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                            </div>
                                          </div>
                                        ) : message?.messageDetails &&
                                          (message?.messageDetails.includes(
                                            "http:"
                                          ) ||
                                            message?.messageDetails.includes(
                                              "https:"
                                            ) ||
                                            message?.messageDetails.includes(
                                              ".com"
                                            )) ? (
                                          <>
                                            <div
                                              style={
                                                userData.role === "Admin"
                                                  ? { cursor: "pointer" }
                                                  : ""
                                              }
                                              className={
                                                decryptResponse(
                                                  message?.fromUserName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                ) ===
                                                decryptResponse(
                                                  userData?.fullName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                )
                                                  ? Styles[
                                                      "message-time-div-right"
                                                    ]
                                                  : Styles[
                                                      "message-time-div-left"
                                                    ]
                                              }
                                              onTouchStart={
                                                userData.role === "Admin"
                                                  ? () =>
                                                      handleMouseDown(
                                                        message?.messageId
                                                      )
                                                  : ""
                                              }
                                              onTouchEnd={
                                                userData.role === "Admin"
                                                  ? () => handleMouseUp()
                                                  : ""
                                              }
                                              onMouseDown={
                                                userData.role === "Admin"
                                                  ? () =>
                                                      handleMouseDown(
                                                        message?.messageId
                                                      )
                                                  : ""
                                              }
                                              onMouseUp={
                                                userData.role === "Admin"
                                                  ? () => handleMouseUp()
                                                  : ""
                                              }
                                            >
                                              <div
                                                className={
                                                  Styles["message-div"]
                                                }
                                                style={
                                                  isSelected(message?.messageId)
                                                    ? {
                                                        backgroundColor:
                                                          "darkgrey",
                                                      }
                                                    : {}
                                                }
                                              >
                                                <div
                                                  className={
                                                    Styles["user-name"]
                                                  }
                                                >
                                                  {decryptResponse(
                                                    message?.fromUserName,
                                                    appLevelConstants.REACT_APP_SECRET_KEY
                                                  ) ===
                                                  decryptResponse(
                                                    userData?.fullName,
                                                    appLevelConstants.REACT_APP_SECRET_KEY
                                                  )
                                                    ? "You"
                                                    : decryptResponse(
                                                        message?.fromUserName,
                                                        appLevelConstants.REACT_APP_SECRET_KEY
                                                      )}
                                                </div>
                                                <a
                                                  key={index}
                                                  className={Styles["message"]}
                                                  href={message?.messageDetails}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {message?.messageDetails}
                                                </a>
                                              </div>

                                              <div
                                                className={
                                                  Styles["time-date-div"]
                                                }
                                              >
                                                <div className={Styles["time"]}>
                                                  {message?.messageCreatedDate
                                                    ? formatTimestampToTime(
                                                        message?.messageCreatedDate
                                                      )
                                                    : " "}
                                                </div>
                                                <div className={Styles["time"]}>
                                                  {message?.messageCreatedDate
                                                    ? formatDatestampToDate(
                                                        message?.messageCreatedDate
                                                      )
                                                    : " "}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            style={
                                              userData.role === "Admin" &&
                                              message?.messageDetails !==
                                                "This message was deleted."
                                                ? { cursor: "pointer" }
                                                : {}
                                            }
                                            className={
                                              decryptResponse(
                                                message?.fromUserName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              ) ===
                                              decryptResponse(
                                                userData?.fullName,
                                                appLevelConstants.REACT_APP_SECRET_KEY
                                              )
                                                ? Styles[
                                                    "message-time-div-right"
                                                  ]
                                                : Styles[
                                                    "message-time-div-left"
                                                  ]
                                            }
                                            onTouchStart={
                                              userData.role === "Admin" &&
                                              message?.messageDetails !==
                                                "This message was deleted."
                                                ? () =>
                                                    handleMouseDown(
                                                      message?.messageId
                                                    )
                                                : ""
                                            }
                                            onTouchEnd={
                                              userData.role === "Admin" &&
                                              message?.messageDetails !==
                                                "This message was deleted."
                                                ? () => handleMouseUp()
                                                : ""
                                            }
                                            onMouseDown={
                                              userData.role === "Admin" &&
                                              message?.messageDetails !==
                                                "This message was deleted."
                                                ? () =>
                                                    handleMouseDown(
                                                      message?.messageId
                                                    )
                                                : ""
                                            }
                                            onMouseUp={
                                              userData.role === "Admin" &&
                                              message?.messageDetails !==
                                                "This message was deleted."
                                                ? () => handleMouseUp()
                                                : ""
                                            }
                                          >
                                            <div
                                              className={Styles["message-div"]}
                                              style={
                                                isSelected(message?.messageId)
                                                  ? {
                                                      backgroundColor:
                                                        "darkgrey",
                                                    }
                                                  : {}
                                              }
                                            >
                                              <div
                                                className={Styles["user-name"]}
                                              >
                                                {decryptResponse(
                                                  message?.fromUserName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                ) ===
                                                decryptResponse(
                                                  userData?.fullName,
                                                  appLevelConstants.REACT_APP_SECRET_KEY
                                                )
                                                  ? "You"
                                                  : decryptResponse(
                                                      message?.fromUserName,
                                                      appLevelConstants.REACT_APP_SECRET_KEY
                                                    )}
                                              </div>
                                              <div
                                                key={index}
                                                className={Styles["message"]}
                                              >
                                                {message?.messageDetails ===
                                                  "This message was deleted." && (
                                                  <img
                                                    alt=""
                                                    src={urls.blockIcon}
                                                    className={
                                                      Styles["block-icon"]
                                                    }
                                                  />
                                                )}
                                                {message?.messageDetails}
                                              </div>
                                            </div>

                                            <div
                                              className={
                                                Styles["time-date-div"]
                                              }
                                            >
                                              <div className={Styles["time"]}>
                                                {message?.messageCreatedDate
                                                  ? formatTimestampToTime(
                                                      message?.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                              <div className={Styles["time"]}>
                                                {message?.messageCreatedDate
                                                  ? formatDatestampToDate(
                                                      message?.messageCreatedDate
                                                    )
                                                  : " "}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ))}
                                </div>
                              </div>
                            ) : (
                              <div className={Styles["no-data"]}>
                                <img
                                  alt=""
                                  src={urls.noData}
                                  className={Styles["no-data-image"]}
                                ></img>
                              </div>
                            )}

                            <div
                              className={
                                selectedChat?.communityStatus !== "Inactive"
                                  ? Styles["text-box-div"]
                                  : Styles["text-box-div-hidden"]
                              }
                            >
                              {isDropdownOpen && (
                                <div className={Styles["dropdown"]}>
                                  <div
                                    className={Styles["dropdown-option"]}
                                    onClick={() =>
                                      handleFileSelection("image/*")
                                    }
                                  >
                                    Photos
                                  </div>
                                  <div
                                    className={Styles["dropdown-option"]}
                                    onClick={() => handleFileSelection(".pdf")}
                                  >
                                    File
                                  </div>
                                </div>
                              )}
                              <img
                                alt=""
                                className={Styles["attach-file"]}
                                src={urls.attachFileIcon}
                                onClick={toggleDropdown}
                              ></img>

                              <input
                                type="text"
                                className={Styles["text-box"]}
                                placeholder="Type a message"
                                value={messageText}
                                onFocus={(e) =>
                                  (e.target.style.outline = "none")
                                }
                                onChange={(e) => setMessageText(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSendMessage(
                                      selectedChat?.communityId,
                                      messageText
                                    );
                                  }
                                }}
                              />
                              <img
                                alt=""
                                className={Styles["submit-arrow"]}
                                src={urls.rightArrow}
                                onClick={() =>
                                  handleSendMessage(
                                    selectedChat?.communityId,
                                    messageText
                                  )
                                }
                              ></img>
                            </div>
                          </>
                        )}

                        {previewOpen && (
                          <div className={Styles["preview-container"]}>
                            <div className={Styles["preview-head"]}>
                              <img
                                alt=""
                                className={Styles["close"]}
                                src={urls.closeIcon}
                                onClick={closePreview}
                              ></img>
                              <div className={Styles["img-name"]}>
                                {fileName}
                              </div>
                            </div>

                            <div className={Styles["preview-div"]}>
                              <img
                                src={
                                  fileType.includes("pdf")
                                    ? urls.document
                                    : URL.createObjectURL(imageFile)
                                }
                                alt="Selected File"
                                className={Styles["prev-img"]}
                              />
                            </div>

                            <div className={Styles["text-box-div"]}>
                              <div className={Styles["submit-arrow-div"]}>
                                <img
                                  alt=""
                                  className={Styles["submit-arrow"]}
                                  src={urls.rightArrow}
                                  onClick={() =>
                                    handleSentFile(selectedChat?.communityId)
                                  }
                                ></img>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {selectedChat && isRightDivVisible && showUserListDiv && (
                    <div className={Styles["user-list-div"]}>
                      <div className={Styles["user-div-header"]}>
                        <img
                          alt=""
                          src={urls.closeIcon}
                          className={Styles["close"]}
                          onClick={() => {
                            setShowUserListDiv(false);
                          }}
                        />
                        <div className={Styles["heading"]}>Community Info</div>
                      </div>
                      <div className={Styles["user-body"]}>
                        <div className={Styles["community-profile-div"]}>
                          <img
                            alt=""
                            src={
                              selectedChat?.communityIcon
                                ? selectedChat?.communityIcon
                                : urls.defaultGroupIcon
                            }
                            className={Styles["community-profile"]}
                          ></img>
                          <span className={Styles["community-name"]}>
                            {selectedChat?.communityName}
                          </span>
                          <span
                            className={Styles["community-participants-count"]}
                          >
                            {" "}
                            {
                              communityUsersList.filter(
                                (user) => user.userStatus !== "Delete"
                              ).length
                            }{" "}
                            participants
                          </span>
                        </div>
                        <div className={Styles["community-user-div"]}>
                          {communityUsersList?.map((item) => {
                            return (
                              <div
                                key={item?.id}
                                className={Styles["community-user-card"]}
                                style={
                                  item?.userStatus === "Inactive"
                                    ? { opacity: "0.5" }
                                    : { opacity: "1" }
                                }
                              >
                                {item?.userStatus !== "Delete" ? (
                                  <>
                                    <img
                                      alt=""
                                      src={
                                        item?.profilePhoto
                                          ? item?.profilePhoto
                                          : urls.userIcon
                                      }
                                      className={Styles["user-profile"]}
                                    ></img>
                                    <div className={Styles["user-name-status"]}>
                                      <span className={Styles["user-name"]}>
                                        {decryptResponse(
                                          item?.userName,
                                          appLevelConstants.REACT_APP_SECRET_KEY
                                        )}
                                      </span>
                                      <span className={Styles["user-status"]}>
                                        {item?.onlineStatus === "true"
                                          ? "online"
                                          : "offline"}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {messageDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={messageDialogOpen}
            onClose={messageDialogClose}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Sorry.. You are not member of this community."
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Speak To Us"
                  className={"button-job-detail-page"}
                  linkTo="action"
                  callback={handleSpeakToUsDialog}
                />
                <ButtonWithLinks
                  name="Close"
                  className={"button-job-detail-page"}
                  linkTo={"action"}
                  callback={messageDialogClose}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {publicMessageDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={publicMessageDialogOpen}
            onClose={() => setPublicMessageDialogOpen(false)}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
            To access public community, you must be a part of atleast one private community.
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Speak To Us"
                  className={"button-job-detail-page"}
                  linkTo="action"
                  callback={handleSpeakToUsDialog}
                />
                <ButtonWithLinks
                  name="Close"
                  className={"button-job-detail-page"}
                  linkTo={"action"}
                  callback={() => setPublicMessageDialogOpen(false)}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {speakToUsDialogOpen && (
        <GetInTouch
          setDialogOpen={setSpeakToUsDialogOpen}
          open={speakToUsDialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}

      <ToastContainer
        limit={1}
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId={"getInTouch"}
        enableMultiContainer
      />

      {userValidationDialogOpen && (
        <UserValidation
          open={userValidationDialogOpen}
          setDialogOpen={setUserValidationDialogOpen}
          onResponse={handleUserValidationResponse}
        ></UserValidation>
      )}

      {otpDialogOpen && (
        <OtpValidation
          open={otpDialogOpen}
          setOtpDialogOpen={setOtpDialogOpen}
          message={message}
          setMessage={setMessage}
          otpId={otpId}
          statusCode={statusCode}
          onResponseUserData={handleOtpVerificationUserDataResponse}
        ></OtpValidation>
      )}

      {alertDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={alertDialogOpen}
          >
            <FormLabel className={Styles["form-label"]}>
              Error: File Size
            </FormLabel>
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "The file size of image / pdf should not exceed more than 2MB"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={() => setAlertDialogOpen(false)}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {deletePopUp && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog className={Styles["dialog-content-div"]} open={deletePopUp}>
            <FormLabel className={Styles["form-label"]}>
              Confirm Deletion
            </FormLabel>
            <DialogTitle className={Styles["dialog-title-msg"]}>
              {selectedMessages.length > 1
                ? "Are you sure you want to delete these messages?"
                : "Are you sure you want to delete this message?"}
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Cancel"
                  className={"button"}
                  linkTo="action"
                  callback={() => setDeletePopUp(false)}
                />
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={() =>
                    handleDelete(selectedChat?.communityId, selectedMessages[0])
                  }
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}
    </>
  );
}
