import React, { useState, useEffect } from "react";
import Styles from "./CouponValidation.module.scss";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { appLevelConstants } from "../../../../constants/AppLevelConstants";
import ButtonWithLink from "../../../buttons/ButtonWithLink";
import DialogActions from "@mui/material/DialogActions";
import { capitalizeFirstLetter } from "../../../../helper-fuction/helper";
// import PaymentRequestButton from "react-payment-request-api";

import {
  registerProgram,
  validateCoupon,
} from "../../../../service/buyCourseService";
import {
  decryptProgramResponse,
  getBatchDetails,
} from "../../../../service/formService";
import {
  encryptURL,
  validateEmail,
  validatePhoneNumber,
} from "../../../..//helper-fuction/helper";
import {
  decryptResponse,
  removeCommaFromDate,
} from "../../../..//helper-fuction/helper";
import { ThreeCircles } from "react-loader-spinner";
import ButtonWithLinks from "../../../buttons/ButtonWithLink";
// import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { getSelectedBatch } from "../../../../service/formService";

export default function CouponValidation() {
  // let navigate = useNavigate();
  // const [paymentResponse, setPaymentResponse] = useState(null);

  // const paymentMethods = [
  //   {
  //     supportedMethods: "https://apple.com/apple-pay",
  //     data: {
  //       version: 6,
  //       merchantIdentifier: "your_merchant_id",
  //       supportedNetworks: ["visa", "mastercard"],
  //       countryCode: "IN",
  //       currencyCode: "INR",
  //     },
  //   },
  // ];

  // const paymentDetails = {
  //   total: {
  //     label: "Total",
  //     amount: {
  //       currency: "INR",
  //       value: "10.00",
  //     },
  //   },
  // };

  // const handlePaymentResponse = async (response) => {
  //   console.log("in handle pay");
  //   try {
  //     const paymentIntent = await response.complete("success");
  //     setPaymentResponse(paymentIntent);
  //   } catch (error) {
  //     console.error(error, "error");
  //   }
  // };

  const { programData } = useParams();
  const [formState, setFormState] = useState({
    selectedBatch: "12/11/2023 - 15/11/2023",
  });
  const [errors, setErrors] = useState({});
  const [apply, setApply] = useState(false);
  const [discount, setDiscout] = useState();
  const [amount, setAmount] = useState();
  const [initialAmount, setInitialAmount] = useState();
  const [fullAmount, setFullAmount] = useState();
  const [discountErorrMessage, setDiscountErorrMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [decryptData, setDecryptData] = useState({});
  const [voucherData, setVoucherData] = useState({});
  const [batchData, setBatchData] = useState({});
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [reqFailedDialogOpen, setReqFailedDialogOpen] = useState(false);
  const [batchSubmitLoader, setBatchSubmitLoader] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [reqFailedMessage, setReqFailedMessage] = useState("");
  const leadSource = capitalizeFirstLetter(
    localStorage.getItem("leadSourceName")
  );

  useEffect(() => {
    let data = {
      cipherText: programData,
    };
    decryptProgramData(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const decryptProgramData = async (programData) => {
    try {
      const response = await decryptProgramResponse(programData);
      setDecryptData(response?.data?.data);
      setInitialAmount(response?.data?.data?.programPrice);
      setAmount(response?.data?.data?.programPrice);
      setFullAmount(response?.data?.data?.fullPayment);
      setFormState({
        ...formState,
        program: response?.data?.data?.programName,
        fullName: response?.data?.data?.fullName,
        email: response?.data?.data?.email,
        mobile: response?.data?.data?.mobile,
        selectedBatch: response?.data?.data?.batchDetails,
      });
      if (response?.data?.data?.formType !== "Program Registration") {
        setFormState({
          ...formState,
          program: response?.data?.data?.programName,
          fullName: response?.data?.data?.fullName,
          email: response?.data?.data?.email,
          mobile: response?.data?.data?.mobile,
          selectedBatch: response?.data?.data?.batchDetails,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    setDiscountErorrMessage(discountErorrMessage);
  }, [discountErorrMessage]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormState({ ...formState, [name]: newValue });
    if (type === "checkbox") {
      setErrors({ ...errors, [name]: false });
    }
    setErrors({ ...errors, [name]: false });
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  //   return emailRegex.test(email);
  // };

  // const validatePhoneNumber = (mobile) => {
  //   const mobileRegex = /^\d{10,}$/;
  //   return mobileRegex.test(mobile);
  // }

  const handleSubmit = (val) => {
    const { email, mobile, fullName, selectedBatch, isCheckboxChecked } =
      formState;
    const isValidEmail = validateEmail(email);
    const isValidMobile = validatePhoneNumber(mobile);

    setErrors({
      email: !validateEmail(email),
      mobile: !validatePhoneNumber(mobile),
      fullName: !fullName,
      selectedBatch: !selectedBatch,
      isCheckboxChecked: !isCheckboxChecked,
    });
    if (isValidEmail && isValidMobile && fullName && isCheckboxChecked) {
      if (val === "apply") {
        let payload = {
          voucherCode: formState.discount,
        };

        validateCouponCode(payload);
        setLoading(true);
      } else {
        if (decryptData?.formType === "Program Registration") {
          let payload = {
            env: "production",
            purpose: formState.program,
            amount: amount,
            buyersName: formState.fullName,
            phone: formState.mobile,
            email: formState.email,
            redirectURL: `https://iamironlady.com/thankYou/${formState.program?.replace(
              /\s/g,
              "_"
            )}/${decryptData?.formType?.replace(/\s/g, "_")}`,
            webhookURL: `https://flow.zoho.in/60021647749/flow/webhook/incoming?zapikey=1001.f9577b19500cd9fa057bdb285a127621.5a2f2e6aa3375d49298fcf162940c882&isdebug=false&fullname=${replace(
              formState.fullName
            )}&email=${formState.email}&phone=${replace(
              formState.mobile
            )}&program=${replace(formState.program)}&formStatus=${replace(
              "Program Registration"
            )}&emiStatus=NA&leadSource=${replace(leadSource)}&couponCode=${
              voucherData?.voucherCode === undefined
                ? ""
                : voucherData?.voucherCode
            }&discountPercentage=${
              voucherData?.voucherDiscountPercentage === undefined
                ? ""
                : voucherData?.voucherDiscountPercentage
            }`,
          };
          console.log(JSON.stringify(payload), "payload");
          let data = {
            payload: encryptURL(JSON.stringify(payload)),
          };
          registerPrograms(data);
        } else if (decryptData?.formType === "EMI - Partial") {
          let payload = {
            env: "production",
            purpose: formState.program,
            amount: amount,
            buyersName: formState.fullName,
            phone: formState.mobile,
            email: formState.email,
            redirectURL: `https://iamironlady.com/thankYou/${formState.program?.replace(
              /\s/g,
              "_"
            )}/${decryptData?.formType?.replace(/\s/g, "_")}`,
            webhookURL: `https://flow.zoho.in/60021647749/flow/webhook/incoming?zapikey=1001.4440a8bf380fade419dc0872147586ea.876ca14ccb47ceb93467b849f35c2fc8&isdebug=false&fullname=${replace(
              formState.fullName
            )}&email=${formState.email}&batch=${replace(
              formState.selectedBatch
            )}&phone=${replace(formState.mobile)}&program=${replace(
              formState.program
            )}&formStatus=${replace(
              "EMI - Partial"
            )}&emiStatus=NA&leadSource=${replace(leadSource)}&couponCode=${
              voucherData?.voucherCode === undefined
                ? ""
                : voucherData?.voucherCode
            }&discountPercentage=${
              voucherData?.voucherDiscountPercentage === undefined
                ? ""
                : voucherData?.voucherDiscountPercentage
            }`,
          };
          console.log(JSON.stringify(payload), "payload");
          let data = {
            payload: encryptURL(JSON.stringify(payload)),
          };
          registerPrograms(data);
        } else if (decryptData?.formType === "EMI - Complete") {
          let payload = {
            env: "production",
            purpose: formState.program,
            amount: amount,
            buyersName: formState.fullName,
            phone: formState.mobile,
            email: formState.email,
            redirectURL: `https://iamironlady.com/thankYou/${formState.program?.replace(
              /\s/g,
              "_"
            )}/${decryptData?.formType?.replace(/\s/g, "_")}`,
            webhookURL: `https://flow.zoho.in/60021647749/flow/webhook/incoming?zapikey=1001.4440a8bf380fade419dc0872147586ea.876ca14ccb47ceb93467b849f35c2fc8&isdebug=false&fullname=${replace(
              formState.fullName
            )}&email=${formState.email}&batch=${replace(
              formState.selectedBatch
            )}&phone=${replace(formState.mobile)}&program=${replace(
              formState.program
            )}&formStatus=${replace(
              "EMI - Complete"
            )}&emiStatus=NA&leadSource=${replace(leadSource)}&couponCode=${
              voucherData?.voucherCode === undefined
                ? ""
                : voucherData?.voucherCode
            }&discountPercentage=${
              voucherData?.voucherDiscountPercentage === undefined
                ? ""
                : voucherData?.voucherDiscountPercentage
            }`,
          };
          console.log(JSON.stringify(payload), "payload");
          let data = {
            payload: encryptURL(JSON.stringify(payload)),
          };
          registerPrograms(data);
        } else if (decryptData?.formType === "Batch Selection") {
          setBatchSubmitLoader(true);
          const webhookURL = `https://www.zohoapis.in/crm/v2/functions/update_batch_in_il_registration/actions/execute?auth_type=apikey&zapikey=1003.fc9035d274089c149e601eaab0f7bb2c.42d592278f3009bdbe4ac29ee38008f5&fullname=${replace(
            formState.fullName
          )}&email=${formState.email}&batch=${replace(
            formState.selectedBatch
          )}&program=${replace(formState.program)}`;
          let encryptedUrl = encryptURL(webhookURL);
          submitBatchSelection(encryptedUrl);
        } else {
          let payload = {
            env: "production",
            purpose: formState.program,
            amount: amount,
            buyersName: formState.fullName,
            phone: formState.mobile,
            email: formState.email,
            redirectURL: `https://iamironlady.com/thankYou/${formState.program?.replace(
              /\s/g,
              "_"
            )}/${decryptData?.formType?.replace(/\s/g, "_")}`,
            webhookURL: `https://flow.zoho.in/60021647749/flow/webhook/incoming?zapikey=1001.b9e2bd9e5af07e27cf7e518c40737199.b7fa6cc75ce13ec0334d81bc2b707afe&isdebug=false&fullname=${replace(
              formState.fullName
            )}&email=${formState.email}&batch=${replace(
              formState.selectedBatch
            )}&phone=${replace(formState.mobile)}&program=${replace(
              formState.program
            )}&formStatus=${replace(
              "Program Enrollment"
            )}&emiStatus=NA&leadSource=${replace(leadSource)}&couponCode=${
              voucherData?.voucherCode === undefined
                ? ""
                : voucherData?.voucherCode
            }&discountPercentage=${
              voucherData?.voucherDiscountPercentage === undefined
                ? ""
                : voucherData?.voucherDiscountPercentage
            }`,
          };
          console.log(JSON.stringify(payload), "payload");
          let data = {
            payload: encryptURL(JSON.stringify(payload)),
          };
          registerPrograms(data);
        }
      }
    }
  };

  const validateCouponCode = async (payload) => {
    try {
      const response = await validateCoupon(payload);
      if (response?.statusCode === 200) {
        console.log(response);
        setVoucherData(response?.data);
        setDiscountErorrMessage(response?.message);
        setAmount(
          amount - (amount * response?.data?.voucherDiscountPercentage) / 100
        );
        setApply(true);
        setDiscout(true);
        setLoading(false);
      } else if (response?.statusCode === 702) {
        setDiscountErorrMessage(response?.message);
        setApply(false);
        setDiscout(false);
        setLoading(false);
      } else if (response?.statusCode === 701) {
        setDiscountErorrMessage(response?.message);
        setApply(false);
        setDiscout(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "error++");
    }
  };

  const registerPrograms = async (payload) => {
    try {
      const response = await registerProgram(payload);
      console.log(response, "response in coupon code");
      if (response?.statusCode === 200) {
        const decryptedResponse = decryptResponse(
          response?.data,
          appLevelConstants.REACT_APP_SECRET_KEY
        );
        window.location.href = JSON.parse(decryptedResponse)?.paymentURL;
        // window.open(JSON.parse(decryptedResponse)?.paymentURL);
        // handleClose();
        // navigate(-2);
      } else {
        setReqFailedMessage(Object.values(response.message)[0][0]);
        setReqFailedDialogOpen(true);
      }
    } catch (error) {
      console.log(error, "error++");
    }
  };

  const replace = (text) => {
    return text?.replace(/\s/g, "%20");
  };
  const invalid = { color: "#F52929" };

  const applyButtonStyle = {
    marginTop: "10px",
    textTransform: "none",
    backgroundColor: "black",
    boxShadow: "none",
    borderRadius: "0px",
    minWidth: "100px",
    fontFamily: "Gemunu Libre",
    "&:hover": {
      color: "#F52929",
      backgroundColor: "black",
    },
  };

  // const handleClose = () => {
  //   setFormState({});
  //   setErrors({});
  //   setDiscountErorrMessage("");
  //   setDiscout();
  //   setApply(false);
  //   setFormState({});
  // };

  const clearCouponCode = () => {
    setDiscout();
    setApply(false);
    setFormState({ ...formState, discount: "" });
    setAmount(decryptData?.programPrice);
  };

  // const goback = (val) => {
  //   handleClose();
  //   navigate(-val);
  // };

  // const goBackAction = () => {
  //   window.history.back();
  // };

  useEffect(() => {
    handleBatchData();
  }, []);

  const handleBatchData = async () => {
    try {
      const response = await getBatchDetails();
      setBatchData(response.data.data);
    } catch (error) {
      throw error;
    }
  };

  const submitBatchSelection = async (url) => {
    try {
      await getSelectedBatch(url);
      setMessageDialogOpen(true);
      setBatchSubmitLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={Styles["coupon-validation-container"]}>
        {/* <div className={Styles["back-button-container"]}>
          <ButtonWithLinks
            name="Back"
            linkTo="action"
            className={"button"}
            callback={() => goback(1)}
            // callback={() => goBackAction()}
            id="back_coupon_validation"
          />
        </div> */}

        <div className={Styles["dialog-title"]}>
          {/* {decryptData?.formType !== "Program Registration"
          ? "Enrollment Form"
          : "Registration Form"} */}
          {decryptData?.formType === "Batch Selection"
            ? "Batch Selection Form"
            : decryptData?.formType === "Program Registration"
            ? "Registration Form"
            : "Enrollment Form"}
        </div>

        <div className={Styles["main-container"]}>
          <TextField
            label={
              formState?.fullName !== undefined ? "" : "Full Name"
              // decryptData?.formType !== "Program Registration" ? "" : "Full Name"
            }
            name="fullName"
            value={formState.fullName}
            onChange={handleInputChange}
            variant="outlined"
            margin="normal"
            fullWidth
            sx={{ marginTop: "10px", marginBottom: "0px" }}
            helperText={
              errors.fullName && (
                <span style={invalid}>Please enter your full name</span>
              )
            }
            disabled={
              decryptData?.formType !== "Program Registration" ? true : false
            }
          />
          <TextField
            label={
              formState?.email !== undefined ? "" : "Email"
              // decryptData?.formType !== "Program Registration" ? "" : "Email"
            }
            name="email"
            value={formState.email}
            onChange={handleInputChange}
            variant="outlined"
            margin="normal"
            fullWidth
            sx={{ marginTop: "10px", marginBottom: "0px" }}
            helperText={
              errors.email && (
                <span style={invalid}>Please enter a valid email</span>
              )
            }
            disabled={
              decryptData?.formType !== "Program Registration" ? true : false
            }
          />
          {decryptData?.formType !== "Batch Selection" && (
            <TextField
              label={formState?.mobile !== undefined ? "" : "Phone"}
              name="mobile"
              value={formState.mobile}
              onChange={handleInputChange}
              variant="outlined"
              margin="normal"
              fullWidth
              sx={{ marginTop: "10px", marginBottom: "0px" }}
              helperText={
                errors.mobile && (
                  <span style={invalid}>Please enter a valid phone number</span>
                )
              }
              disabled={
                decryptData?.formType !== "Program Registration" ? true : false
              }
            />
          )}
          <TextField
            label="Program"
            name="program"
            value={`${formState?.program}`}
            onChange={handleInputChange}
            variant="outlined"
            margin="normal"
            fullWidth
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            disabled={true}
          />

          {decryptData?.formType !== "Program Registration" &&
            decryptData?.formType !== "EMI - Complete" && (
              <>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  margin="normal"
                  sx={{ fontWeight: "bold", color: "black", marginTop: "10px" }}
                >
                  Select Batch
                </FormLabel>

                {/* <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  id="selectedBatch"
                  name="selectedBatch"
                  // value="15/09/2023 - 16/09/2023"
                  value={formState.selectedBatch}
                  onChange={handleInputChange}
                  required
                  helperText={
                    errors.selectedBatch && (
                      <span style={invalid}>Please select batch</span>
                    )
                  }
                >
                  <FormControlLabel
                    value={
                      decryptData?.programName ===
                      "Leadership Essentials Program"
                        ? batchData?.LEP?.[0]
                        : decryptData?.programName ===
                          "100 Board Members Program"
                        ? removeCommaFromDate(batchData?.BM_100?.[0])
                        : decryptData?.programName ===
                          "Master of Business Warfare"
                        ? removeCommaFromDate(batchData?.MBW?.[0])
                        : " "
                    }
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label={
                      decryptData?.programName ===
                      "Leadership Essentials Program"
                        ? batchData?.LEP?.[0]
                        : decryptData?.programName ===
                          "100 Board Members Program"
                        ? removeCommaFromDate(batchData?.BM_100?.[0])
                        : decryptData?.programName ===
                          "Master of Business Warfare"
                        ? removeCommaFromDate(batchData?.MBW?.[0])
                        : " "
                    }
                    className={Styles["radio-button"]}
                  />
                  <FormControlLabel
                    value={
                      decryptData?.programName ===
                      "Leadership Essentials Program"
                        ? batchData?.LEP?.[1]
                        : decryptData?.programName ===
                          "100 Board Members Program"
                        ? removeCommaFromDate(batchData?.BM_100?.[1])
                        : decryptData?.programName ===
                          "Master of Business Warfare"
                        ? removeCommaFromDate(batchData?.MBW?.[1])
                        : " "
                    }
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label={
                      decryptData?.programName ===
                      "Leadership Essentials Program"
                        ? batchData?.LEP?.[1]
                        : decryptData?.programName ===
                          "100 Board Members Program"
                        ? removeCommaFromDate(batchData?.BM_100?.[1])
                        : decryptData?.programName ===
                          "Master of Business Warfare"
                        ? removeCommaFromDate(batchData?.MBW?.[1])
                        : " "
                    }
                  />
                  <FormControlLabel
                    value={
                      decryptData?.programName ===
                      "Leadership Essentials Program"
                        ? batchData?.LEP?.[2]
                        : decryptData?.programName ===
                          "100 Board Members Program"
                        ? removeCommaFromDate(batchData?.BM_100?.[2])
                        : decryptData?.programName ===
                          "Master of Business Warfare"
                        ? removeCommaFromDate(batchData?.MBW?.[2])
                        : " "
                    }
                    control={
                      <Radio
                        sx={{
                          color: "#F52929",
                          "&.Mui-checked": {
                            color: "#F52929",
                          },
                        }}
                      />
                    }
                    label={
                      decryptData?.programName ===
                      "Leadership Essentials Program"
                        ? batchData?.LEP?.[2]
                        : decryptData?.programName ===
                          "100 Board Members Program"
                        ? removeCommaFromDate(batchData?.BM_100?.[2])
                        : decryptData?.programName ===
                          "Master of Business Warfare"
                        ? removeCommaFromDate(batchData?.MBW?.[2])
                        : " "
                    }
                    helperText={
                      errors.selectedBatch && (
                        <span style={invalid}>Please select batch</span>
                      )
                    }
                  />
                </RadioGroup> */}

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  id="selectedBatch"
                  name="selectedBatch"
                  value={formState.selectedBatch}
                  onChange={handleInputChange}
                  required
                  helperText={
                    errors.selectedBatch && (
                      <span style={invalid}>Please select batch</span>
                    )
                  }
                >
                  {(() => {
                    switch (decryptData?.programName) {
                      case "Leadership Essentials Program":
                        return batchData?.LEP?.map((batch, index) => (
                          <FormControlLabel
                            key={index}
                            value={removeCommaFromDate(batch)}
                            control={
                              <Radio
                                sx={{
                                  color: "#F52929",
                                  "&.Mui-checked": {
                                    color: "#F52929",
                                  },
                                }}
                              />
                            }
                            label={removeCommaFromDate(batch)}
                            className={Styles["radio-button"]}
                          />
                        ));
                      case "100 Board Members Program":
                        return batchData?.BM_100?.map((batch, index) => (
                          <FormControlLabel
                            key={index}
                            value={removeCommaFromDate(batch)}
                            control={
                              <Radio
                                sx={{
                                  color: "#F52929",
                                  "&.Mui-checked": {
                                    color: "#F52929",
                                  },
                                }}
                              />
                            }
                            label={removeCommaFromDate(batch)}
                            className={Styles["radio-button"]}
                          />
                        ));
                      case "Master of Business Warfare":
                        return batchData?.MBW?.map((batch, index) => (
                          <FormControlLabel
                            key={index}
                            value={removeCommaFromDate(batch)}
                            control={
                              <Radio
                                sx={{
                                  color: "#F52929",
                                  "&.Mui-checked": {
                                    color: "#F52929",
                                  },
                                }}
                              />
                            }
                            label={removeCommaFromDate(batch)}
                            className={Styles["radio-button"]}
                          />
                        ));
                      default:
                        return null;
                    }
                  })()}
                </RadioGroup>

                {errors.selectedBatch && (
                  <span style={invalid}>Please select batch</span>
                )}
              </>
            )}

          {decryptData?.formType === "Program Enrollment" ? (
            <div className={Styles["input-container"]}>
              <TextField
                label="Discout Coupon"
                name="discount"
                value={formState.discount}
                onChange={handleInputChange}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={apply}
                sx={{
                  marginTop: "10px",
                  marginBottom: "0px",
                }}
              />
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "100px",
                  }}
                >
                  <ThreeCircles
                    height="20"
                    width="20"
                    color="#F52929"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                  />
                </div>
              ) : (
                <Button
                  id="apply_coupon_validation"
                  variant="contained"
                  color="primary"
                  sx={applyButtonStyle}
                  onClick={() =>
                    apply ? clearCouponCode() : handleSubmit("apply")
                  }
                >
                  {apply ? "Clear" : "Apply"}
                </Button>
              )}
            </div>
          ) : (
            ""
          )}
          {discount === false ? (
            <span
              style={{
                color: "#F52929",
                fontWeight: "400",
                fontSize: "0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: "0",
                marginLeft: "14px",
              }}
            >
              {discountErorrMessage}
            </span>
          ) : discount === true ? (
            <span
              style={{
                color: "green",
                fontWeight: "400",
                fontSize: "0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: "0",
                marginLeft: "14px",
              }}
            >
              {discountErorrMessage}
            </span>
          ) : (
            ""
          )}

          {decryptData?.formType !== "Batch Selection" && (
            <div className={Styles["total"]}>
              <span>Total:</span>
              {/* <span>&#8377;{amount} including GST</span> */}
              <span>&#8377;{amount}</span>
              {discount === true && (
                <div>
                  <span
                    style={{
                      color: "#F52929",
                      // fontWeight: "400",
                      // fontSize: "0.75rem",
                      // lineHeight: "1.66",
                      // letterSpacing: "0.03333em",
                      // textAlign: "left",
                      // marginTop: "3px",
                      // marginRight: "14px",
                      // marginBottom: "0",
                      // marginLeft: "14px",
                    }}
                  >
                    <del>&#8377;{decryptData?.programPrice}</del>&nbsp;
                    {voucherData?.voucherDiscountPercentage}% OFF
                  </span>
                </div>
              )}
            </div>
          )}

          {decryptData?.formType === "Program Registration" && (
            <div className={Styles["note-div"]}>
              <span className={Styles["note"]}>Note: </span>
              <span className={Styles["note-msg"]}>
                {initialAmount}/- is a registration fee. Total program Fee is{" "}
                {fullAmount}/-. Balance {fullAmount - initialAmount}/- needs to
                be paid within next 1 week.
              </span>
            </div>
          )}

          <div className={Styles["terms-consition-div"]}>
            <input
              type="checkbox"
              name="isCheckboxChecked"
              checked={formState.isCheckboxChecked}
              onChange={handleInputChange}
              className={Styles["checkbox"]}
            />
            <span>I accept the </span>
            <a
              href="https://d1ixtxt2uzttul.cloudfront.net/Documents/Terms%20%26%20Conditions%20for%20Leadership%20Essential%20Program.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </div>
          {errors.isCheckboxChecked && (
            <span style={invalid}>
              Please accept the Terms and Conditions to proceed.
            </span>
          )}

          <div className={Styles["dialog-action-button"]}>
            {/* <div onClick={() => handleSubmit("submit")}>
            <ButtonWithLink
              name={batchSubmitLoader ? "Submitting" : "Submit"}
              type="submit"
              className={"button"}
              id="submit_coupon_validation"
            />
            <span>I accept the </span>
            <a
              href="https://d1ixtxt2uzttul.cloudfront.net/Documents/Terms%20%26%20Conditions%20for%20Leadership%20Essential%20Program.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </div>
          {errors.isCheckboxChecked && (
            <span style={invalid}>
              Please accept the Terms and Conditions to proceed.
            </span>
          )} */}

            <div className={Styles["dialog-action-button"]}>
              {decryptData?.formType === "Batch Selection" && (
                <div onClick={() => handleSubmit("submit")}>
                  <ButtonWithLink
                    name={batchSubmitLoader ? "Submitting" : "Submit"}
                    type="submit"
                    className={"button"}
                    id="submit_coupon_validation"
                  />
                </div>
              )}

             {decryptData?.formType !== "Batch Selection" && (
                <div onClick={() => handleSubmit("submit")}>
                  <ButtonWithLink
                    name="Submit"
                    type="submit"
                    className={"button"}
                    id="submit_coupon_validation"
                  />
                </div>)}
              

              {/* {decryptData?.formType !== "Batch Selection" && (
                <div
                  className={Styles["pay-button"]}
                  onClick={() => handleSubmit("submit")}
                >
                  <ButtonWithLink
                    name="Pay Using Apple Pay"
                    type="submit"
                    className={"button"}
                    id="submit_coupon_validation"
                  />
                </div>
              )}  */}

              {/* <ButtonWithLink
                    name="Pay With Apple Pay"
                    type="submit"
                    className={"button"}
                    linkTo="payAction"
                    paymentMethods={paymentMethods}
                    paymentDetails={paymentDetails}
                    onPaymentResponse={() => handlePaymentResponse()}
                    id="submit_coupon_validation"
                  /> */}

              {/* <ButtonWithLink name="Apple Pay" className={"button"}>
                <PaymentRequestButton
                  paymentMethods={paymentMethods}
                  paymentDetails={paymentDetails}
                  onPaymentResponse={handlePaymentResponse}
                >
                  <button>Pay with Apple Pay kfnvk</button>
                </PaymentRequestButton>
              </ButtonWithLink> */}
              {/* <span className={Styles["pay-button"]}>
      <PaymentRequestButton
        paymentMethods={paymentMethods}
        paymentDetails={paymentDetails}
        onPaymentResponse={handlePaymentResponse}
      >
        <button>Pay with Apple Pay kfnvk</button>
      </PaymentRequestButton>
      {paymentResponse && (
        <div>
          Payment Response:
          <pre>{JSON.stringify(paymentResponse, null, 2)}</pre>
        </div>
      )}
    </span> */}

              <div className={Styles["cancel"]}>
                <ButtonWithLink
                  name="Cancel"
                  className={"button"}
                  linkTo="/"
                  // callback={() => setCancelConfirmation(true)}
                  id="cancel_coupon_validation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {messageDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={messageDialogOpen}
            // onClose={messageDialogClose}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Your Batch Details selection was Successful"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Close"
                  className={"button"}
                  linkTo="/"
                  // callback={messageDialogClose}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {cancelConfirmation && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={cancelConfirmation}
            // onClose={messageDialogClose}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Are you sure you want to discard registration?"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Yes"
                  className={"button"}
                  linkTo="action"
                  callback={() => window.close()}
                />
                <ButtonWithLinks
                  name="No"
                  className={"button"}
                  linkTo="action"
                  callback={() => setCancelConfirmation(false)}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {reqFailedDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={reqFailedDialogOpen}
            // onClose={messageDialogClose}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              {/* "Failed in processing your request. Please Try Again after
              sometime" */}
              {reqFailedMessage}
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Close"
                  className={"button"}
                  linkTo="action"
                  callback={() => setReqFailedDialogOpen(false)}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}
    </>
  );
}
