import Styles from "./BarCharts.module.scss";
import React, { useEffect, useRef } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  BarElement,
  CategoryScale as CategoryScale$1,
  LinearScale as LinearScale$1,
  BarController,
} from "chart.js";

export default function BarCharts({ data, text, yAxisConfig }) {
  const chartRef = useRef(null);
  const canvasWidth = data?.labels?.length;

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    Chart.register(
      CategoryScale,
      LinearScale,
      Title,
      Tooltip,
      BarElement,
      CategoryScale$1,
      LinearScale$1,
      BarController
    );

    const chartInstance = new Chart(ctx, {
      type: "bar",
      legend: {
        display: false,
      },
      data: {
        labels: data?.labels,
        datasets: [
          {
            label: "Bar Chart",
            data: data?.values,
            backgroundColor: "#F52929",
            borderColor: "#F52929",
            borderWidth: 1,
          },
        ],
      },
      options: {
        // maintainAspectRatio: false,
        responsive: false,
        scales: {
          x: {
            title: {
              display: true,
            },
          },
          y: yAxisConfig,
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
      },
    });

    return () => {
      chartInstance.destroy();
    };
  }, [data, text, yAxisConfig]);

  return (
    <div className={Styles["container"]}>
      <div className={Styles["chart-container"]}>
        <canvas
          ref={chartRef}
          width={canvasWidth > 6 ? canvasWidth * 100 : "600"}
          height="400"
        />
      </div>
    </div>
  );
}
