import ButtonWithLink from "../../components/buttons/ButtonWithLink";
import Styles from "./PayButtons.module.scss";

export default function PayButtons() {

  const goBackAction = () => {
    window.history.back();
  };

  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["back-button"]}>
        <ButtonWithLink name="Back" className={"button"} linkTo="action" callback={() => goBackAction()}></ButtonWithLink>
      </div>

      <div className={Styles["card-div"]}>
        <span className={Styles["title"]}>
          Please choose the payment method of your preference
        </span>
        <div className={Styles["button-div"]}>
          <div className={Styles["button"]}>
            <ButtonWithLink
              name="Pay Using Instamojo"
              className={"button"}
            ></ButtonWithLink>
          </div> 

          <div className={Styles["button"]}>
            <ButtonWithLink
              name="Pay Using Apple Pay"
              className={"button"}
            ></ButtonWithLink>
          </div>

        </div>
      </div>
    </div>
  );
}
