import Styles from "./Dashboards.module.scss";
import BarChart from "../bar-charts/BarCharts";
import LineCharts from "../line-chart/LineCharts";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import ButtonWithLinks from "../buttons/ButtonWithLink";
import { getDahsboardData } from "../../service/dashboardService";
import { useEffect } from "react";

export default function Dashboards() {
  const { BatchId, UserId } = useParams();
  const [showRightDiv, setShowRightDiv] = useState(false);
  const [showLeftDiv, setShowLeftDiv] = useState(true);
  const [individualLineData, setIndividualLineData] = useState([]);
  const [individualProgressTracker, setIndividualProgressTracker] = useState(
    []
  );
  const [individual100BmData, setIndividual100BmData] = useState([]);
  const [filterClick, setFilterClick] = useState(null);
  const [filter100BMClick, setFilter100BMClick] = useState(null);
  const [summeryData, setSummeryData] = useState({});
  const [summeryFinalData, setSummeryFinalData] = useState([]);
  const [graphFilter, setGraphFilter] = useState({});
  const [graph100BMFilter, setGraph100BMFilter] = useState({});
  const [selectedProgramOption, setSelectedProgramOption] = useState(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [unEnrolledProgramDialogOpen, setUnEnrolledProgramDialogOpen] =
    useState(false);

  const data = JSON.parse(BatchId);
  const programNameOptions = data.map((item) => Object.keys(item)[0]);

  const handleCloseAlert = () => {
    setAlertDialogOpen(false);
  };

  const handleCloseUnEnrolled = () => {
    setUnEnrolledProgramDialogOpen(false);
  };

  const handleProgramDropdownChange = (selected) => {
    setSelectedProgramOption(selected);
    if (selected?.label === "100 Board Members Program") {
      setShowLeftDiv(false);
      setShowRightDiv(true);
    }
    const selectedProgramObj = data.find(
      (item) => Object.keys(item)[0] === selected?.value
    );

    if (selectedProgramObj) {
      const batchData = selectedProgramObj[selected?.value].replace(/\+/g, " ");
      const programName = selected?.label;
      handleGetDashboardData(batchData, UserId, programName);
    }
  };

  const handleGetDashboardData = async (batchId, userId, programName) => {
    try {
      if (batchId === "null") {
        setUnEnrolledProgramDialogOpen(true);
      } else {
        const response = await getDahsboardData(batchId, userId, programName);
        if (response.data.statusCode === 200) {
          if (programName === "Leadership Essentials Program") {
            setIndividualProgressTracker(
              response.data.data.individualProgressTracker
            );
            setSummeryData(response.data.data.summaryDashboard);
          } else {
            setIndividual100BmData(
              response.data.data.individualProgressTracker
            );
          }
        } else {
          setAlertDialogOpen(true);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (individualProgressTracker) {
      setLineGraphData(individualProgressTracker);
    }
  }, [individualProgressTracker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (individual100BmData) {
      set100BMLineGraphData(individual100BmData);
    }
  }, [individual100BmData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (summeryData !== null || Object.keys(summeryData)?.length !== 0) {
      barGraph(summeryData);
    }
  }, [summeryData]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatData = (data) => {
    return {
      labels: data?.map((item) => item["weekNumber"]),
      values: data?.map((item) => item["weekScore"]),
    };
  };

  const formatDailyRitualData = (data) => {
    return {
      labels: data?.map((item) => item["weekNumber"]),
      values: data?.map((item) =>
        ["faizen", "powerfulRequests", "playingGame", "graditude", "mirrorWork"]
          .map((prop) => parseInt(item[prop] || 0)) // Convert to integers and handle missing values
          .reduce((sum, value) => sum + value, 0)
      ),
    };
  };

  const createLineData = (data) => {
    const weeksNumber = data?.labels?.map((label) => `Week ${label}`);
    const weekScore = data?.values;
    return {
      weeksNumber,
      weekScore,
    };
  };

  const createLine100BMData = (data) => {
    var result = {};
    data?.forEach((item) => {
      const monthNumber = item?.monthNumber;
      const weekNo = item?.data?.map((item) => item?.weekNumber);
      const score = item?.data?.map((item) => item?.weekScore);
      if (!result[monthNumber]) {
        result[monthNumber] = { weekNo: [], months: [], data: [] };
      }
      result[monthNumber].weekNo.push(weekNo);
      result[monthNumber].months.push(monthNumber);
      result[monthNumber].data.push(score);
    });
    return result;
  };
  const formatBarGraph100BMData = (data) => {
    const flattenedWeekNo = data?.weekNo?.flat();
    const newLabel = flattenedWeekNo?.map((item) => `Week ${item}`);
    return {
      weeksNumber: newLabel,
      weekScore: data?.data?.flat(),
    };
  };

  const setLineGraphData = (individualProgressTracker) => {
    if (individualProgressTracker) {
      const dailyRituals = formatDailyRitualData(
        individualProgressTracker?.dailyRituals
      );
      const BHAGProgress = formatData(individualProgressTracker?.BHAGProgress);
      const challengeParticipationDays = formatData(
        individualProgressTracker?.challengeParticipationDays
      );
      const hoursSavedThisWeekThroughERRC = formatData(
        individualProgressTracker?.hoursSavedThisWeekThroughERRC
      );
      const leadershipDashboard = formatData(
        individualProgressTracker?.leadershipDashboard
      );
      const graphData = {
        dailyRituals: createLineData(dailyRituals),
        BHAGProgress: createLineData(BHAGProgress),
        challengeParticipationDays: createLineData(challengeParticipationDays),
        hoursSavedThisWeekThroughERRC: createLineData(
          hoursSavedThisWeekThroughERRC
        ),
        leadershipDashboard: createLineData(leadershipDashboard),
      };
      setIndividualLineData(graphData);
    }
  };

  const set100BMLineGraphData = (individual100BmData) => {
    if (individual100BmData) {
      const dailyRituals = createLine100BMData(
        individual100BmData?.PerformedYourDailyRituals
      );
      const BHAGProgress = createLine100BMData(
        individual100BmData?.BHAGProgress
      );
      const challengeParticipationDays = createLine100BMData(
        individual100BmData?.challengeParticipationDays
      );
      const hoursSavedThisWeekThroughERRC = createLine100BMData(
        individual100BmData?.hoursSavedThisWeekThroughERRC
      );
      const leadershipDashboard = createLine100BMData(
        individual100BmData?.leadershipDashboard
      );
      const expressRitual = createLine100BMData(
        individual100BmData?.ExpressRitual
      );
      const onePerson = createLine100BMData(individual100BmData?.OnePerson);

      const reachOutRitual = createLine100BMData(
        individual100BmData?.ReachOutRitual
      );
      const reachedOutTopMgmt = createLine100BMData(
        individual100BmData?.ReachedOutTopMgmt
      );
      const visualization = createLine100BMData(
        individual100BmData?.Visualization
      );
      const ceosBoardMembersConnected = createLine100BMData(
        individual100BmData?.RenamedceosBoardMembersConnected
      );

      const graphData = {
        dailyRituals: formatBarGraph100BMData(
          dailyRituals[Object.keys(dailyRituals)[0]]
        ),
        BHAGProgress: formatBarGraph100BMData(
          BHAGProgress[Object.keys(BHAGProgress)[0]]
        ),
        challengeParticipationDays: formatBarGraph100BMData(
          challengeParticipationDays[Object.keys(challengeParticipationDays)[0]]
        ),
        hoursSavedThisWeekThroughERRC: formatBarGraph100BMData(
          hoursSavedThisWeekThroughERRC[
            Object.keys(hoursSavedThisWeekThroughERRC)[0]
          ]
        ),
        leadershipDashboard: formatBarGraph100BMData(
          leadershipDashboard[Object.keys(leadershipDashboard)[0]]
        ),
        expressRitual: formatBarGraph100BMData(
          expressRitual[Object.keys(expressRitual)[0]]
        ),
        onePerson: formatBarGraph100BMData(
          onePerson[Object.keys(onePerson)[0]]
        ),
        reachOutRitual: formatBarGraph100BMData(
          reachOutRitual[Object.keys(reachOutRitual)[0]]
        ),
        reachedOutTopMgmt: formatBarGraph100BMData(
          reachedOutTopMgmt[Object.keys(reachedOutTopMgmt)[0]]
        ),
        visualization: formatBarGraph100BMData(
          visualization[Object.keys(visualization)[0]]
        ),
        ceosBoardMembersConnected: formatBarGraph100BMData(
          ceosBoardMembersConnected[Object.keys(ceosBoardMembersConnected)[0]]
        ),
      };
      setIndividualLineData(graphData);
      const monthFilterdata = Object.keys(dailyRituals);
      setGraph100BMFilter(monthFilterdata?.map((val) => `Month ${val}`));
    }
  };

  const filter100BMGraph = (val) => {
    if (individual100BmData) {
      const dailyRituals = createLine100BMData(
        individual100BmData?.PerformedYourDailyRituals
      );
      const BHAGProgress = createLine100BMData(
        individual100BmData?.BHAGProgress
      );
      const challengeParticipationDays = createLine100BMData(
        individual100BmData?.challengeParticipationDays
      );
      const hoursSavedThisWeekThroughERRC = createLine100BMData(
        individual100BmData?.hoursSavedThisWeekThroughERRC
      );
      const leadershipDashboard = createLine100BMData(
        individual100BmData?.leadershipDashboard
      );
      const expressRitual = createLine100BMData(
        individual100BmData?.ExpressRitual
      );
      const onePerson = createLine100BMData(individual100BmData?.OnePerson);

      const reachOutRitual = createLine100BMData(
        individual100BmData?.ReachOutRitual
      );
      const reachedOutTopMgmt = createLine100BMData(
        individual100BmData?.ReachedOutTopMgmt
      );
      const visualization = createLine100BMData(
        individual100BmData?.Visualization
      );
      const ceosBoardMembersConnected = createLine100BMData(
        individual100BmData?.RenamedceosBoardMembersConnected
      );
      const graphData = {
        dailyRituals: formatBarGraph100BMData(
          Object.values(dailyRituals).find(
            (item) => parseInt(item.months) === val
          )
        ),
        BHAGProgress: formatBarGraph100BMData(
          Object.values(BHAGProgress).find(
            (item) => parseInt(item.months) === val
          )
        ),
        challengeParticipationDays: formatBarGraph100BMData(
          Object.values(challengeParticipationDays).find(
            (item) => parseInt(item.months) === val
          )
        ),
        hoursSavedThisWeekThroughERRC: formatBarGraph100BMData(
          Object.values(hoursSavedThisWeekThroughERRC).find(
            (item) => parseInt(item.months) === val
          )
        ),
        leadershipDashboard: formatBarGraph100BMData(
          Object.values(leadershipDashboard).find(
            (item) => parseInt(item.months) === val
          )
        ),
        expressRitual: formatBarGraph100BMData(
          Object.values(expressRitual).find(
            (item) => parseInt(item.months) === val
          )
        ),
        onePerson: formatBarGraph100BMData(
          Object.values(onePerson).find((item) => parseInt(item.months) === val)
        ),
        reachOutRitual: formatBarGraph100BMData(
          Object.values(reachOutRitual).find(
            (item) => parseInt(item.months) === val
          )
        ),
        reachedOutTopMgmt: formatBarGraph100BMData(
          Object.values(reachedOutTopMgmt).find(
            (item) => parseInt(item.months) === val
          )
        ),
        visualization: formatBarGraph100BMData(
          Object.values(visualization).find(
            (item) => parseInt(item.months) === val
          )
        ),
        ceosBoardMembersConnected: formatBarGraph100BMData(
          Object.values(ceosBoardMembersConnected).find(
            (item) => parseInt(item.months) === val
          )
        ),
      };
      setIndividualLineData(graphData);
    }
  };

  const createBarGraphData = (data) => {
    var result = {};
    data?.forEach((item) => {
      const weekNumber = item?.WeekNumber;
      const name = item?.Details?.userName;
      const score = parseInt(item?.Details?.bhagScore);
      if (!result[weekNumber]) {
        result[weekNumber] = { names: [], weeks: [], data: [] };
      }
      result[weekNumber].names.push(name);
      result[weekNumber].weeks.push(weekNumber);
      result[weekNumber].data.push(score);
    });
    return result;
  };

  const formatBarGraphData = (data) => {
    return {
      labels: data?.names,
      values: data?.data,
    };
  };

  const barGraph = (summeryData) => {
    const dailyRituals = createBarGraphData(summeryData?.dailyRituals);
    const BHAGProgress = createBarGraphData(summeryData?.BHAGProgress);
    const challengeParticipationDays = createBarGraphData(
      summeryData?.challengeParticipationDays
    );
    const hoursSavedThisWeekThroughERRC = createBarGraphData(
      summeryData?.hoursSavedThisWeekThroughERRC
    );
    const leadershipDashboard = createBarGraphData(
      summeryData?.leadershipDashboard
    );

    setSummeryFinalData({
      dailyRituals: formatBarGraphData(
        dailyRituals[Object.keys(dailyRituals)[0]]
      ),
      BHAGProgress: formatBarGraphData(
        BHAGProgress[Object.keys(BHAGProgress)[0]]
      ),
      challengeParticipationDays: formatBarGraphData(
        challengeParticipationDays[Object.keys(challengeParticipationDays)[0]]
      ),
      hoursSavedThisWeekThroughERRC: formatBarGraphData(
        hoursSavedThisWeekThroughERRC[
          Object.keys(hoursSavedThisWeekThroughERRC)[0]
        ]
      ),
      leadershipDashboard: formatBarGraphData(
        leadershipDashboard[Object.keys(leadershipDashboard)[0]]
      ),
    });
    setGraphFilter(Object.keys(dailyRituals));
  };

  const filterBarGraph = (val) => {
    const dailyRituals = createBarGraphData(summeryData?.dailyRituals);
    const BHAGProgress = createBarGraphData(summeryData?.BHAGProgress);
    const challengeParticipationDays = createBarGraphData(
      summeryData?.challengeParticipationDays
    );
    const hoursSavedThisWeekThroughERRC = createBarGraphData(
      summeryData?.hoursSavedThisWeekThroughERRC
    );
    const leadershipDashboard = createBarGraphData(
      summeryData?.leadershipDashboard
    );
    setSummeryFinalData({
      dailyRituals: formatBarGraphData(dailyRituals[val]),
      BHAGProgress: formatBarGraphData(BHAGProgress[val]),
      challengeParticipationDays: formatBarGraphData(
        challengeParticipationDays[val]
      ),
      hoursSavedThisWeekThroughERRC: formatBarGraphData(
        hoursSavedThisWeekThroughERRC[val]
      ),
      leadershipDashboard: formatBarGraphData(leadershipDashboard[val]),
    });
  };

  const handleFilterButtonClick = (val) => {
    filterBarGraph(val);
    setFilterClick(val);
  };

  const handleMonthFilterButtonClick = (val) => {
    filter100BMGraph(val);
    setFilter100BMClick(val);
  };

  useEffect(() => {
    if (graphFilter.length > 0) {
      setFilterClick(graphFilter[0]);
    }
  }, [graphFilter]);

  useEffect(() => {
    if (graph100BMFilter.length > 0) {
      setFilter100BMClick(graph100BMFilter[0].match(/\d+/)[0], 10);
    }
  }, [graph100BMFilter]);

  useEffect(() => {
    if (Object.keys(summeryFinalData)?.length !== 0) {
      setSummeryFinalData(summeryFinalData);
    }
  }, [summeryFinalData]);

  const handleLeft = () => {
    setShowLeftDiv(true);
    setShowRightDiv(false);
  };
  const handleRight = () => {
    setShowRightDiv(true);
    setShowLeftDiv(false);
  };

  const yAxisConfigPercentage = {
    beginAtZero: true,
    max: 100,
    ticks: {
      callback: function (value) {
        return value + "%";
      },
    },
    title: {
      display: true,
      text: "Progress",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigHoursSaved = {
    beginAtZero: true,
    max: 100,
    title: {
      display: true,
      text: "Hours",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigDailyRituals = {
    beginAtZero: true,
    max: 35,
    ticks: {
      stepSize: 5,
    },
    title: {
      display: true,
      text: "Days",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigWeeklyChallenge = {
    beginAtZero: true,
    max: 7,
    ticks: {
      stepSize: 1,
    },
    title: {
      display: true,
      text: "Days",
      fontFamily: "Gemunu-regular",
    },
  };

  const yAxisConfigLeadershipScore = {
    beginAtZero: true,
    max: 20,
    ticks: {
      stepSize: 2,
    },
    title: {
      display: true,
      text: "Score",
      fontFamily: "Gemunu-regular",
    },
  };

  // const dummyData = {
  //   values: [
  //     10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 10, 20, 30, 40, 50, 60, 70, 80,
  //     90, 10, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 10, 20, 30, 40, 50, 60,
  //     70, 80, 90, 10,
  //   ],
  //   labels: [
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //     "abhijit",
  //     "abhijit",
  //     "nishigandha",
  //     "vikas",
  //     "gaurang m",
  //   ],
  // };

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className={Styles["main-parent-div"]}>
        <div className={Styles["back-button-container"]}>
          <ButtonWithLinks
            id="gdashboardPageBack"
            name="Back"
            linkTo="action"
            callback={goBack}
            className={"button"}
          />
        </div>

        <div className={Styles["title"]}>Dashboards</div>

        <div className={Styles["dropdown-div"]}>
          <Select
            options={programNameOptions.map((option) => ({
              value: option,
              label: option.replace(/\+/g, " "),
            }))}
            placeholder="Select Program"
            value={selectedProgramOption}
            onChange={handleProgramDropdownChange}
            className={Styles["dropdown"]}
          />
        </div>

        {selectedProgramOption && (
          <>
            <div
              className={
                selectedProgramOption?.label !== "100 Board Members Program"
                  ? Styles["column-div"]
                  : Styles["column-div-single"]
              }
            >
              {selectedProgramOption?.label !== "100 Board Members Program" && (
                <div
                  className={
                    showLeftDiv
                      ? Styles["left-column-afterClick"]
                      : Styles["left-column"]
                  }
                  onClick={handleLeft}
                >
                  Summary
                </div>
              )}

              <div
                className={
                  showRightDiv
                    ? Styles["right-column-afterClick"]
                    : Styles["right-column"]
                }
                onClick={handleRight}
              >
                Individual Progress
              </div>
            </div>

            {showLeftDiv && (
              <>
                <div className={Styles["filter-div"]}>
                  {graphFilter.length > 0 &&
                    graphFilter?.map((item) => {
                      return (
                        <div className={Styles["filter-button"]}>
                          <button
                            onClick={() => handleFilterButtonClick(item)}
                            className={
                              filterClick === item
                                ? Styles["filter-button-click"]
                                : Styles["filter-button"]
                            }
                          >
                            {item}
                          </button>
                        </div>
                      );
                    })}
                </div>

                <div className={Styles["bar-div"]}>
                  <div className={Styles["bar-title"]}>Daily Rituals</div>
                  <BarChart
                    data={summeryFinalData?.dailyRituals}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigDailyRituals}
                  />

                  <div className={Styles["bar-title"]}>
                    Leadership Dashboard
                  </div>
                  <BarChart
                    data={summeryFinalData?.leadershipDashboard}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigLeadershipScore}
                  />

                  <div className={Styles["bar-title"]}>B-HAG Progress</div>
                  <BarChart
                    data={summeryFinalData?.BHAGProgress}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigPercentage}
                  />

                  <div className={Styles["bar-title"]}>
                    Challenge Participation
                  </div>
                  <BarChart
                    data={summeryFinalData?.challengeParticipationDays}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigWeeklyChallenge}
                  />

                  <div className={Styles["bar-title"]}>Hours Saved</div>
                  <BarChart
                    data={summeryFinalData?.hoursSavedThisWeekThroughERRC}
                    text={"weeks"}
                    yAxisConfig={yAxisConfigHoursSaved}
                  />
                </div>
              </>
            )}

            {showRightDiv && (
              <>
                {selectedProgramOption?.label !==
                  "Leadership Essentials Program" && (
                  <div className={Styles["filter-div"]}>
                    {graph100BMFilter.length > 0 &&
                      graph100BMFilter?.map((item) => {
                        return (
                          <div className={Styles["filter-button"]}>
                            <button
                              onClick={() =>
                                handleMonthFilterButtonClick(
                                  parseInt(item.match(/\d+/)[0], 10)
                                )
                              }
                              className={
                                parseInt(filter100BMClick) ===
                                parseInt(item.match(/\d+/)[0])
                                  ? Styles["filter-button-click"]
                                  : Styles["filter-button"]
                              }
                            >
                              {item}
                            </button>
                          </div>
                        );
                      })}
                  </div>
                )}

                <div className={Styles["line-div"]}>
                  {parseInt(individualLineData.dailyRituals.weekScore[0]) !==
                    0 && (
                    <>
                      <div className={Styles["line-title"]}>Daily Rituals</div>
                      <LineCharts
                        labels={individualLineData.dailyRituals.weeksNumber}
                        values={individualLineData.dailyRituals.weekScore}
                        yAxisConfig={
                          selectedProgramOption?.label !==
                          "100 Board Members Program"
                            ? yAxisConfigDailyRituals
                            : yAxisConfigWeeklyChallenge
                        }
                      />{" "}
                    </>
                  )}

                  {selectedProgramOption?.label !==
                    "100 Board Members Program" && (
                    <>
                      <div className={Styles["line-title"]}>
                        Leadership Dashboard
                      </div>
                      <LineCharts
                        labels={
                          individualLineData.leadershipDashboard.weeksNumber
                        }
                        values={
                          individualLineData.leadershipDashboard.weekScore
                        }
                        yAxisConfig={yAxisConfigLeadershipScore}
                      />{" "}
                    </>
                  )}

                  {selectedProgramOption?.label ===
                    "100 Board Members Program" && (
                    <>
                      {parseInt(
                        individualLineData.expressRitual?.weekScore[0]
                      ) !== 0 && (
                        <>
                          <div className={Styles["line-title"]}>
                            Express Ritual
                          </div>
                          <LineCharts
                            labels={
                              individualLineData.expressRitual?.weeksNumber
                            }
                            values={individualLineData.expressRitual?.weekScore}
                            yAxisConfig={yAxisConfigWeeklyChallenge}
                          />
                        </>
                      )}

                      {parseInt(individualLineData.onePerson?.weekScore[0]) !==
                        0 && (
                        <>
                          <div className={Styles["line-title"]}>
                            Reach Out At Least One Person
                          </div>
                          <LineCharts
                            labels={individualLineData.onePerson?.weeksNumber}
                            values={individualLineData.onePerson?.weekScore}
                            yAxisConfig={yAxisConfigWeeklyChallenge}
                          />
                        </>
                      )}

                      {parseInt(
                        individualLineData.reachOutRitual?.weekScore[0]
                      ) !== 0 && (
                        <>
                          <div className={Styles["line-title"]}>
                            Reach out Ritual
                          </div>
                          <LineCharts
                            labels={
                              individualLineData.reachOutRitual?.weeksNumber
                            }
                            values={
                              individualLineData.reachOutRitual?.weekScore
                            }
                            yAxisConfig={yAxisConfigWeeklyChallenge}
                          />
                        </>
                      )}

                      {parseInt(
                        individualLineData.reachedOutTopMgmt?.weekScore[0]
                      ) !== 0 && (
                        <>
                          <div className={Styles["line-title"]}>
                            Reached Out To Any CXO/BM/Top management people
                          </div>
                          <LineCharts
                            labels={
                              individualLineData.reachedOutTopMgmt?.weeksNumber
                            }
                            values={
                              individualLineData.reachedOutTopMgmt?.weekScore
                            }
                            yAxisConfig={yAxisConfigWeeklyChallenge}
                          />
                        </>
                      )}

                      {parseInt(
                        individualLineData.visualization?.weekScore[0]
                      ) !== 0 && (
                        <>
                          <div className={Styles["line-title"]}>
                            Practice Visualization
                          </div>
                          <LineCharts
                            labels={
                              individualLineData.visualization?.weeksNumber
                            }
                            values={individualLineData.visualization?.weekScore}
                            yAxisConfig={yAxisConfigWeeklyChallenge}
                          />
                        </>
                      )}

                      {parseInt(
                        individualLineData.ceosBoardMembersConnected
                          ?.weekScore[0]
                      ) !== 0 && (
                        <>
                          <div className={Styles["line-title"]}>
                            CEOs or Board Members could you connect in Linked In
                          </div>
                          <LineCharts
                            labels={
                              individualLineData.ceosBoardMembersConnected
                                ?.weeksNumber
                            }
                            values={
                              individualLineData.ceosBoardMembersConnected
                                ?.weekScore
                            }
                            yAxisConfig={yAxisConfigWeeklyChallenge}
                          />
                        </>
                      )}
                    </>
                  )}

                  {parseInt(individualLineData.BHAGProgress.weekScore[0]) !==
                    0 && (
                    <>
                      <div className={Styles["line-title"]}>B-HAG Progress</div>
                      <LineCharts
                        labels={individualLineData.BHAGProgress.weeksNumber}
                        values={individualLineData.BHAGProgress.weekScore}
                        yAxisConfig={yAxisConfigPercentage}
                      />
                    </>
                  )}

                  {parseInt(
                    individualLineData.challengeParticipationDays.weekScore[0]
                  ) !== 0 && (
                    <>
                      <div className={Styles["line-title"]}>
                        Challenge Participation
                      </div>
                      <LineCharts
                        labels={
                          individualLineData.challengeParticipationDays
                            .weeksNumber
                        }
                        values={
                          individualLineData.challengeParticipationDays
                            .weekScore
                        }
                        yAxisConfig={yAxisConfigWeeklyChallenge}
                      />
                    </>
                  )}

                  {parseInt(
                    individualLineData.hoursSavedThisWeekThroughERRC
                      .weekScore[0]
                  ) !== 0 && (
                    <>
                      <div className={Styles["line-title"]}>Hours Saved</div>
                      <LineCharts
                        labels={
                          individualLineData.hoursSavedThisWeekThroughERRC
                            .weeksNumber
                        }
                        values={
                          individualLineData.hoursSavedThisWeekThroughERRC
                            .weekScore
                        }
                        yAxisConfig={yAxisConfigHoursSaved}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {alertDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={alertDialogOpen}
            onClose={handleCloseAlert}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Progress Not Found"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={handleCloseAlert}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}

      {unEnrolledProgramDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={unEnrolledProgramDialogOpen}
            onClose={handleCloseUnEnrolled}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "You have not enrolled for this program"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={handleCloseUnEnrolled}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}
    </>
  );
}
