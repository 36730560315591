import React from "react";
import Styles from './LineCharts.module.scss'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

export default function LineCharts({labels, values, yAxisConfig}) {

  const lineWidth = labels?.length;
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: false,
    legend: {
        display: false, // Hide the legend
      },
    plugins: {
      legend: {
        display: false
        // position: "top",
      },
      title: {
        display: false,
        // text: "Chart.js Line Chart",
      },
     
    },
    scales: {
        x: {
          title: {
            display: true,
            // text: "Weeks",
          },
        },
        y: yAxisConfig
      },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Daily Rituals",
        data: values,
        borderColor: "red",
        backgroundColor: "red",
      }
    ],
  };

  // const shouldRenderChart = values && values.every(value => value !== null);
  // if (!shouldRenderChart) {
  //   return null; // Don't render the chart if all values are null
  // }

  return (
    <div className={Styles["container"]}>
      <div className={Styles["main-div"]}>
      <Line options={options} data={data} width={lineWidth > 6 ? lineWidth * 100 : '600'} height="400" />
    </div>

    </div>
    
  );
}
