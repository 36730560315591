// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import ButtonWithLinks from "../buttons/ButtonWithLink";
// import styles from './NewsletterDetails.module.scss';

// const NewsletterDetails = () => {
//     const { year, month } = useParams();
//     const [newsletterImages, setNewsletterImages] = useState({ images: [] });
//     // const BASE_URL = "https://api.iamironlady.com/api";
//     const BASE_URL = "http://localhost:3001/api";

//     useEffect(() => {
//         const fetchNewsletterImages = async () => {
//             try {
//                 const response = await fetch(`${BASE_URL}/newsletter-details/${year}/${month}`);
//                 const data = await response.json();
//                 setNewsletterImages(data.data);
//             } catch (error) {
//                 console.error("Error fetching images:", error);
//             }
//         };

//         fetchNewsletterImages();
//     }, [month, year]);

//     return (
//         <div className={styles.container}>
//             <div className={styles.images}>
//             {newsletterImages.images.map((image) => (
//                 <div key={image.id} className={styles.imageContainer}>
//                     {image.clickable ? (
//                         <a href={image.link} target="_blank" rel="noopener noreferrer">
//                             <img src={image.url} alt={`Newsletter ${image.id}`} />
//                         </a>
//                     ) : (
//                         <img src={image.url} alt={`Newsletter ${image.id}`} />
//                     )}
//                 </div>
//             ))}
//             </div>

//             <div className={styles.newsletters}>
//                 <ButtonWithLinks
//                     id="newsletter"
//                     name="Go Back"
//                     linkTo="/newsletter"
//                     className={"button"}
//                 />
//             </div>
//         </div>
//     );
// };

// export default NewsletterDetails;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonWithLinks from "../buttons/ButtonWithLink";
import styles from './NewsletterDetails.module.scss';
import { getAllNewsletterFromCMS } from '../../service/communityService.js';
import { ThreeCircles } from 'react-loader-spinner';

const NewsletterDetails = () => {
    const { year, month } = useParams();
    const [newsletterImages, setNewsletterImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsletterDetails = async () => {
            try {
                const response = await getAllNewsletterFromCMS();
                if (response.statusCode === 200 && Array.isArray(response.data)) {
                    const data = response.data.map(item => {
                        try {
                            return {
                                id: item.id,
                                name: item.name,
                                description: JSON.parse(item.description),
                            };
                        } catch (error) {
                            console.error("Error parsing description:", error);
                            return {
                                id: item.id,
                                name: item.name,
                                description: null,
                            };
                        }
                    }).sort((a, b) => b.id - a.id);

                    const filteredImages = data.filter(item => {
                        const [itemMonth, itemYear] = item.name.split(" ");
                        return itemMonth === month && itemYear === year;
                    });

                    if (filteredImages.length > 0) {
                        setNewsletterImages(filteredImages[0].description.images);
                    }
                }
            } catch (error) {
                console.error("Error fetching newsletters:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsletterDetails();
    }, [month, year]);

    return (
        <div className={styles.container}>
            {loading ? (
                <ThreeCircles
                    height="70"
                    width="70"
                    color="#F52929"
                    wrapperStyle={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "20vh",
                    }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                />
            ) : (
                <div className={styles.images}>
                    {newsletterImages.map((image) => (
                        <div key={image.id} className={styles.imageContainer}>
                            {image.clickable ? (
                                <a href={image.link} target="_blank" rel="noopener noreferrer">
                                    <img src={image.url} alt={`Newsletter ${image.id}`} />
                                </a>
                            ) : (
                                <img src={image.url} alt={`Newsletter ${image.id}`} />
                            )}
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.newsletters}>
                <ButtonWithLinks
                    id="newsletter"
                    name="Go Back"
                    linkTo="/newsletter"
                    className={"button"}
                />
            </div>
        </div>
    );
};

export default NewsletterDetails;