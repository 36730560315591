import Styles from "../methodology-new/Methodology.module.scss";
import AOS from "aos";
import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function MethodologyNew({ filteredData }) {
  useEffect(() => {
    AOS.init();
  }, []);

  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  return (
    <div className={Styles["main-parent-div"]}>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        className={Styles["box-shadow"]}
      >
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["title-div"]}
        >
          <span className={Styles["title"]}>
            {" "}
            {filteredData?.methodology.title}{" "}
          </span>
        </div>
        {screen >= 769 ? (
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            className={Styles["main-div"]}
          >
            <div className={Styles["left-div"]}>
              {filteredData?.methodology?.webMethodologyLeft &&
                filteredData?.methodology?.webMethodologyLeft.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          Styles[
                            index !==
                            filteredData?.methodology?.webMethodologyLeft.length -
                              1
                              ? "container"
                              : "container2"
                          ]
                        }
                      >
                        <div className={Styles["img-div"]}>
                          <img
                            alt=""
                            className={Styles["img"]}
                            src={item.img}
                          ></img>
                        </div>

                        <div className={Styles["description-div"]}>
                          <span className={Styles["description"]}>
                            {item.title}
                          </span>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>

            <div className={Styles["right-div"]}>
              {filteredData?.methodology?.webMethodologyRight &&
                filteredData?.methodology?.webMethodologyRight.map(
                  (item, index) => {
                    return (
                      <div key={index} className={Styles["container"]}>
                        <div className={Styles["img-div"]}>
                          <img
                            alt=""
                            className={Styles["img"]}
                            src={item.img}
                          ></img>
                        </div>

                        <div className={Styles["description-div"]}>
                          <span className={Styles["description"]}>
                            {item.title}
                          </span>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        ) : (
          <div className={Styles["mobile-div"]}>
            {filteredData?.methodology?.mobileMethodology &&
              filteredData?.methodology?.mobileMethodology.map((item, index) => {
                return (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    key={index}
                    className={
                      Styles[
                        index !==
                        filteredData?.methodology?.mobileMethodology.length - 1
                          ? "mobile-container"
                          : "mobile-container2"
                      ]
                    }
                  >
                    <div className={Styles["img-div"]}>
                      <img
                        alt=""
                        className={Styles["img"]}
                        src={item.img}
                      ></img>
                    </div>

                    <div className={Styles["description-div"]}>
                      <span className={Styles["description"]}>
                        {item.title}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
