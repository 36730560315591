import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonWithLink from "../../buttons/ButtonWithLink";
import Styles from "./GetInTouch.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DialogContent } from "@mui/material";
import { capitalizeFirstLetter } from "../../../helper-fuction/helper";
import { encryptURL } from "../../../helper-fuction/helper";
import { decryptResponse } from "../../../helper-fuction/helper";
import { appLevelConstants } from "../../../constants/AppLevelConstants";
import { refer } from "../../../service/formService";
import { validatePhoneNumber } from "../../../helper-fuction/helper";
import { validateEmail } from "../../../helper-fuction/helper";
import { WordCount } from "../../../helper-fuction/helper";

const Refer = ({ open, setDialogOpen, sucessToaster, errorToaster }) => {
  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  let getContactUsDateTime = new Date().toJSON();
  const leadSource = capitalizeFirstLetter(
    localStorage.getItem("leadSourceName")
  );

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [fullName, setFullName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [referralName, setReferralName] = useState("");
  const [referralEmail, setReferralEmail] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneerror] = useState("");
  const [referralNameError, setReferralNameError] = useState("");
  const [referralEmailError, setReferralEmailError] = useState("");

  let count = WordCount(fullName);
  let space, firstName, lastName;
  if (count < 2) {
    space = fullName.indexOf(" ");
    firstName = fullName.substring(space + 1);
    lastName = "------";
  } else {
    space = fullName.indexOf(" ");
    firstName = fullName.substring(0, space);
    lastName = fullName.substring(space + 1);
  }

  var urlToCallRefer = encryptURL(
    "https://www.zohoapis.in/crm/v2/functions/il_refer_func/actions/execute?auth_type=apikey&zapikey=1003.fc9035d274089c149e601eaab0f7bb2c.42d592278f3009bdbe4ac29ee38008f5" +
      "&firstName=" +
      firstName +
      "&lastName=" +
      lastName +
      "&fullName=" +
      fullName +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&leadSource=" +
      leadSource +
      "&currentLeadStatus=" +
      appLevelConstants.forms.referForm +
      "&getContactUsDateTime=" +
      getContactUsDateTime +
      "&referralName=" +
      referralName +
      "&referralEmail=" +
      referralEmail +
      ""
  );

  let hasError = false;
  const submitRefer = async (url) => {
    try {
      const response = await refer(url);
      const encryptedMessage = response.data.data;
      const decryptMessage = decryptResponse(
        encryptedMessage,
        appLevelConstants.REACT_APP_SECRET_KEY
      );
      setDialogOpen(false);
      sucessToaster(decryptMessage);
    } catch (error) {
      setDialogOpen(false);
      errorToaster(error.response.data.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fullName.trim() === "") {
      setFullNameError(appLevelConstants.Errors.firstName);
      hasError = true;
    } else {
      setFullNameError("");
    }
    if (!validateEmail(email)) {
      setEmailError(appLevelConstants.Errors.email);
      hasError = true;
    } else {
      setEmailError("");
    }
    if (referralName.trim() === "") {
      setReferralNameError(appLevelConstants.Errors.refer);
      hasError = true;
    } else {
      setReferralNameError("");
    }
    if (!validateEmail(referralEmail)) {
      setReferralEmailError(appLevelConstants.Errors.email);
      hasError = true;
    } else {
      setReferralEmailError("");
    }
    if (!validatePhoneNumber(phone)) {
      setPhoneerror(appLevelConstants.Errors.phone);
      hasError = true;
    } else {
      setPhoneerror("");
      if (!hasError) {
        submitRefer(urlToCallRefer);
      }
    }
  };

  return (
    <>
      <div className={Styles["dialog-main-div"]}>
        <Dialog
          className={Styles["dialog-div"]}
          open={open}
          // onClose={handleClose}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle
              sx={{ bgcolor: "#F52929", color: "black" }}
              className={Styles["dialog-title"]}
            >
              {appLevelConstants.forms.refer}
            </DialogTitle>

            <DialogContent className={Styles["dialog-content-div"]}>
              <TextField
                autoFocus
                margin="dense"
                id="fullName"
                name="fullName"
                label="Full Name"
                type="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                fullWidth
              />
              {fullNameError && (
                <div style={{ color: "#F52929" }}>{fullNameError}</div>
              )}

              <TextField
                margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              {emailError && (
                <div style={{ color: "#F52929" }}>{emailError}</div>
              )}
              <TextField
                margin="dense"
                id="phone"
                name="phone"
                label="Phone Number"
                type="tel"
                value={phone}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                fullWidth
              />
              {phoneError && (
                <div style={{ color: "#F52929" }}>{phoneError}</div>
              )}
              <TextField
                margin="dense"
                id="referralName"
                name="referralName"
                label="Referral Name"
                type="text"
                value={referralName}
                onChange={(e) => setReferralName(e.target.value)}
                fullWidth
                required
              />
              {referralNameError && (
                <div style={{ color: "#F52929" }}>{referralNameError}</div>
              )}
              <TextField
                margin="dense"
                id="referralEmail"
                name="referralEmail"
                label="Referral EmailID"
                type="text"
                value={referralEmail}
                onChange={(e) => setReferralEmail(e.target.value)}
                fullWidth
                required
              />
              {referralEmailError && (
                <div style={{ color: "#F52929" }}>{referralEmailError}</div>
              )}
            </DialogContent>
            <DialogActions className={Styles["dialog-action-button"]}>
              <div onClick={handleSubmit}>
                <ButtonWithLink
                  name="Submit"
                  type="submit"
                  className={"button"}
                />
              </div>
              <div onClick={handleClose}>
                <ButtonWithLink name="Cancel" className={"button"} />
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </>
  );
};

export default Refer;
