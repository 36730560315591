import Styles from "./InspirationZone.module.scss";
import { fetchInspirationData } from "../../service/imapctService";
import { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import ButtonWithLink from "../buttons/ButtonWithLink";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { truncateText } from "../../helper-fuction/helper";
import UserValidation from "../common_components/forms/user-validation/UserValidation";
import OtpValidation from "../common_components/forms/otpValidatoin/otpValidation";
import { urls } from "../../utils/urls";

export default function InsiprationZone() {
  const [userValidationDialogOpen, setUserValidationDialogOpen] =
    useState(false);
  const [InsiprationZoneData, setInspirationZoneData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState("initial");
  const [otpId, setOtpId] = useState("");
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  let navigate = useNavigate();

  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  const handleGoToCommunity = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    if (userData) {
      navigate("/group-chat");
    } else {
      setUserValidationDialogOpen(true);
    }
  };

  const slidesPerView = Math.min(3, InsiprationZoneData.length);
  const swiperOptions = {
    slidesPerView: 1,
    // spaceBetween: 10,
    autoHeight: true,
    navigation: {
      nextEl: ".my-swiper-button-next",
      prevEl: ".my-swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: slidesPerView,
      },
    },
  };
  const handleUserValidationResponse = (response) => {
    setOtpId(response.data.data.otpID);
    setMessage(response.data.message);
    setStatusCode(response.data.statusCode);
    if (response.data.statusCode === 200) {
      setOtpDialogOpen(true);
    }
  };

  const handleOtpVerificationUserDataResponse = (response) => {
    if (response.data.statusCode !== 200) {
      setOtpDialogOpen(true);
    } else {
      setOtpDialogOpen(false);
      navigate("/group-chat");
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    try {
      if (page === 1) {
        setLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      // const summary = await getCounts();
      const result = await fetchInspirationData(page);
      setTotalPages(result.totalPage);
      setInspirationZoneData((prevData) => [...prevData, ...result.postData]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (page === 1) {
        setLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  const handleLoadMore = () => {
    setIsAtEnd(false);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    const swiper = document.querySelector(".my-swiper-container").swiper;
    swiper.slidePrev();
    setIsAtEnd(false);

    if (screen > 768) {
      if (InsiprationZoneData.length <= 3) {
        setIsAtEnd(true);
      } else {
        setIsAtEnd(false);
      }
    } else {
      if (InsiprationZoneData.length <= 1) {
        setIsAtEnd(true);
      } else {
        setIsAtEnd(false);
      }
    }
  };

  const handleNextClick = () => {
    const swiper = document.querySelector(".my-swiper-container").swiper;
    swiper.slideNext();
    setIsAtBeginning(false);

    if (screen > 768) {
      if (InsiprationZoneData.length <= 3) {
        setIsAtBeginning(true);
      } else {
        setIsAtBeginning(false);
      }
    } else {
      if (InsiprationZoneData.length <= 1) {
        setIsAtBeginning(true);
      } else {
        setIsAtBeginning(false);
      }
    }
  };

  return (
    <>
      <div className={Styles["main-parent-div"]}>
        {InsiprationZoneData.length > 0 && (
          <span className={Styles["main-title"]}>Insipration Zone</span>
        )}
        {InsiprationZoneData.length > 0 && (
          <div className={Styles["bullatin-container"]}>
            {loading ? (
              <ThreeCircles
                height="70"
                width="70"
                color="#F52929"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "20vh",
                }}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
              />
            ) : (
              <>
                {InsiprationZoneData.length !== 0 ? (
                  <>
                    <img
                      alt=""
                      src={urls.prevArrow}
                      className={
                        isAtBeginning ? Styles["disabled"] : Styles["prev"]
                      }
                      onClick={handlePrevClick}
                    ></img>

                    <Swiper
                      {...swiperOptions}
                      className="my-swiper-container"
                      onReachBeginning={() => {
                        setIsAtBeginning(true);
                      }}
                      onReachEnd={() => {
                        setIsAtEnd(true);
                      }}
                    >
                      {InsiprationZoneData.map((item) => {
                        return (
                          <SwiperSlide key={item.id}>
                            <div
                              key={item.id}
                              className={Styles["bullatin-card"]}
                              onClick={() =>
                                navigate(
                                  "/success-story/" +
                                    item.fullNameOfStoryTeller
                                      .split(" ")
                                      .join("_") +
                                    "/" +
                                    item.postId
                                )
                              }
                            >
                              <div className={Styles["bullatin-top"]}>
                                <img
                                  className={Styles["bullatin-img"]}
                                  src={item.successStoryPostImageLink}
                                  alt=""
                                ></img>
                              </div>
                              <div className={Styles["bullatin-bottom"]}>
                                <span className={Styles["bullatin-heading"]}>
                                  {truncateText(item.pageTitle, 50)}
                                </span>

                                <span
                                  className={Styles["bullatin-website-link"]}
                                >
                                  {item.fullNameOfStoryTeller}
                                </span>

                                <span className={Styles["bullatin-mode"]}>
                                  {truncateText(
                                    item.fullNameOfStoryTellerDesignation,
                                    30
                                  )}
                                </span>

                                <div className={Styles["know-more-div"]}>
                                  <span
                                    className={Styles["know-more"]}
                                    onClick={() =>
                                      navigate(
                                        "/success-story/" +
                                          item.fullNameOfStoryTeller
                                            .split(" ")
                                            .join("_") +
                                          "/" +
                                          item.postId
                                      )
                                    }
                                  >
                                    Know More
                                  </span>
                                  <img
                                    alt=""
                                    src={urls.rightUpArrow}
                                    className={Styles["right-arrow"]}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}

                      {currentPage < totalPages && (
                        <SwiperSlide>
                          <div className={Styles["load-more-div"]}>
                            <ButtonWithLink
                              linkTo="action"
                              callback={handleLoadMore}
                              name={isLoadingMore ? "Loading..." : "Load More"}
                              className={"button"}
                              disabled={isLoadingMore}
                            ></ButtonWithLink>
                          </div>
                        </SwiperSlide>
                      )}
                    </Swiper>

                    <img
                      alt=""
                      src={urls.nextArrow}
                      className={isAtEnd ? Styles["disabled"] : Styles["next"]}
                      onClick={handleNextClick}
                    ></img>
                  </>
                ) : (
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    className={Styles["no-data"]}
                  >
                    <img
                      alt=""
                      src={urls.noData}
                      className={Styles["no-data-image"]}
                    ></img>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div className={Styles["bottom-community-chat"]}>
          <div onClick={handleGoToCommunity}>
            <ButtonWithLink
              id="community-chat"
              name="Go to community groups"
              className={"button-red"}
            />
          </div>
        </div>
      </div>

      {userValidationDialogOpen && (
        <UserValidation
          open={userValidationDialogOpen}
          setDialogOpen={setUserValidationDialogOpen}
          onResponse={handleUserValidationResponse}
        ></UserValidation>
      )}

      {otpDialogOpen && (
        <OtpValidation
          open={otpDialogOpen}
          setOtpDialogOpen={setOtpDialogOpen}
          message={message}
          setMessage={setMessage}
          otpId={otpId}
          statusCode={statusCode}
          onResponseUserData={handleOtpVerificationUserDataResponse}
        ></OtpValidation>
      )}
    </>
  );
}
