import axios from 'axios';

const BASE_URL = "https://api.iamironlady.com/api";


export const getJobPostsData = async (pageNumber) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/job-post?page=`+pageNumber
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };
  
  export const getJobPostsSummary = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/get-all-job-post-summary-details`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };