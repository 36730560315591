import Styles from "../impact-success-story/SuccessStory.module.scss";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonWithLinks from "../buttons/ButtonWithLink";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

import AOS from "aos";
import {
  fetchStoriesData,
  getCounts,
  getCatgoryData,
} from "../../service/imapctService";
import { ThreeCircles } from "react-loader-spinner";
import { urls } from "../../utils/urls";

export default function SuccessStory() {
  useEffect(() => {
    AOS.init();
  });

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [allStoriesData, setAllStroiesData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalPost, setTotalPost] = useState(0);
  const [moreLess, setMoreLess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [storyName, setStoryName] = useState({});
  const [queryParms, setQueryParms] = useState({});

  let navigate = useNavigate();

  const handleCloseAlert = () => {
    setAlertDialogOpen(false);
    // setLoading(true);
  };

  const fetchData = async () => {
    try {
      const result = await fetchStoriesData(pageNumber);
      if (result.statusCode === 200) {
        if (pageNumber === 1) {
          setMoreLess(false);
          setAllStroiesData(result.data);
          setPageNumber(pageNumber + 1);
          setLoading(false);
        } else if (parseInt(totalPage) === pageNumber) {
          setButtonLoading(true);
          setMoreLess(true);
          setPageNumber(1);
          const combinedArray = Object.values(result.data).flatMap(
            (arr) => arr
          );
          setAllStroiesData((prevValues) => [...prevValues, ...combinedArray]);
          setLoading(false);
        } else {
          setButtonLoading(true);
          setPageNumber(pageNumber + 1);
          const combinedArray = Object.values(result.data).flatMap(
            (arr) => arr
          );
          setAllStroiesData((prevValues) => [...prevValues, ...combinedArray]);
          setLoading(false);
        }
        setLoading(false);
        setButtonLoading(false);
      } else {
        setAlertDialogOpen(true);
      }
    } catch (error) {
      setPageNumber(1);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPageNumber(pageNumber);
  }, [pageNumber]);

  const getCount = async () => {
    try {
      const counts = await getCounts();
      setTotalPage(counts.totalPage);
      setTotalPost(counts.totalPost);

      setPrograms(counts?.programObj);
      setIndustry(counts?.IndustryObj);
      setFunctions(counts?.functionObj);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  const extractYouTubeVideoId = (url) => {
    const regex =
      /^https?:\/\/(?:www\.)?youtu(?:be\.com\/(?:watch\?(?:.*&)?v=|v\/|embed\/)|\.be\/)([\w\-_]+)(?:.*)$/i;
    const match = url?.match(regex);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  };

  const handleProgramChange = (event, newValue) => {
    setLoading(true);
    if (newValue === null) {
      delete storyName["program"];
      setStoryName({ ...storyName });

      // fetchData();
      // getCount();
      delete queryParms["programId"];
      setQueryParms({ ...queryParms });
    } else if (newValue.programName === "LEP") {
      setAllStroiesData([]);
      fetchData();
      getCount();
      setStoryName({ ...storyName, program: newValue.programName });
    } else {
      setAllStroiesData([]);
      setQueryParms({ ...queryParms, programId: newValue.programId });
      setStoryName({ ...storyName, program: newValue.programName });
      setPageNumber(1);
      setMoreLess(true);
    }
  };

  const handleIndustryChange = (event, newValue) => {
    setLoading(true);
    if (newValue === null) {
      delete storyName["industry"];
      setStoryName({ ...storyName });
      delete queryParms["industryId"];
      setQueryParms({ ...queryParms });
    } else {
      setAllStroiesData([]);
      setQueryParms({ ...queryParms, industryId: newValue.industryId });
      setStoryName({ ...storyName, industry: newValue.industryName });
      setPageNumber(1);
      setMoreLess(true);
    }
  };

  const handleFunctionChange = (event, newValue) => {
    setLoading(true);
    if (newValue === null) {
      delete queryParms["functionId"];
      setQueryParms({ ...queryParms });
      delete storyName["function"];
      setStoryName({ ...storyName });
    } else {
      setAllStroiesData([]);
      setQueryParms({ ...queryParms, functionId: newValue.functionId });
      setStoryName({ ...storyName, function: newValue.functionName });
      setPageNumber(1);
      setMoreLess(true);
    }
  };

  useEffect(() => {
    if (JSON.stringify(queryParms) !== "{}") getCatgoriesData(queryParms);
    else {
      setLoading(true);
      fetchData();
      getCount();
    }
  }, [queryParms]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCatgoriesData = async (queryParms) => {
    try {
      const response = await getCatgoryData(queryParms);
      setAllStroiesData(response?.successStoryDetails);
      setTotalPost(response?.storyCount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={Styles["main-parent-div"]}>
        <div className={Styles["dropdown-container"]}>
          <div className={Styles["dropdown-programs"]}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={programs}
              getOptionLabel={(option) => option.programName}
              getOptionSelected={(option, value) => option.id === value.id}
              className={Styles["auto-complete"]}
              onChange={handleProgramChange}
              renderInput={(params) => (
                <TextField {...params} label="Programs" />
              )}
            />
          </div>
          <div className={Styles["dropdown-programs-author"]}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={industry}
              getOptionLabel={(option) => option?.industryName}
              getOptionSelected={(option, value) => option.id === value.id}
              className={Styles["auto-complete"]}
              onChange={handleIndustryChange}
              renderInput={(params) => (
                <TextField {...params} label="Industry" />
              )}
            />
          </div>
          <div className={Styles["dropdown-programs-author"]}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={functions}
              getOptionLabel={(option) => option?.functionName}
              getOptionSelected={(option, value) => option.id === value.id}
              className={Styles["auto-complete"]}
              onChange={handleFunctionChange}
              renderInput={(params) => (
                <TextField {...params} label="Functions" />
              )}
            />
          </div>
        </div>

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3vw",
            }}
          >
            <ThreeCircles
              height="100"
              width="100"
              color="#F52929"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        )}

        {totalPost !== 0 && loading === false ? (
          <>
            <div className={Styles["stroy-count-container"]}>
              <span
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["all-stroy"]}
              >
                {JSON.stringify(storyName) !== "{}"
                  ? Object.values(storyName).join(", ")
                  : "All Stories"}
              </span>
            </div>

            <div className={Styles["card-container"]}>
              {allStoriesData?.map((val) => (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["card"]}
                >
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    className={Styles["inner-card"]}
                  >
                    {val?.successStoryPostYouTubeLink ? (
                      <iframe
                        className={Styles["iframe"]}
                        title="youtube Video Player"
                        src={
                          "https://www.youtube.com/embed/" +
                          extractYouTubeVideoId(
                            val?.successStoryPostYouTubeLink
                          )
                        }
                        id="fitvid725928"
                      ></iframe>
                    ) : (
                      <img
                        alt=""
                        src={val?.successStoryPostImageLink}
                        className={Styles["user-image"]}
                      ></img>
                    )}
                  </div>
                  <div className={Styles["card-bottom-section"]}>
                    <span
                      onClick={() =>
                        navigate(
                          "/success-story/" +
                            val.fullNameOfStoryTeller.split(" ").join("_") +
                            "/" +
                            val.postId
                        )
                      }
                      className={Styles["user-name"]}
                    >
                      {val?.pageTitle}
                    </span>
                    <span
                      onClick={() =>
                        navigate(
                          "/success-story/" +
                            val.fullNameOfStoryTeller.split(" ").join("_") +
                            "/" +
                            val.postId
                        )
                      }
                      className={Styles["user-name"]}
                    >
                      {val?.fullNameOfStoryTeller}
                    </span>
                    <span
                      onClick={() =>
                        navigate(
                          "/success-story/" +
                            val.fullNameOfStoryTeller.split(" ").join("_") +
                            "/" +
                            val.postId
                        )
                      }
                      className={Styles["designation"]}
                    >
                      {val?.fullNameOfStoryTellerDesignation}
                    </span>

                    <div className={Styles["know-more-div"]}>
                      <span
                        onClick={() =>
                          navigate(
                            "/success-story/" +
                              val.fullNameOfStoryTeller.split(" ").join("_") +
                              "/" +
                              val.postId
                          )
                        }
                        className={Styles["know-more"]}
                      >
                        Know More
                      </span>
                      <img
                        alt=""
                        src={urls.rightUpArrow}
                        className={Styles["right-arrow"]}
                      ></img>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {buttonLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1vw",
                }}
              >
                <ThreeCircles
                  height="100"
                  width="100"
                  color="#F52929"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                />
              </div>
            )}

            {moreLess === false && (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["button-container"]}
              >
                <ButtonWithLinks
                  name="Load More"
                  linkTo="action"
                  className={"button"}
                  callback={fetchData}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {totalPost === 0 && loading === false && (
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["no-data"]}
              >
                <img
                  alt=""
                  src={urls.noData}
                  className={Styles["no-data-image"]}
                ></img>
              </div>
            )}
          </>
        )}
      </div>

      {alertDialogOpen && (
        <div className={Styles["dialog-main-div"]}>
          <Dialog
            className={Styles["dialog-content-div"]}
            open={alertDialogOpen}
            onClose={handleCloseAlert}
          >
            <DialogTitle className={Styles["dialog-title-msg"]}>
              "Error while fetching success stories"
              <DialogActions className={Styles["dialog-action"]}>
                <ButtonWithLinks
                  name="Ok"
                  className={"button"}
                  linkTo="action"
                  callback={handleCloseAlert}
                />
              </DialogActions>
            </DialogTitle>
          </Dialog>
        </div>
      )}
    </>
  );
}
