import { useState } from "react";
import GetInTouch from "../common_components/forms/GetInTouch.js";
import Styles from "./ContactUs.module.scss";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { toast } from "react-toastify";

export default function ContactUs() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: 'getInTouch'
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: 'getInTouch'
    });
  };

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  return (
    <>
      {dialogOpen && (
        <GetInTouch
          setDialogOpen={setDialogOpen}
          open={dialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}

      <div className={Styles["main-parent-div"]}>
        <span className={Styles["main-title"]}>Contact Us</span>

        <p className={Styles["sub-title"]}>
          At Iron Lady, we are committed to empowering women across India to become bold, confident leaders. Whether you're looking to join one of our leadership programs, collaborate with us, or simply have a question, we would love to hear from you.
        </p>

        <p className={Styles["sub-title"]}>
          Feel free to reach out through the form below or contact us via the details provided. Together, let's continue to break barriers and create a future where every woman leads with courage and conviction.
        </p>

        <div className={Styles["card-container"]}>
          <div className={Styles["contact-card"]}>
            <FaPhone className={Styles["icon"]} style={{ 'transform': 'rotate(90deg)' }} />
            <span className={Styles["label"]}>Phone</span>
            <span className={Styles["value"]}>+91 - 6360823123</span>
          </div>

          <div className={Styles["contact-card"]}>
            <FaEnvelope className={Styles["icon"]} />
            <span className={Styles["label"]}>Email</span>
            <span className={Styles["value"]}>admin@iamironlady.com</span>
          </div>

          <div className={Styles["contact-card"]}>
            <FaMapMarkerAlt className={Styles["icon"]} />
            <span className={Styles["label"]}>Address</span>
            <span className={Styles["value"]}>Nandi Info Tech, 2nd Floor, KIADB plot#8, post, 1st cross, Sadaramangala Rd, Mahadevapura, Bengaluru, Karnataka - 560048.</span>
          </div>
        </div>

        <span className={Styles["sub-title"]}>Fill out the form, and we'll reach out with the details you're looking for - let's start your leadership journey together!</span>

        <div className={Styles["button-div"]}>
          <button className={Styles["know-more"]} onClick={() => window.open('https://zfrmz.in/boZr9Cru7BVFkZsqt5dV', '_self')}>Know More</button>
        </div>

      </div>
    </>

  );
}
