// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import styles from './newsletter.module.scss';
// import { getAllNewsletterFromCMS } from '../../service/communityService.js';

// const Newsletter = () => {
//     const [newsletterImages, setNewsletterImages] = useState({ images: [] });
//     const [newsletterImages1, setNewsletterImages1] = useState({ images: [] });
//     const [selectedMonth, setSelectedMonth] = useState('');
//     const [selectedYear, setSelectedYear] = useState('');
//     const [availableNewsletters, setAvailableNewsletters] = useState({});
//     const [availableNewsletters1, setAvailableNewsletters1] = useState({});
//     const [loading, setLoading] = useState(true);
//     const { month: paramMonth, year: paramYear } = useParams();
//     const navigate = useNavigate();
//     // const BASE_URL = "https://api.iamironlady.com/api";
//     const BASE_URL = "http://localhost:3001/api";

//     useEffect(() => {
//         const fetchNewsletterDetailsCMS = async () => {
//           try {
//             const response = await getAllNewsletterFromCMS();
      
//             if (response.statusCode === 200 && Array.isArray(response.data)) {
              
//               const data = response.data;
      
//               const parsedData = data.map(item => {
//                 if (item.description) {
//                   try {
//                     const parsedDescription = JSON.parse(item.description);
//                     return {
//                       id: item.id,
//                       name: item.name,
//                       description: parsedDescription, 
//                     };
//                   } catch (error) {
//                     console.error("Error parsing description:", error);
//                     return {
//                       id: item.id,
//                       name: item.name,
//                       description: null, 
//                     };
//                   }
//                 }
//                 return {
//                   id: item.id,
//                   name: item.name,
//                   description: null, 
//                 };
//               });
      
//               const sortedData = parsedData.sort((a, b) => b.id - a.id);

//               setNewsletterImages1(sortedData)

//               const groupedByYear = sortedData.reduce((acc, item) => {
//                 const [month, year] = item.name.split(" "); 
//                 if (!acc[year]) {
//                     acc[year] = [];
//                 }
//                 if (!acc[year].includes(month)) {
//                     acc[year].push(month);
//                 }
//                 return acc;
//             }, {});
    
//             setAvailableNewsletters1(groupedByYear);
    
//             }
//           } catch (error) {
//             console.error("Error fetching keywords:", error);
//           }
//         };
      
//         fetchNewsletterDetailsCMS();
//       }, []);

//       console.log(newsletterImages1); 
//       console.log(availableNewsletters1)
      

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const newsletterResponse = await fetch(`${BASE_URL}/newsletter-availability`);
//                 const newsletterData = await newsletterResponse.json();

//                 // console.log(newsletterData.data)
//                 setAvailableNewsletters(newsletterData.data);

//                 if (paramMonth && paramYear) {
//                     setSelectedMonth(paramMonth);
//                     setSelectedYear(paramYear);
//                     await fetchNewsletterImages(paramMonth, paramYear);

//                 } else {
//                     const currentDate = new Date();
//                     currentDate.setMonth(currentDate.getMonth() - 1);

//                     const year = currentDate.getFullYear().toString();
//                     const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate);

//                     setSelectedYear(year);
//                     setSelectedMonth(month);

//                     await fetchNewsletterImages(month, year);
//                 }
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, [paramMonth, paramYear]);

//     const fetchNewsletterImages = async (month, year) => {
//         try {
//             const response = await fetch(`${BASE_URL}/newsletter-details?month=${month}&year=${year}`);
//             const data = await response.json();
//             setNewsletterImages(data.data);
//             console.log(data.data)
//         } catch (error) {
//             console.error("Error fetching images:", error);
//         }
//     };

//     const handleMonthClick = (month, year) => {
//         console.log(month, year)
//         setSelectedMonth(month);
//         setSelectedYear(year);
//         fetchNewsletterImages(month, year);
//         navigate(`/newsletter/${year}/${month}`);
//     };

//     return (
//         <div className={styles.container}>
//             <div className={styles.newsletters}>
//                 {Object.keys(availableNewsletters1).map(year => (
//                     <div key={year}>
//                         <h2 className={styles.year}>{year}</h2>
//                         {availableNewsletters1[year].map(month => (
//                             <button key={month} onClick={() => handleMonthClick(month, year)}>
//                                 {month} {year}
//                             </button>
//                         ))}
//                     </div>
//                 ))}
//             </div>

//             <div className={styles.images}>
//                 {newsletterImages.images.map((image) => (
//                     <div key={image.id} className={styles.imageContainer}>
//                         {image.clickable ? (
//                             <a href={image.link} target="_blank" rel="noopener noreferrer">
//                                 <img src={image.url} alt={`Newsletter ${image.id}`} />
//                             </a>
//                         ) : (
//                             <img src={image.url} alt={`Newsletter ${image.id}`} />
//                         )}
//                     </div>
//                 ))}
//             </div>

//         </div>
//     );
// };

// export default Newsletter


import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './newsletter.module.scss';
import { getAllNewsletterFromCMS } from '../../service/communityService.js';

const Newsletter = () => {
    const [newsletterData, setNewsletterData] = useState([]);
    const [availableNewsletters, setAvailableNewsletters] = useState({});
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [loading, setLoading] = useState(true);
    const { month: paramMonth, year: paramYear } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNewsletterDetailsCMS = async () => {
            try {
                const response = await getAllNewsletterFromCMS();
                if (response.statusCode === 200 && Array.isArray(response.data)) {
                    const data = response.data.map(item => {
                        try {
                            return {
                                id: item.id,
                                name: item.name,
                                description: JSON.parse(item.description),
                            };
                        } catch (error) {
                            console.error("Error parsing description:", error);
                            return {
                                id: item.id,
                                name: item.name,
                                description: null,
                            };
                        }
                    }).sort((a, b) => b.id - a.id);

                    setNewsletterData(data);

                    const groupedByYear = data.reduce((acc, item) => {
                        const [month, year] = item.name.split(" ");
                        if (!acc[year]) {
                            acc[year] = [];
                        }
                        if (!acc[year].includes(month)) {
                            acc[year].push(month);
                        }
                        return acc;
                    }, {});

                    setAvailableNewsletters(groupedByYear);

                    if (paramMonth && paramYear) {
                        setSelectedMonth(paramMonth);
                        setSelectedYear(paramYear);
                        filterNewsletterImages(paramMonth, paramYear, data);
                    } else {
                        const currentDate = new Date();
                        currentDate.setMonth(currentDate.getMonth() - 1);

                        const year = currentDate.getFullYear().toString();
                        const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate);

                        setSelectedYear(year);
                        setSelectedMonth(month);

                        filterNewsletterImages(month, year, data);
                    }
                }
            } catch (error) {
                console.error("Error fetching newsletters:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsletterDetailsCMS();
    }, [paramMonth, paramYear]);

    const filterNewsletterImages = (month, year, data) => {
        const filteredImages = data.filter(item => {
            const [itemMonth, itemYear] = item.name.split(" ");
            return itemMonth === month && itemYear === year;
        });
        setNewsletterData(filteredImages);
    };

    const handleMonthClick = (month, year) => {
        setSelectedMonth(month);
        setSelectedYear(year);
        filterNewsletterImages(month, year, newsletterData);
        navigate(`/newsletter/${year}/${month}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.newsletters}>
                {Object.keys(availableNewsletters).map(year => (
                    <div key={year}>
                        <h2 className={styles.year}>{year}</h2>
                        {availableNewsletters[year].map(month => (
                            <button key={month} onClick={() => handleMonthClick(month, year)}>
                                {month} {year}
                            </button>
                        ))}
                    </div>
                ))}
            </div>

            <div className={styles.images}>
                {newsletterData.map((newsletter) => (
                    newsletter.description.images.map((image) => (
                        <div key={image.id} className={styles.imageContainer}>
                            {image.clickable ? (
                                <a href={image.link} target="_blank" rel="noopener noreferrer">
                                    <img src={image.url} alt={`Newsletter ${image.id}`} />
                                </a>
                            ) : (
                                <img src={image.url} alt={`Newsletter ${image.id}`} />
                            )}
                        </div>
                    ))
                ))}
            </div>
        </div>
    );
};

export default Newsletter;