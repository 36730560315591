import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import parse, { domToReact } from "html-react-parser";
import Styles from "./blog.module.scss";
import {
  getAllBlogKeywordDetails,
  getAllPostsData,
  getFeedDetailsByName,
} from "../../service/communityService.js";
import { ThreeCircles } from "react-loader-spinner";
import GetInTouch from "../common_components/forms/GetInTouch.js";
import { toast } from "react-toastify";
import RelatedBlogs from "../related-blogs/RelatedBlogs.js";
import { FacebookIcon, FacebookShareButton, TwitterShareButton, LinkedinIcon, LinkedinShareButton, XIcon, } from 'react-share';
import { FaInstagram } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Blog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, category, subcategory } = useParams();
  const [feedContent, setFeedContent] = useState("");
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");

  const id = location.state ? location.state.id : null;
  const slug = location.state ? location.state.slug : null;
  const imageURL = location.state ? location.state.imageURL : null;

  const shareUrl = window.location.href;



  const formatTitle = (str) => {
    return str
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return (div.textContent || div.innerText || '').trim();
  };

  const formattedTitle = formatTitle(title);

  const handleInstagramShare = () => {
    const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent(shareUrl)}`;
    window.open(instagramUrl, '_blank');
  };

  const handleYouTubeShare = () => {
    const youtubeUrl = `https://www.youtube.com/share?url=${encodeURIComponent(shareUrl)}`;
    window.open(youtubeUrl, '_blank');
  };

  const handleScrollToItem = (id) => {
    navigate('/aboutUs', { state: { scrollTo: 'programCurtators', itemId: id } });
  };

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await getAllBlogKeywordDetails();

        if (response.statusCode === 200) {
          const formattedKeywords = response.data.map(item => ({
            keyword: item.name,
            link: item.description.startsWith('http') ? item.description : null,
            id: !item.description.startsWith('http') ? parseInt(item.description) : null
          }));
          setKeywords(formattedKeywords);
        }
      } catch (error) {
        console.error("Error fetching keywords:", error);
      }
    };

    fetchKeywords();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;

        if (id) {
          data = await getFeedDetailsByName(slug);
        } else {
          data = await getFeedDetailsByName(title);
        }
        setFeedContent(data.content.rendered);

        const cleanDescription = stripHtmlTags(data.excerpt.rendered);

        setMetaDescription(cleanDescription);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching feed details:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, slug, title]);

  useEffect(() => {
    if (feedContent) {
      const parseOptions = {
        replace: (domNode) => {
          if (domNode.name === "h3" || domNode.name === "h5" || domNode.name === "p" || domNode.name === "b") {
            const content = domToReact(domNode.children, parseOptions);
            const modifiedContent = replaceKeywordsWithLinks(content, keywords);
            return React.createElement(domNode.name, { key: domNode.attribs.key }, modifiedContent);
          }
        },
      };

      const parsedContent = parse(feedContent, parseOptions);
      setElements(parsedContent);
    }
  }, [feedContent, keywords]);

  const replaceKeywordsWithLinks = (content, keywords) => {
    if (typeof content === 'string') {
      let modifiedText = content;
      keywords.forEach(({ keyword, link, id }) => {
        const regex = new RegExp(`\\b${keyword}\\b`, 'g');
        if (link) {
          modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" class="${Styles['keyword-link']}" rel="noopener noreferrer">${keyword}</a>`);
        } else if (id) {
          modifiedText = modifiedText.replace(regex, `<span class="${Styles['keyword-button']}" data-id="${id}">${keyword}</span>`);
        }
      });
      return parse(modifiedText);
    }

    if (Array.isArray(content)) {
      return content.map((node) => {
        if (typeof node === 'string') {
          let modifiedText = node;
          keywords.forEach(({ keyword, link, id }) => {
            const regex = new RegExp(`\\b${keyword}\\b`, 'g');
            if (link) {
              modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" rel="noopener noreferrer">${keyword}</a>`);
            } else if (id) {
              modifiedText = modifiedText.replace(regex, `<span class="${Styles['keyword-button']}" data-id="${id}">${keyword}</span>`);
            }
          });
          return parse(modifiedText);
        } else if (node && node.props && node.props.children) {
          const modifiedChildren = replaceKeywordsWithLinks(node.props.children, keywords);
          return React.cloneElement(node, { ...node.props, children: modifiedChildren });
        }
        return node;
      });
    }

    return content;
  };

  useEffect(() => {
    const handleButtonClick = (event) => {
      const target = event.target;
      if (target.classList.contains(Styles['keyword-button'])) {
        const id = target.getAttribute('data-id');
        handleScrollToItem(id);
      }
    };

    document.addEventListener('click', handleButtonClick);

    return () => {
      document.removeEventListener('click', handleButtonClick);
    };
  }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const response = await getAllPostsData();
        const data = await JSON.parse(response);
        setBlogs(data.blog);

        const related = data.blog.filter(
          (blog) =>
            blog.PostCategory === category &&
            blog.PostSubCategory === subcategory
        );
        setRelatedBlogs(related);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      }
    };

    if (category && subcategory) {
      fetchBlogs();
    }
  }, [category, subcategory]);

  const handleBlogClick = (blog) => {
    setLoading(true);
    navigate(`/blogs/${blog.PostCategory}/${blog.PostSubCategory}/${blog.PostSlug}`, { state: { id: blog.id, slug: blog.PostSlug } });
  };

  return (
    <>
      {dialogOpen && (
        <GetInTouch
          setDialogOpen={setDialogOpen}
          open={dialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}

      <div className={Styles["main-div"]}>
        {loading ? (
          <ThreeCircles
            height="70"
            width="70"
            color="#F52929"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "20vh",
            }}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
          />
        ) : (
          <>

            <Helmet>
              <title>{formattedTitle}</title>
              <meta name="description" content={metaDescription} />
              {/* <meta property="og:title" content={formattedTitle} />
              <meta property="og:description" content={metaDescription} />
              <meta property="og:image" content={imageURL} /> 
              <meta property="og:url" content={shareUrl} />
              <meta property="og:type" content="article" /> */}
            </Helmet>

            {elements.map((element, index) => (
              <div key={index}>{element}</div>
            ))}

            <div className={Styles["social-share-container"]}>
              <div>
                <h5 style={{ margin: '16px 0px' }}>Share This Page</h5>
              </div>
              <div className={Styles["social-share-buttons"]}>
                <TwitterShareButton url={shareUrl} title={formattedTitle}>
                  <XIcon size={32} />
                </TwitterShareButton>
                <FacebookShareButton url={shareUrl} quote={formattedTitle}>
                  <FacebookIcon size={32} />
                </FacebookShareButton>
                <LinkedinShareButton url={shareUrl} title={formattedTitle}>
                  <LinkedinIcon size={32} />
                </LinkedinShareButton>
              </div>
            </div>

            {relatedBlogs.length > 0 && (
              <RelatedBlogs
                relatedBlogs={relatedBlogs.filter(blog => blog.id !== id)}
                handleBlogClick={handleBlogClick}
              />
            )}


            <div onClick={() => window.open('https://zfrmz.in/boZr9Cru7BVFkZsqt5dV', '_self')}>
              <button className={Styles["cta-button"]}>Know More</button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Blog;


