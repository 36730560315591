import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import Styles from "./article.module.scss";
import { getFeedDetailsById, getFeedDetailsByName } from '../../service/communityService.js';
import { ThreeCircles } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import GetInTouch from '../common_components/forms/GetInTouch.js';

const Article = () => {
  const location = useLocation();
  const {title} = useParams();
  const [feedContent, setFeedContent] = useState('');
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  

  const id = location.state ? location.state.id : null;
  const slug = location.state ? location.state.slug : null;

  // const slug = decodeURIComponent(title).replace(/ /g, '-').toLowerCase();

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;

        if(id) {
          data = await getFeedDetailsByName(slug);
        } else {
          data = await getFeedDetailsByName(title);
        }
        setFeedContent(data.content.rendered);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching feed details:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, slug, title]);


  // useEffect(() => {
  //   getFeedDetailsById(id).then(data => {
  //     setFeedContent(data.content.rendered);
  //   });
  // }, [id]);

  // useEffect(() => {
  //   getFeedDetailsByName(name).then(data => {
  //     setFeedContent(data.content.rendered);
  //   });
  // }, [name]);

  useEffect(() => {
    if (feedContent) {
      const elementsArray = [];
      const parseOptions = {
        replace: domNode => {
          if (domNode.name === 'h3') {
            elementsArray.push(
              <h3>{domToReact(domNode.children)}</h3>
            );
          } else if (domNode.name === 'h5') {
            elementsArray.push(
              <h5>{domToReact(domNode.children)}</h5>
            );
          } else if (domNode.name === 'p') {
            elementsArray.push(
              <p>{domToReact(domNode.children)}</p>
            );
          } else if (domNode.name === 'img') {
            elementsArray.push(
              <img src={domNode.attribs.src} alt={domNode.attribs.alt} />
            );
          }
        }
      };

      parse(feedContent, parseOptions);
      setElements(elementsArray);
    }
  }, [feedContent]);

  return (
    <>
      {dialogOpen && (
        <GetInTouch
          setDialogOpen={setDialogOpen}
          open={dialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}

      <div className={Styles["main-div"]}>
        {
          loading ? (
            <ThreeCircles
              height="70"
              width="70"
              color="#F52929"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "20vh",
              }}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
            />
          ) : (
            <>
              {elements.map((element, index) => (
                <div key={index}>{element}</div>
              ))}

              <div onClick={() => window.open('https://zfrmz.in/boZr9Cru7BVFkZsqt5dV', '_self')}>
                <button className={Styles["cta-button"]}>Know More</button>
              </div>
            </>
          )
        }
      </div>
    </>
  );
}

export default Article