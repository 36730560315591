import Styles from "../wisdom/Wisdom.module.scss";
import { useState, useEffect } from "react";
import {
  getWisdomData,
  getWisdomSummary,
} from "../../service/communityService";
import { ThreeCircles } from "react-loader-spinner";
import ButtonWithLink from "../buttons/ButtonWithLink";
import { useNavigate } from "react-router-dom";
import { extractYouTubeVideoId } from "../../helper-fuction/helper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { truncateText } from "../../helper-fuction/helper";
import { urls } from "../../utils/urls";
export default function Wisdom() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  let navigate = useNavigate();

  const [screen, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setScreenWidth(screen);
  }, [screen]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    try {
      if (page === 1) {
        setLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      const summary = await getWisdomSummary();
      setTotalPages(summary.totalPage);
      const result = await getWisdomData(page);
      setData((prevData) => [...prevData, ...result]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (page === 1) {
        setLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  const slidesPerView = Math.min(3, data.length);
  const swiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: ".my-swiper-button-next",
      prevEl: ".my-swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: slidesPerView,
      },
    },
  };

  const handleLoadMore = () => {
    setIsAtEnd(false);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    const swiper = document.querySelector(".my-swiper-container-comp3").swiper;
    swiper.slidePrev();
    setIsAtEnd(false);
    if(screen > 768){
      if (data.length <= 3){
        setIsAtEnd(true);
      } else {
        setIsAtEnd(false);
      }
    }else{
      if (data.length <= 1){
        setIsAtEnd(true);
      } else {
        setIsAtEnd(false);
      }
    } 
  };

  const handleNextClick = () => {
    const swiper = document.querySelector(".my-swiper-container-comp3").swiper;
    swiper.slideNext();
    setIsAtBeginning(false);
    if(screen > 768){
      if (data.length <= 3) {
        setIsAtBeginning(true);
      } else {
        setIsAtBeginning(false);
      }
    }else{
      if (data.length <= 1) {
        setIsAtBeginning(true);
      } else {
        setIsAtBeginning(false);
      }
    }  
  };

  return (
    <div className={Styles["main-parent-div"]}>
      {data.length > 0 && (
        <span className={Styles["main-title"]}>Wisdom/Insights</span>
      )}

      {data.length > 0 && (
        <div className={Styles["bullatin-container"]}>
          {loading ? (
            <ThreeCircles
              height="70"
              width="70"
              color="#F52929"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "20vh",
              }}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
            />
          ) : (
            <>
              {data.length !== 0 ? (
                <>
                  <img
                    alt=""
                    src={urls.prevArrow}
                    className={
                      isAtBeginning ? Styles["disabled"] : Styles["prev"]
                    }
                    onClick={handlePrevClick}
                  ></img>

                  <Swiper
                    {...swiperOptions}
                    className="my-swiper-container-comp3"
                    onReachBeginning={() => {
                      setIsAtBeginning(true);
                    }}
                    onReachEnd={() => {
                      setIsAtEnd(true);
                    }}
                  >
                    {data.map((item) => {
                      return (
                        <SwiperSlide key={item.id}>
                          <div
                            key={item.id}
                            className={Styles["bullatin-card"]}
                            onClick={() => {
                              navigate("/wisdomInsightDetailPage/" + item.id);
                            }}
                          >
                            <div className={Styles["bullatin-top"]}>
                              {item?.knw_video_url_S3 ? (
                                <video
                                  className={Styles["iframe"]}
                                  src={
                                    "https://d1ixtxt2uzttul.cloudfront.net/videos/impact_video.mp4"
                                  }
                                  controls
                                  autoPlay
                                  muted
                                  poster={item?.thumbnail}
                                ></video>
                              ) : item?.knw_video_url ? (
                                <>
                                  <iframe
                                    className={Styles["iframe"]}
                                    title="youtube Video Player"
                                    allowFullScreen
                                    src={
                                      "https://www.youtube.com/embed/" +
                                      extractYouTubeVideoId(item?.knw_video_url)
                                    }
                                    id="fitvid725928"
                                  ></iframe>
                                </>
                              ) : (
                                <img
                                  alt=""
                                  src={item?.img}
                                  className={Styles["bullatin-img"]}
                                ></img>
                              )}
                            </div>
                            <div className={Styles["bullatin-bottom"]}>
                              <span className={Styles["bullatin-heading"]}>
                                {truncateText(item?.knw_title, 90)}
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}

                    {currentPage < totalPages && (
                      <SwiperSlide>
                        <div className={Styles["load-more-div"]}>
                          <ButtonWithLink
                            linkTo="action"
                            callback={handleLoadMore}
                            name={isLoadingMore ? "Loading..." : "Load More"}
                            className={"button"}
                            disabled={isLoadingMore}
                          ></ButtonWithLink>
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>

                  <img
                    alt=""
                    src={urls.nextArrow}
                    className={isAtEnd ? Styles["disabled"] : Styles["next"]}
                    onClick={handleNextClick}
                  ></img>
                </>
              ) : (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["no-data"]}
                >
                  <img
                    alt=""
                    src={urls.noData}
                    className={Styles["no-data-image"]}
                  ></img>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}