import Styles from "./CompanyView.module.scss";
import { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import ButtonWithLinks from "../buttons/ButtonWithLink";
import { getJobPostsSummary, getJobPostsData } from "../../service/jobPosts";
import { urls } from "../../utils/urls";

export default function CompanyView() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredItem, setFilteredItem] = useState({});
  const [summary, setSummary] = useState({});

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const result = await getJobPostsData(page);
      const summary = await getJobPostsSummary();
      setSummary(summary);
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && data.length > 0) {
      const filtered = data.find(
        (item) => item.companyDetails.companyId === parseInt(id)
      );
      setFilteredItem(filtered || null);
    }
  }, [id, data]);

  useEffect(() => {
    if (id && !filteredItem && currentPage < summary.totalPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [id, filteredItem, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToLinkedIn = () => {
    window.open(filteredItem?.companyDetails?.companyLinkedInURL, "_blank");
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className={Styles["main-parent-div"]}>
      <div className={Styles["back-button-container"]}>
        <ButtonWithLinks
          id="jobPostsDetailPageBack"
          name="Back"
          linkTo="action"
          callback={handleBack}
          className={"button-job-detail-page"}
        />
      </div>

      {loading ? (
        <ThreeCircles
          height="70"
          width="70"
          color="#F52929"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "20vh",
          }}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
        />
      ) : (
        <>
          <div className={Styles["section-1"]}>
            <div className={Styles["card"]} id={filteredItem?.jobPostId}>
              <div className={Styles["card-top"]}>
                <img
                alt=""
                  className={Styles["company-logo"]}
                  src={filteredItem?.companyDetails?.companyLogo}
                ></img>
                <div className={Styles["right-div"]}>
                  <span className={Styles["job-post-title"]}>
                    {filteredItem?.companyDetails?.companyName}
                  </span>
                  <span className={Styles["company-name"]}>
                    {filteredItem?.companyDetails?.companyType}
                  </span>
                  <div className={Styles["icon-div"]}>
                    <img alt="" className={Styles["icon"]} src={urls.locationIcon}></img>
                    <span className={Styles["location"]}>
                      {filteredItem?.jobLocation}
                    </span>
                  </div>
                  <div className={Styles["website-link-div"]}>
                      <span className={Styles["website-link"]}>Website Link: </span>
                      <a
                            href={filteredItem?.companyDetails?.companyWebsiteURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {filteredItem?.companyDetails?.companyWebsiteURL}
                          </a>
                    </div>
                  { filteredItem?.companyDetails?.companyLinkedInURL ? (
                    <div className={Styles["follow-button-container"]}>
                      <ButtonWithLinks
                        id="followCompanyDetailPage"
                        name="Follow"
                        linkTo='action'
                        callback={redirectToLinkedIn}
                        className={"button-job-detail-page"}
                      />
                    </div>
                  ) : <>
                  </>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={Styles["section-2"]}>
            <div className={Styles["card-2"]}>
              <div className={Styles["description-title"]}>Overview</div>
              {filteredItem &&
              filteredItem?.companyDetails?.companyOverview?.startsWith(
                "<p"
              ) ? (
                <>
                  <div
                    className={Styles["description"]}
                    dangerouslySetInnerHTML={{
                      __html: filteredItem?.companyDetails?.companyOverview,
                    }}
                  />
                </>
              ) : (
                <>
                  <span className={Styles["description"]}>
                    {filteredItem?.companyDetails?.companyOverview}
                  </span>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
